import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card, PageTitle, Table } from '../../components';
import { TableVapour } from '../../components/TableVapour';
import { APIService, SessionUtils } from '../../utils';

const transform = (arrData) => {
  return arrData.map((item) => {
    const { name, sand, silt, clay } = item;
    return [
      {
        Chemical: name,
        'Soil Type': 'Sand',
        'HSL A&B - Low_high density residential': {
          '0 to <1 m': sand.ab[0],
          '1 to <2 m': sand.ab[1],
          '2 to <4 m': sand.ab[2],
          '4 to <8 m': sand.ab[3],
          '8 m +': sand.ab[4],
        },
        'HSL C - Recreational / Open space': {
          '0 to <1 m': sand.c[0],
          '1 to <2 m': sand.c[1],
          '2 to <4 m': sand.c[2],
          '4 to <8 m': sand.c[3],
          '8 m +': sand.c[4],
        },
        'HSL D - Commercial / Industrial': {
          '0 to <1 m': sand.d[0],
          '1 to <2 m': sand.d[1],
          '2 to <4 m': sand.d[2],
          '4 to <8 m': sand.d[3],
          '8 m +': sand.d[4],
        },
      },
      {
        Chemical: name,
        'Soil Type': 'Silt',
        'HSL A&B - Low_high density residential': {
          '0 to <1 m': silt.ab[0],
          '1 to <2 m': silt.ab[1],
          '2 to <4 m': silt.ab[2],
          '4 to <8 m': silt.ab[3],
          '8 m +': silt.ab[4],
        },
        'HSL C - Recreational / Open space': {
          '0 to <1 m': silt.c[0],
          '1 to <2 m': silt.c[1],
          '2 to <4 m': silt.c[2],
          '4 to <8 m': silt.c[3],
          '8 m +': silt.c[4],
        },
        'HSL D - Commercial / Industrial': {
          '0 to <1 m': silt.d[0],
          '1 to <2 m': silt.d[1],
          '2 to <4 m': silt.d[2],
          '4 to <8 m': silt.d[3],
          '8 m +': silt.d[4],
        },
      },
      {
        Chemical: name,
        'Soil Type': 'Clay',
        'HSL A&B - Low_high density residential': {
          '0 to <1 m': clay.ab[0],
          '1 to <2 m': clay.ab[1],
          '2 to <4 m': clay.ab[2],
          '4 to <8 m': clay.ab[3],
          '8 m +': clay.ab[4],
        },
        'HSL C - Recreational / Open space': {
          '0 to <1 m': clay.c[0],
          '1 to <2 m': clay.c[1],
          '2 to <4 m': clay.c[2],
          '4 to <8 m': clay.c[3],
          '8 m +': clay.c[4],
        },
        'HSL D - Commercial / Industrial': {
          '0 to <1 m': clay.d[0],
          '1 to <2 m': clay.d[1],
          '2 to <4 m': clay.d[2],
          '4 to <8 m': clay.d[3],
          '8 m +': clay.d[4],
        },
      },
    ];
  });
};

export const InfocareTable = () => {
  let query = new URLSearchParams(useLocation().search);
  let keyword = query.get('keyword');
  const [soilTable, setSoilTable] = useState([]);
  const [groundwaterTable, setGroundwaterTable] = useState([]);
  const [vapourSoilTable, setVapourSoilTable] = useState([]);
  const [vapourGroundwaterTable, setVapourGroundwaterTable] = useState([]);
  const [vapourSoilVapourTable, setVapourSoilVapourTable] = useState([]);

  /** Effect to get data */
  useEffect(() => {
    if (keyword) {
      APIService.get.searchAll(encodeURIComponent(keyword)).then((reply) => {
        let fulldata = reply.data;
        if (fulldata.soil) {
          let newSoil = _(fulldata.soil)
            .reject({ status: 'inactive' })
            .orderBy('name', 'asc')
            .filter((item) => {
              if (SessionUtils.getUserData().role === 'admin') {
                return item.status === 'active';
              } else {
                return (
                  item.status === 'active' &&
                  (item.userId === SessionUtils.getUserData().id ||
                    item.userId === 1)
                );
              }
            })
            .value()
            .map((item) => {
              return {
                Chemicals: item.name,
                'Chemical Group': item.group,
                'Residential A': item.residentialA,
                'Residential B': item.residentialB,
                'Recreational C': item.recreationalC,
                'Commercial/industrial D': item.commercialD,
              };
            });
          setSoilTable(newSoil);
        }

        if (fulldata.grounwater) {
          let newGround = _(fulldata.grounwater)
            .reject({ status: 'inactive' })
            .orderBy('name', 'asc')
            .filter((item) => {
              if (SessionUtils.getUserData().role === 'admin') {
                return item.status === 'active';
              } else {
                return (
                  item.status === 'active' &&
                  (item.userId === SessionUtils.getUserData().id ||
                    item.userId === 1)
                );
              }
            })
            .value()
            .map((item) => {
              return {
                Substance: item.name,
                'Chemical Group': item.group,
                'Fresh Water': item.freshWater,
                'Marine Water': item.marineWater,
                'Drinking Water': item.drinkingWater,
              };
            });
          setGroundwaterTable(newGround);
        }

        if (fulldata.vapoursoil) {
          let newVapourSoil = transform(
            _(fulldata.vapoursoil)
              .reject({ status: 'inactive' })
              .orderBy('name', 'asc')
              .filter((item) => {
                if (SessionUtils.getUserData().role === 'admin') {
                  return item.status === 'active';
                } else {
                  return (
                    item.status === 'active' &&
                    (item.userId === SessionUtils.getUserData().id ||
                      item.userId === 1)
                  );
                }
              })
              .value()
          ).flat();
          setVapourSoilTable(newVapourSoil);
        }

        if (fulldata.vapourwater) {
          let newVapourWater = transform(
            _(fulldata.vapourwater)
              .reject({ status: 'inactive' })
              .orderBy('name', 'asc')
              .filter((item) => {
                if (SessionUtils.getUserData().role === 'admin') {
                  return item.status === 'active';
                } else {
                  return (
                    item.status === 'active' &&
                    (item.userId === SessionUtils.getUserData().id ||
                      item.userId === 1)
                  );
                }
              })
              .value()
          ).flat();
          setVapourGroundwaterTable(newVapourWater);
        }

        if (fulldata.vapoursoilvapour) {
          let newVapourSoilVapour = transform(
            _(fulldata.vapoursoilvapour)
              .reject({ status: 'inactive' })
              .orderBy('name', 'asc')
              .filter((item) => {
                if (SessionUtils.getUserData().role === 'admin') {
                  return item.status === 'active';
                } else {
                  return (
                    item.status === 'active' &&
                    (item.userId === SessionUtils.getUserData().id ||
                      item.userId === 1)
                  );
                }
              })
              .value()
          ).flat();
          setVapourSoilVapourTable(newVapourSoilVapour);
        }
      });
    }
  }, []);

  return (
    <div>
      <PageTitle
        title="infoCARE"
        description="Search and view contaminant database."
        className="pe-7s-info"
      />

      {/* Back button */}
      <div className="flex flex-row items-center">
        <Link to="/infocare" className="cursor-pointer inline-block mx-6">
          <img
            className="w-10 "
            src="/assets/icons/arrow-left-line.svg"
            alt=""
          />
        </Link>
        <h2 className="font-semibold">The search results for '{keyword}' :</h2>
      </div>

      <div className="flex flex-col px-6 pt-2 pb-6 space-y-8">
        {soilTable.length > 0 && (
          <Card
            title={`Health Screening Levels for "${keyword}" in soil (mg/Kg):`}
            uppercase={false}
          >
            <div>
              <Table
                data={soilTable}
                headerBgColor="bg-xgreen"
                headerTextColor="text-white"
                headerText="capitalize-first"
              />
            </div>
          </Card>
        )}

        {groundwaterTable.length > 0 && (
          <Card
            title={`Health Screening Levels for "${keyword}" in groundwater (mg/L):`}
            uppercase={false}
          >
            <div>
              <Table
                data={groundwaterTable}
                NL={2}
                headerBgColor="bg-xgreen"
                headerTextColor="text-white"
                headerText="capitalize-first"
              />
            </div>
          </Card>
        )}

        {vapourSoilTable.length > 0 && (
          <Card
            title={`Health Screening Levels for "${keyword}" in vapour intrusion (soil sample mg/kg): `}
            uppercase={false}
          >
            <div>
              <TableVapour
                data={vapourSoilTable}
                headerBgColor="bg-xgreen"
                headerTextColor="text-white"
                headerText="capitalize-first"
              />
            </div>
          </Card>
        )}

        {vapourGroundwaterTable.length > 0 && (
          <Card
            title={`Health Screening Levels for "${keyword}" in vapour intrusion (groundwater sample ug/L): `}
            uppercase={false}
          >
            <div>
              <TableVapour
                data={vapourGroundwaterTable}
                headerBgColor="bg-xgreen"
                headerTextColor="text-white"
                headerText="capitalize-first"
              />
            </div>
          </Card>
        )}

        {vapourSoilVapourTable.length > 0 && (
          <Card
            title={[
              `Health Screening Levels for "${keyword}" in vapour intrusion (soil vapour mg/m`,
              <sup>3</sup>,
              `):`,
            ]}
            uppercase={false}
          >
            <div>
              <TableVapour
                data={vapourSoilVapourTable}
                headerBgColor="bg-xgreen"
                headerTextColor="text-white"
                headerText="capitalize-first"
              />
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};
