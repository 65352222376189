import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Swal from 'sweetalert2';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Card, PageTitle } from '../../components';
import { APIService, SessionUtils, useWindowSize } from '../../utils';

import { connect } from 'react-redux';
import { RESOURCE_NAMES } from '../../store/reducers/resources';
import {
  getDataById as _getDataById,
  addData as _addData,
  updateData as _updateData,
  deleteData as _deleteData,
} from '../../store/actions/resources';
import { getResource } from '../../store/selectors/resources';

const VapourForm = ({
  getDataById,
  addSoilData,
  addGroundwaterData,
  addSoilVapourData,
  updateSoilData,
  updateGroundwaterData,
  updateSoilVapourData,
  deleteData,
  soil,
  groundwater,
  soilVapour,
}) => {
  let { vapourType, dataId } = useParams();
  const history = useHistory();
  const [width] = useWindowSize();

  const [cardTitle, setCardTitle] = useState('');
  const [disabled, setDisabled] = useState(true);

  const [name, setName] = useState('');
  const [sandAb, setSandAb] = useState(['', '', '', '', '']);
  const [sandC, setSandC] = useState(['', '', '', '', '']);
  const [sandD, setSandD] = useState(['', '', '', '', '']);

  const [siltAb, setSiltAb] = useState(['', '', '', '', '']);
  const [siltC, setSiltC] = useState(['', '', '', '', '']);
  const [siltD, setSiltD] = useState(['', '', '', '', '']);

  const [clayAb, setClayAb] = useState(['', '', '', '', '']);
  const [clayC, setClayC] = useState(['', '', '', '', '']);
  const [clayD, setClayD] = useState(['', '', '', '', '']);

  const [error, setError] = useState('');
  const [isReplace, setIsReplace] = useState(false);

  // Specify card title for specific vapour type
  useEffect(() => {
    if (vapourType === 'soil') {
      setCardTitle(
        'Vapour Intrusion - Volatile Organic Compounds (VOC) in soil'
      );
    } else if (vapourType === 'groundwater') {
      setCardTitle(
        'Vapour Intrusion - Volatile Organic Compounds (VOC) in groundwater'
      );
    } else if (vapourType === 'soil-vapour') {
      setCardTitle(
        'Vapour Intrusion - Volatile Organic Compounds (VOC) in soil vapour'
      );
    }
  }, [vapourType]);

  // Get specific data form value for edit
  useEffect(() => {
    if (dataId) {
      if (vapourType === 'soil') {
        getDataById(RESOURCE_NAMES['VAPOUR-INTRUSION-SOILS'], dataId);
      } else if (vapourType === 'groundwater') {
        getDataById(RESOURCE_NAMES['VAPOUR-INTRUSION-GROUNDWATERS'], dataId);
      } else if (vapourType === 'soil-vapour') {
        getDataById(RESOURCE_NAMES['VAPOUR-INTRUSION-SOIL-VAPOURS'], dataId);
      }
    } else {
      setDisabled(false);
    }
  }, [dataId]);

  // assign data form value for edit view
  useEffect(() => {
    if (dataId && !isReplace) {
      if (vapourType === 'soil') {
        if (soil[dataId]) {
          let arr = soil[dataId];
          setName(arr.name);
          setSandAb(arr.sand.ab);
          setSandC(arr.sand.c);
          setSandD(arr.sand.d);

          setSiltAb(arr.silt.ab);
          setSiltC(arr.silt.c);
          setSiltD(arr.silt.d);

          setClayAb(arr.clay.ab);
          setClayC(arr.clay.c);
          setClayD(arr.clay.d);
        }
      } else if (vapourType === 'groundwater') {
        if (groundwater[dataId]) {
          let arr = groundwater[dataId];
          setName(arr.name);
          setSandAb(arr.sand.ab);
          setSandC(arr.sand.c);
          setSandD(arr.sand.d);

          setSiltAb(arr.silt.ab);
          setSiltC(arr.silt.c);
          setSiltD(arr.silt.d);

          setClayAb(arr.clay.ab);
          setClayC(arr.clay.c);
          setClayD(arr.clay.d);
        }
      } else if (vapourType === 'soil-vapour') {
        if (soilVapour[dataId]) {
          let arr = soilVapour[dataId];
          setName(arr.name);
          setSandAb(arr.sand.ab);
          setSandC(arr.sand.c);
          setSandD(arr.sand.d);

          setSiltAb(arr.silt.ab);
          setSiltC(arr.silt.c);
          setSiltD(arr.silt.d);

          setClayAb(arr.clay.ab);
          setClayC(arr.clay.c);
          setClayD(arr.clay.d);
        }
      }
    }
  }, [soil, groundwater, soilVapour, isReplace]);

  const handleAdd = async () => {
    let dt = {
      name: name.trim(),
      sand: {
        ab: sandAb,
        c: sandC,
        d: sandD,
      },
      silt: {
        ab: siltAb,
        c: siltC,
        d: siltD,
      },
      clay: {
        ab: clayAb,
        c: clayC,
        d: clayD,
      },
    };
    let reply = {};
    try {
      if (vapourType === 'soil') {
        reply = await APIService.get.vapourSoil.all();
      } else if (vapourType === 'groundwater') {
        reply = await APIService.get.vapourGroundWater.all();
      } else if (vapourType === 'soil-vapour') {
        reply = await APIService.get.vapourSoilVapour.all();
      }
    } catch (err) {
      await Swal.fire({
        title: 'An Error occured!',
        icon: 'error',
        confirmButtonColor: '#28a745',
      });
    }
    if (
      !reply.data.some(
        (item) =>
          item.name.trim().toLowerCase() == name.trim().toLowerCase() &&
          item.status == 'active'
      )
    ) {
      Swal.fire({
        title: 'Check your data',
        text: 'Review your data before submission.',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Submit',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            if (vapourType === 'soil') {
              await addSoilData(dt);
              return { isConfirmed: true };
            } else if (vapourType === 'groundwater') {
              await addGroundwaterData(dt);
              return { isConfirmed: true };
            } else if (vapourType === 'soil-vapour') {
              await addSoilVapourData(dt);
              return { isConfirmed: true };
            }
          } catch (err) {
            Swal.fire({
              title: 'An Error occured!',
              icon: 'error',
              confirmButtonColor: '#28a745',
            });
          }
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Swal.fire({
            icon: 'success',
            title: 'Vapour data successfully created!',
            confirmButtonColor: '#28a745',
          });
          window.location.href = `${process.env.MIX_APP_URL}/vapour`;
        }
      });
    } else {
      const redirectId = reply.data.find(
        (item) =>
          item.name.trim().toLowerCase() == name.trim().toLowerCase() &&
          item.status == 'active'
      ).id;
      Swal.fire({
        title: `Vapour with the name ${name} already exists!`,
        text: 'You have to replace the existing data first OR rename the current data.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#309e1d',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Rename this data',
        confirmButtonText: 'Replace the existing data',
        allowOutsideClick: false,
        didDestroy: () => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        },
      }).then((result) => {
        if (result.value) {
          setError('');
          history.push(`/vapour/${vapourType}/${redirectId}`);
          setIsReplace(true);
        } else {
          setIsReplace(false);
        }
      });
    }
  };

  const handleUpdate = async () => {
    let dt = {
      name: name.trim(),
      sand: {
        ab: sandAb,
        c: sandC,
        d: sandD,
      },
      silt: {
        ab: siltAb,
        c: siltC,
        d: siltD,
      },
      clay: {
        ab: clayAb,
        c: clayC,
        d: clayD,
      },
    };

    try {
      if (vapourType === 'soil') {
        const reply = await APIService.get.vapourSoil.all();
        if (
          !reply.data.some(
            (item) =>
              item.name.trim().toLowerCase() !==
                soil[dataId].name.toLowerCase() &&
              item.name.trim().toLowerCase() == name.trim().toLowerCase() &&
              item.status == 'active'
          )
        ) {
          Swal.fire({
            title: 'Check your data',
            text: 'Review your data before submission.',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
              return updateSoilData(dataId, dt)
                .then((res) => {
                  setError('');
                  return { isConfirmed: true };
                })
                .catch((error) => {
                  Swal.fire({
                    title: 'An Error occured!',
                    icon: 'error',
                    confirmButtonColor: '#28a745',
                  });
                });
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              await Swal.fire({
                icon: 'success',
                title: 'Vapour data successfully updated!',
                confirmButtonColor: '#28a745',
              });
              window.location.href = `${process.env.MIX_APP_URL}/vapour`;
            }
          });
        } else {
          const redirectId = reply.data.find(
            (item) =>
              item.name.trim().toLowerCase() == name.trim().toLowerCase() &&
              item.status == 'active'
          ).id;
          Swal.fire({
            title: `Vapour with the name ${name} already exists!`,
            text: 'You have to replace the existing data first OR rename the current data.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#309e1d',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Rename this data',
            confirmButtonText: 'Replace the existing data',
            allowOutsideClick: false,
            didDestroy: () => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            },
          }).then((result) => {
            if (result.value) {
              setError('');
              history.push(`/vapour/${vapourType}/${redirectId}`);
              setIsReplace(true);
            } else {
              setIsReplace(false);
            }
          });
        }
      } else if (vapourType === 'groundwater') {
        const reply = await APIService.get.vapourGroundWater.all();
        if (
          !reply.data.some(
            (item) =>
              item.name.trim().toLowerCase() !==
                groundwater[dataId].name.toLowerCase() &&
              item.name.trim().toLowerCase() == name.trim().toLowerCase() &&
              item.status == 'active'
          )
        ) {
          Swal.fire({
            title: 'Check your data',
            text: 'Review your data before submission.',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
              return updateGroundwaterData(dataId, dt)
                .then((res) => {
                  setError('');
                  return { isConfirmed: true };
                })
                .catch((error) => {
                  Swal.fire({
                    title: 'An Error occured!',
                    icon: 'error',
                    confirmButtonColor: '#28a745',
                  });
                });
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              await Swal.fire({
                icon: 'success',
                title: 'Vapour data successfully updated!',
                confirmButtonColor: '#28a745',
              });
              window.location.href = `${process.env.MIX_APP_URL}/vapour`;
            }
          });
        } else {
          const redirectId = reply.data.find(
            (item) =>
              item.name.trim().toLowerCase() == name.trim().toLowerCase() &&
              item.status == 'active'
          ).id;
          Swal.fire({
            title: `Vapour with the name ${name} already exists!`,
            text: 'You have to replace the existing data first OR rename the current data.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#309e1d',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Rename this data',
            confirmButtonText: 'Replace the existing data',
            allowOutsideClick: false,
            didDestroy: () => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            },
          }).then((result) => {
            if (result.value) {
              setError('');
              history.push(`/vapour/${vapourType}/${redirectId}`);
              setIsReplace(true);
            } else {
              setIsReplace(false);
            }
          });
        }
      } else if (vapourType === 'soil-vapour') {
        const reply = await APIService.get.vapourSoilVapour.all();
        if (
          !reply.data.some(
            (item) =>
              item.name.trim().toLowerCase() !==
                soilVapour[dataId].name.toLowerCase() &&
              item.name.trim().toLowerCase() == name.trim().toLowerCase() &&
              item.status == 'active'
          )
        ) {
          Swal.fire({
            title: 'Check your data',
            text: 'Review your data before submission.',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
              return updateSoilVapourData(dataId, dt)
                .then((res) => {
                  setError('');
                  return { isConfirmed: true };
                })
                .catch((error) => {
                  Swal.fire({
                    title: 'An Error occured!',
                    icon: 'error',
                    confirmButtonColor: '#28a745',
                  });
                });
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              await Swal.fire({
                icon: 'success',
                title: 'Vapour data successfully updated!',
                confirmButtonColor: '#28a745',
              });
              window.location.href = `${process.env.MIX_APP_URL}/vapour`;
            }
          });
        } else {
          const redirectId = reply.data.find(
            (item) =>
              item.name.trim().toLowerCase() == name.trim().toLowerCase() &&
              item.status == 'active'
          ).id;
          Swal.fire({
            title: `Vapour with the name ${name} already exists!`,
            text: 'You have to replace the existing data first OR rename the current data.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#309e1d',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Rename this data',
            confirmButtonText: 'Replace the existing data',
            allowOutsideClick: false,
            didDestroy: () => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            },
          }).then((result) => {
            if (result.value) {
              setError('');
              history.push(`/vapour/${vapourType}/${redirectId}`);
              setIsReplace(true);
            } else {
              setIsReplace(false);
            }
          });
        }
      }
    } catch (err) {
      await Swal.fire({
        title: 'An Error occured!',
        icon: 'error',
        confirmButtonColor: '#28a745',
      });
    }
  };

  const handleDelete = async () => {
    const result = await Swal.fire({
      title: 'Delete this data?',
      text: 'All of this related data will be deleted permanently',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    });
    if (result.value) {
      try {
        if (vapourType === 'soil') {
          await deleteData(RESOURCE_NAMES['VAPOUR-INTRUSION-SOILS'], dataId);
          window.location.href = `${process.env.MIX_APP_URL}/vapour`;
        } else if (vapourType === 'groundwater') {
          await deleteData(
            RESOURCE_NAMES['VAPOUR-INTRUSION-GROUNDWATERS'],
            dataId
          );
          window.location.href = `${process.env.MIX_APP_URL}/vapour`;
        } else if (vapourType === 'soil-vapour') {
          await deleteData(
            RESOURCE_NAMES['VAPOUR-INTRUSION-SOIL-VAPOURS'],
            dataId
          );
          window.location.href = `${process.env.MIX_APP_URL}/vapour`;
        }
      } catch (err) {
        await Swal.fire({
          title: 'An Error occured!',
          icon: 'error',
          confirmButtonColor: '#28a745',
        });
      }
    }
  };

  const handleDisabled = () => {
    if (name.trim() === '') {
      return true;
    }
    if (sandAb.some((item) => item === '')) {
      return true;
    }
    if (sandC.some((item) => item === '')) {
      return true;
    }
    if (sandD.some((item) => item === '')) {
      return true;
    }

    if (siltAb.some((item) => item === '')) {
      return true;
    }
    if (siltC.some((item) => item === '')) {
      return true;
    }
    if (siltD.some((item) => item === '')) {
      return true;
    }

    if (clayAb.some((item) => item === '')) {
      return true;
    }
    if (clayC.some((item) => item === '')) {
      return true;
    }
    if (clayD.some((item) => item === '')) {
      return true;
    }
  };

  return (
    <div>
      {dataId ? (
        <PageTitle
          title="Edit Vapour Intrusion"
          description="Edit your existing Volatile Organic Compound (VOC) data."
          className="bi-wind opacity-90"
        />
      ) : (
        <PageTitle
          title="Add New Vapour Intrusion"
          description="Create your new Volatile Organic Compound (VOC) data."
          className="bi-wind opacity-90"
        />
      )}

      <div className="flex flex-col px-6 pt-2 pb-6 space-y-8">
        <Card title={cardTitle}>
          <div className="space-y-2">
            {error !== '' && <p className="text-red-500">* {error}</p>}
            <div>
              <p className="text-sm mb-1">Contaminant Name</p>
              <input
                className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                name="contaminant"
                type="text"
                placeholder="New Contaminant"
                onChange={(e) => setName(e.target.value)}
                value={name}
                disabled={disabled}
              />
            </div>
          </div>
        </Card>

        {/* SAND FORM */}
        <Card title={dataId ? 'Edit Sand' : 'Sand'}>
          <div>
            {width > 1024 && (
              <div className="flex flex-row">
                <h2 className="py-4 px-2 border border-white font-semibold bg-xgreen text-white text-center w-1/3">
                  {'HSL A&B - Low_high Density Residential'}
                </h2>
                <h2 className="py-4 px-2 border border-white font-semibold bg-xgreen text-white text-center w-1/3">
                  {'HSL C - Recreational/Open Space'}
                </h2>
                <h2 className="py-4 px-2 border border-white font-semibold bg-xgreen text-white text-center w-1/3">
                  {'HSL D - Commercial/Industrial'}
                </h2>
              </div>
            )}
            <div className="flex flex-col lg:flex-row">
              {/* SAND HSL AB */}
              <div className="lg:w-1/3">
                {width < 1024 && (
                  <h2 className="py-4 px-2 border border-white font-semibold bg-xgreen text-white text-center">
                    {'HSL A&B - Low_high Density Residential'}
                  </h2>
                )}

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'0 to < 1 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...sandAb];
                        temp[0] = e.target.value;
                        setSandAb(temp);
                      }}
                      value={sandAb[0]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'1 m to < 2 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...sandAb];
                        temp[1] = e.target.value;
                        setSandAb(temp);
                      }}
                      value={sandAb[1]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'2 m to < 4 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...sandAb];
                        temp[2] = e.target.value;
                        setSandAb(temp);
                      }}
                      value={sandAb[2]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'4 m to < 8 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...sandAb];
                        temp[3] = e.target.value;
                        setSandAb(temp);
                      }}
                      value={sandAb[3]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'8 m + :'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...sandAb];
                        temp[4] = e.target.value;
                        setSandAb(temp);
                      }}
                      value={sandAb[4]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>
              </div>
              {/* SAND HSL C */}
              <div className="lg:w-1/3">
                {width < 1024 && (
                  <h2 className="py-4 px-2 border border-white font-semibold bg-xgreen text-white text-center">
                    {'HSL C - Recreational/Open Space'}
                  </h2>
                )}

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'0 to < 1 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...sandC];
                        temp[0] = e.target.value;
                        setSandC(temp);
                      }}
                      value={sandC[0]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'1 m to < 2 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...sandC];
                        temp[1] = e.target.value;
                        setSandC(temp);
                      }}
                      value={sandC[1]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'2 m to < 4 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...sandC];
                        temp[2] = e.target.value;
                        setSandC(temp);
                      }}
                      value={sandC[2]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'4 m to < 8 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...sandC];
                        temp[3] = e.target.value;
                        setSandC(temp);
                      }}
                      value={sandC[3]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'8 m + :'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...sandC];
                        temp[4] = e.target.value;
                        setSandC(temp);
                      }}
                      value={sandC[4]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>
              </div>
              {/* SAND HSL D */}
              <div className="lg:w-1/3">
                {width < 1024 && (
                  <h2 className="py-4 px-2 border border-white font-semibold bg-xgreen text-white text-center">
                    {'HSL D - Commercial/Industrial'}
                  </h2>
                )}

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'0 to < 1 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...sandD];
                        temp[0] = e.target.value;
                        setSandD(temp);
                      }}
                      value={sandD[0]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'1 m to < 2 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...sandD];
                        temp[1] = e.target.value;
                        setSandD(temp);
                      }}
                      value={sandD[1]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'2 m to < 4 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...sandD];
                        temp[2] = e.target.value;
                        setSandD(temp);
                      }}
                      value={sandD[2]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'4 m to < 8 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...sandD];
                        temp[3] = e.target.value;
                        setSandD(temp);
                      }}
                      value={sandD[3]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'8 m + :'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...sandD];
                        temp[4] = e.target.value;
                        setSandD(temp);
                      }}
                      value={sandD[4]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>

        {/* SILT FORM */}
        <Card title={dataId ? 'Edit Silt' : 'Silt'}>
          <div>
            {width > 1024 && (
              <div className="flex flex-row">
                <h2 className="py-4 px-2 border border-white font-semibold bg-xgreen text-white text-center w-1/3">
                  {'HSL A&B - Low_high Density Residential'}
                </h2>
                <h2 className="py-4 px-2 border border-white font-semibold bg-xgreen text-white text-center w-1/3">
                  {'HSL C - Recreational/Open Space'}
                </h2>
                <h2 className="py-4 px-2 border border-white font-semibold bg-xgreen text-white text-center w-1/3">
                  {'HSL D - Commercial/Industrial'}
                </h2>
              </div>
            )}
            <div className="flex flex-col lg:flex-row">
              {/* SILT HSL AB */}
              <div className="lg:w-1/3">
                {width < 1024 && (
                  <h2 className="py-4 px-2 border border-white font-semibold bg-xgreen text-white text-center">
                    {'HSL A&B - Low_high Density Residential'}
                  </h2>
                )}

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'0 to < 1 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...siltAb];
                        temp[0] = e.target.value;
                        setSiltAb(temp);
                      }}
                      value={siltAb[0]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'1 m to < 2 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...siltAb];
                        temp[1] = e.target.value;
                        setSiltAb(temp);
                      }}
                      value={siltAb[1]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'2 m to < 4 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...siltAb];
                        temp[2] = e.target.value;
                        setSiltAb(temp);
                      }}
                      value={siltAb[2]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'4 m to < 8 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...siltAb];
                        temp[3] = e.target.value;
                        setSiltAb(temp);
                      }}
                      value={siltAb[3]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'8 m + :'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...siltAb];
                        temp[4] = e.target.value;
                        setSiltAb(temp);
                      }}
                      value={siltAb[4]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>
              </div>
              {/* SILT HSL C */}
              <div className="lg:w-1/3">
                {width < 1024 && (
                  <h2 className="py-4 px-2 border border-white font-semibold bg-xgreen text-white text-center">
                    {'HSL C - Recreational/Open Space'}
                  </h2>
                )}

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'0 to < 1 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...siltC];
                        temp[0] = e.target.value;
                        setSiltC(temp);
                      }}
                      value={siltC[0]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'1 m to < 2 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...siltC];
                        temp[1] = e.target.value;
                        setSiltC(temp);
                      }}
                      value={siltC[1]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'2 m to < 4 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...siltC];
                        temp[2] = e.target.value;
                        setSiltC(temp);
                      }}
                      value={siltC[2]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'4 m to < 8 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...siltC];
                        temp[3] = e.target.value;
                        setSiltC(temp);
                      }}
                      value={siltC[3]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'8 m + :'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...siltC];
                        temp[4] = e.target.value;
                        setSiltC(temp);
                      }}
                      value={siltC[4]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>
              </div>
              {/* SAND HSL D */}
              <div className="lg:w-1/3">
                {width < 1024 && (
                  <h2 className="py-4 px-2 border border-white font-semibold bg-xgreen text-white text-center">
                    {'HSL D - Commercial/Industrial'}
                  </h2>
                )}

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'0 to < 1 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...siltD];
                        temp[0] = e.target.value;
                        setSiltD(temp);
                      }}
                      value={siltD[0]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'1 m to < 2 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...siltD];
                        temp[1] = e.target.value;
                        setSiltD(temp);
                      }}
                      value={siltD[1]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'2 m to < 4 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...siltD];
                        temp[2] = e.target.value;
                        setSiltD(temp);
                      }}
                      value={siltD[2]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'4 m to < 8 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...siltD];
                        temp[3] = e.target.value;
                        setSiltD(temp);
                      }}
                      value={siltD[3]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'8 m + :'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...siltD];
                        temp[4] = e.target.value;
                        setSiltD(temp);
                      }}
                      value={siltD[4]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>

        {/* CLAY FORM */}
        <Card title={dataId ? 'Edit Clay' : 'Clay'}>
          <div>
            {width > 1024 && (
              <div className="flex flex-row">
                <h2 className="py-4 px-2 border border-white font-semibold bg-xgreen text-white text-center w-1/3">
                  {'HSL A&B - Low_high Density Residential'}
                </h2>
                <h2 className="py-4 px-2 border border-white font-semibold bg-xgreen text-white text-center w-1/3">
                  {'HSL C - Recreational/Open Space'}
                </h2>
                <h2 className="py-4 px-2 border border-white font-semibold bg-xgreen text-white text-center w-1/3">
                  {'HSL D - Commercial/Industrial'}
                </h2>
              </div>
            )}
            <div className="flex flex-col lg:flex-row">
              {/* CLAY HSL AB */}
              <div className="lg:w-1/3">
                {width < 1024 && (
                  <h2 className="py-4 px-2 border border-white font-semibold bg-xgreen text-white text-center">
                    {'HSL A&B - Low_high Density Residential'}
                  </h2>
                )}

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'0 to < 1 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...clayAb];
                        temp[0] = e.target.value;
                        setClayAb(temp);
                      }}
                      value={clayAb[0]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'1 m to < 2 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...clayAb];
                        temp[1] = e.target.value;
                        setClayAb(temp);
                      }}
                      value={clayAb[1]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'2 m to < 4 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...clayAb];
                        temp[2] = e.target.value;
                        setClayAb(temp);
                      }}
                      value={clayAb[2]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'4 m to < 8 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...clayAb];
                        temp[3] = e.target.value;
                        setClayAb(temp);
                      }}
                      value={clayAb[3]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'8 m + :'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...clayAb];
                        temp[4] = e.target.value;
                        setClayAb(temp);
                      }}
                      value={clayAb[4]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>
              </div>
              {/* CLAY HSL C */}
              <div className="lg:w-1/3">
                {width < 1024 && (
                  <h2 className="py-4 px-2 border border-white font-semibold bg-xgreen text-white text-center">
                    {'HSL C - Recreational/Open Space'}
                  </h2>
                )}

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'0 to < 1 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...clayC];
                        temp[0] = e.target.value;
                        setClayC(temp);
                      }}
                      value={clayC[0]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'1 m to < 2 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...clayC];
                        temp[1] = e.target.value;
                        setClayC(temp);
                      }}
                      value={clayC[1]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'2 m to < 4 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...clayC];
                        temp[2] = e.target.value;
                        setClayC(temp);
                      }}
                      value={clayC[2]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'4 m to < 8 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...clayC];
                        temp[3] = e.target.value;
                        setClayC(temp);
                      }}
                      value={clayC[3]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'8 m + :'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...clayC];
                        temp[4] = e.target.value;
                        setClayC(temp);
                      }}
                      value={clayC[4]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>
              </div>
              {/* CLAY HSL D */}
              <div className="lg:w-1/3">
                {width < 1024 && (
                  <h2 className="py-4 px-2 border border-white font-semibold bg-xgreen text-white text-center">
                    {'HSL D - Commercial/Industrial'}
                  </h2>
                )}

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'0 to < 1 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...clayD];
                        temp[0] = e.target.value;
                        setClayD(temp);
                      }}
                      value={clayD[0]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'1 m to < 2 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...clayD];
                        temp[1] = e.target.value;
                        setClayD(temp);
                      }}
                      value={clayD[1]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'2 m to < 4 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...clayD];
                        temp[2] = e.target.value;
                        setClayD(temp);
                      }}
                      value={clayD[2]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'4 m to < 8 m:'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...clayD];
                        temp[3] = e.target.value;
                        setClayD(temp);
                      }}
                      value={clayD[3]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>

                <div className="py-2 pl-3 pr-3 lg:pr-8">
                  <p className="text-sm mb-1 lg:ml-3">{'8 m + :'}</p>
                  <div className="flex flex-row justify-center items-center space-x-2 w-full">
                    <input
                      className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                      name="contaminant"
                      type="number"
                      placeholder={0}
                      onChange={(e) => {
                        const temp = [...clayD];
                        temp[4] = e.target.value;
                        setClayD(temp);
                      }}
                      value={clayD[4]}
                      disabled={disabled}
                    />
                    <span>ug/L</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>

        {/* Button */}
        <div
          className={classNames(
            { 'flex-row-reverse': !dataId },
            'flex flex-row justify-between px-4'
          )}
        >
          {dataId && disabled && vapourType ? (
            (vapourType == 'soil' &&
              soil[dataId] &&
              (SessionUtils.getUserData().role == 'admin' ||
                SessionUtils.getUserData().id == soil[dataId].userId)) ||
            (vapourType == 'groundwater' &&
              groundwater[dataId] &&
              (SessionUtils.getUserData().role == 'admin' ||
                SessionUtils.getUserData().id == groundwater[dataId].userId)) ||
            (vapourType == 'soil-vapour' &&
              soilVapour[dataId] &&
              (SessionUtils.getUserData().role == 'admin' ||
                SessionUtils.getUserData().id == soilVapour[dataId].userId)) ? (
              <span
                className="cursor-pointer text-green-500"
                onClick={() => setDisabled(false)}
              >
                Edit
              </span>
            ) : null
          ) : null}

          {dataId && !disabled && vapourType ? (
            (vapourType == 'soil' &&
              soil[dataId] &&
              (SessionUtils.getUserData().role == 'admin' ||
                SessionUtils.getUserData().id == soil[dataId].userId)) ||
            (vapourType == 'groundwater' &&
              groundwater[dataId] &&
              (SessionUtils.getUserData().role == 'admin' ||
                SessionUtils.getUserData().id == groundwater[dataId].userId)) ||
            (vapourType == 'soil-vapour' &&
              soilVapour[dataId] &&
              (SessionUtils.getUserData().role == 'admin' ||
                SessionUtils.getUserData().id == soilVapour[dataId].userId)) ? (
              <span
                className="cursor-pointer  text-red-500"
                onClick={handleDelete}
              >
                Delete
              </span>
            ) : null
          ) : null}

          {dataId ? (
            !disabled && vapourType ? (
              (vapourType == 'soil' &&
                soil[dataId] &&
                (SessionUtils.getUserData().role == 'admin' ||
                  SessionUtils.getUserData().id == soil[dataId].userId)) ||
              (vapourType == 'groundwater' &&
                groundwater[dataId] &&
                (SessionUtils.getUserData().role == 'admin' ||
                  SessionUtils.getUserData().id ==
                    groundwater[dataId].userId)) ||
              (vapourType == 'soil-vapour' &&
                soilVapour[dataId] &&
                (SessionUtils.getUserData().role == 'admin' ||
                  SessionUtils.getUserData().id ==
                    soilVapour[dataId].userId)) ? (
                <Button
                  lowercase
                  theme="success"
                  text="Update Data"
                  onClick={handleUpdate}
                  disabled={handleDisabled()}
                />
              ) : null
            ) : null
          ) : (
            <Button
              lowercase
              theme="success"
              text="Submit"
              onClick={handleAdd}
              disabled={handleDisabled()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  soil: getResource(RESOURCE_NAMES['VAPOUR-INTRUSION-SOILS'])(state),
  groundwater: getResource(RESOURCE_NAMES['VAPOUR-INTRUSION-GROUNDWATERS'])(
    state
  ),
  soilVapour: getResource(RESOURCE_NAMES['VAPOUR-INTRUSION-SOIL-VAPOURS'])(
    state
  ),
});

export default connect(mapStateToProps, {
  getDataById: _getDataById,
  addSoilData: _addData(RESOURCE_NAMES['VAPOUR-INTRUSION-SOILS']),
  addGroundwaterData: _addData(RESOURCE_NAMES['VAPOUR-INTRUSION-GROUNDWATERS']),
  addSoilVapourData: _addData(RESOURCE_NAMES['VAPOUR-INTRUSION-SOIL-VAPOURS']),
  updateSoilData: _updateData(RESOURCE_NAMES['VAPOUR-INTRUSION-SOILS']),
  updateGroundwaterData: _updateData(
    RESOURCE_NAMES['VAPOUR-INTRUSION-GROUNDWATERS']
  ),
  updateSoilVapourData: _updateData(
    RESOURCE_NAMES['VAPOUR-INTRUSION-SOIL-VAPOURS']
  ),
  deleteData: _deleteData,
})(VapourForm);
