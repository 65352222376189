export const soilContaminants = [
  'Metals and Inorganics',
  'Polycyclic Aromatic Hydrocarbons (PAHs)',
  'Phenols',
  'Organochlorine Pesticides',
  'Herbicides',
  'Other Pesticides',
  'Other Organics',
];

export const groundwaterContaminants = [
  'Metals and Metalloids',
  'Non-metallic Inorganics',
  'Organic alchohols/other organics',
  'Anilines',
  'Chlorinated Alkanes',
  'Chlorinated Alkenes',
  'Chlorinated Benzenes',
  'Polychlorinated Biphenyls (PCBs)',
  'Other Chlorinated Compounds',
  'Monocyclic Aromatic Hydrocarbons',
  'Polycyclic Aromatic Hydrocarbons (PAHs)',
  'Phenols',
  'Phthalates',
  'Pesticides',
  'Surfactants',
];

export const vapourIntrusionContaminants = [
  'Soil',
  'Groundwater',
  'Soil Vapour',
];
