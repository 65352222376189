import md5 from 'md5';

// Map localStorage key name to hashed strings.
const keys = {
  token: md5('token'),
  user: md5('user'),
  exp: md5('exp'),
};

// LocalStorage helpers
const storage = {
  // Setter functions
  set token(data) {
    localStorage.setItem(keys.token, data);
  },
  set user(data) {
    localStorage.setItem(keys.user, btoa(JSON.stringify(data)));
  },
  set exp(data) {
    localStorage.setItem(keys.exp, data);
  },

  // Getter functions
  get token() {
    return localStorage.getItem(keys.token);
  },
  get user() {
    try {
      const decoded = atob(localStorage.getItem(keys.user));
      return JSON.parse(decoded);
    } catch (e) {
      return {};
    }
  },
  get exp() {
    return localStorage.getItem(keys.exp);
  },

  // Additional helper functions
  get hasSession() {
    return Boolean(this.token) && Boolean(this.user) && Boolean(this.exp);
  },
  get isExpired() {
    return Date.now() > this.exp * 1000;
  },
};

/**
 * Function that clears session, i.e. deletes the localStorage.
 */
const clearSession = () => localStorage.clear();

/**
 * Function that returns session auth status.
 *
 * @return   {Boolean}         Authentication status.
 */
const isAuthed = () => {
  if (!storage.hasSession || storage.isExpired) {
    SessionUtils.clearSession();
    return false;
  }
  return true;
};

/**
 * Function that sets token in localStorage.
 *
 * @param   {String} token         Token string from API.
 */
const setToken = (token) => {
  storage.token = token;
};

/**
 * Function that sets user token, user data, and token expiration time in localStorage.
 *
 * @param   {String} token         Token string.
 * @param   {Object} user          User data object.
 * @param   {Integer} exp          Token expiration date in Unix timestamp.
 */
const setUserData = (token, user, exp) => {
  storage.token = token;
  storage.user = user;
  storage.exp = exp;
};

/**
 * Function that gets user data object from localStorage. Returns empty object if user is not authenticated.
 *
 * @return   {Object}         User data object.
 */
const getUserData = () => {
  if (!isAuthed()) {
    return {};
  }

  return storage.user;
};

/**
 * Function that gets session token from localStorage.
 *
 * @return   {String}         Token string.
 */
const getToken = () => {
  return storage.token;
};

/**
 * Function that gets token expiration time from localStorage.
 *
 * @return   {String}         Token expiration time in Unix timestamp.
 */
const getExp = () => {
  return storage.exp;
};

/**
 * Holds various function for accessing and setting user session data from localStorage.
 *
 * @namespace
 */
export const SessionUtils = {
  clearSession,
  setToken,
  setUserData,
  getUserData,
  getToken,
  getExp,
  isAuthed,
};
