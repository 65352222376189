import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Button, Card, PageTitle, Table } from '../../components';
import { SessionUtils, UserService } from '../../utils';

import { connect } from 'react-redux';
import { RESOURCE_NAMES } from '../../store/reducers/resources';
import {
  getAllData as _getAlldata,
  deleteData as _deleteData,
} from '../../store/actions/resources';
import { getResource } from '../../store/selectors/resources';

const Soil = ({ getAllData, deleteData, soil }) => {
  const [soilTable, setSoilTable] = useState([]);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    getAllData(RESOURCE_NAMES['SOIL-HSLS']);
    UserService.getAllUsers().then((res) => {
      setUserData(res);
    });
  }, []);

  useEffect(() => {
    if ((soil, userData)) {
      let newSoil = _.orderBy(_.values(soil), ['created_at'], ['desc'])
        .filter((item) => {
          if (SessionUtils.getUserData().role === 'admin') {
            return item.status === 'active';
          } else {
            return (
              item.status === 'active' &&
              (item.userId === SessionUtils.getUserData().id ||
                item.userId === 1)
            );
          }
        })
        .map((item) => {
          const actions =
            SessionUtils.getUserData().role === 'admin' ||
            item.userId === SessionUtils.getUserData().id ? (
              <div className="flex flex-row flex-grow space-x-2">
                <Link
                  to={`/soil-hsls/${item.id}`}
                  className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-blue-400 text-blue-400 hover:bg-blue-500 hover:text-white focus:outline-none"
                >
                  <div className="self-center align-middle font-normal text-lg pe-7s-look transition duration-200" />
                </Link>
                <button
                  onClick={() => handleDelete(item.id)}
                  className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-red-400 text-red-400 hover:bg-red-600 hover:text-white focus:outline-none"
                >
                  <div className="self-center align-middle font-normal text-lg pe-7s-trash transition duration-200" />
                </button>
              </div>
            ) : (
              <Link
                to={`/soil-hsls/${item.id}`}
                className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-blue-400 text-blue-400 hover:bg-blue-500 hover:text-white focus:outline-none"
              >
                <div className="self-center align-middle font-normal text-lg pe-7s-look transition duration-200" />
              </Link>
            );

          let username = '';
          if (SessionUtils.getUserData().role === 'admin') {
            username = userData.find((user) => user.id === item.userId).name;
          } else {
            if (SessionUtils.getUserData().id === item.userId) {
              username = SessionUtils.getUserData().name;
            } else {
              username = 'Administrator';
            }
          }

          return {
            Username: username,
            Chemicals: item.name,
            'Chemical Group': item.group,
            'Residential A': item.residentialA,
            'Residential B': item.residentialB,
            'Recreational C': item.recreationalC,
            'Commercial/industrial D': item.commercialD,
            Actions: actions,
          };
        });
      setSoilTable(newSoil);
    }
  }, [soil, userData]);

  /** Function for deleting data */
  const handleDelete = async (dataId) => {
    const result = await Swal.fire({
      title: 'Delete this data?',
      text: 'All of this related data will be deleted permanently',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    });
    if (result.value) {
      try {
        deleteData(RESOURCE_NAMES['SOIL-HSLS'], dataId);
      } catch (err) {
        await Swal.fire({
          title: 'An Error occured!',
          icon: 'error',
        });
      }
    }
  };

  return (
    <div>
      <PageTitle
        title="Soil HSLs"
        description="The current list of soil substances."
        className="pe-7s-leaf"
      />
      <div className="flex flex-col px-6 pt-2 pb-6 space-y-8">
        <Card
          title="THE CURRENT LIST OF SOIL CONTAMINANTS (MG/KG)"
          right={
            <Button
              to="/soil-hsls/new"
              theme="success"
              text="Add Contaminants"
            />
          }
        >
          <Table data={soilTable} show={10} />
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  soil: getResource(RESOURCE_NAMES['SOIL-HSLS'])(state),
});

export default connect(mapStateToProps, {
  getAllData: _getAlldata,
  deleteData: _deleteData,
})(Soil);
