import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import 'chartkick/chart.js';
import { PieChart as Pie } from 'react-chartkick';
import { Card, PageTitle, Table } from '../../components';
import { APIService, UserService } from '../../utils';

/** Function to get random color. Taken from mineCARE */
const getRandomColor = () => {
  const letters = '0123456789ABCDEF'.split('');
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 8)];
  }
  return color;
};

const PieChart = () => {
  const [fileData, setFileData] = useState([]);
  const [soilData, setSoilData] = useState([]);
  const [groundwaterData, setGroundwaterData] = useState([]);
  const [vapourData, setVapourData] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const [{ data: pieChartData }, users] = await Promise.all([
        APIService.get.pieChart(),
        UserService.getAllUsers(),
      ]);

      // Take top 5 users who inputted the most files
      const topUsers = [...pieChartData]
        .sort((a, b) => b.file - a.file)
        .slice(0, 5);

      const userByIds = _.keyBy(users, 'id');

      // generate table data
      const newTableData = _.map(topUsers, (item) => {
        const user = userByIds[item.userId];
        return {
          User: user.name,
          Measurement: _.get(item, 'file'),
          'Soil HSLs substance': _.get(item, 'soil'),
          'Groundwater HSLs substance': _.get(item, 'groundwater'),
          'Vapour Intrusion substance': _.get(item, 'vapour'),
        };
      });
      setTableData(newTableData);

      // generate pie chart file data
      const newFileData = _(topUsers)
        .reject({ file: 0 })
        .orderBy('file', 'desc')
        .map((item) => {
          const user = userByIds[item.userId];
          return [user.name, item.file];
        })
        .value();
      setFileData(newFileData);

      // generate pie chart soil data
      const newSoilData = _(topUsers)
        .reject({ soil: 0 })
        .orderBy('soil', 'desc')
        .map((item) => {
          const user = userByIds[item.userId];
          return [user.name, item.soil];
        })
        .value();
      setSoilData(newSoilData);

      // generate pie chart groundwater data
      const newGroundwaterData = _(topUsers)
        .reject({ groundwater: 0 })
        .orderBy('groundwater', 'desc')
        .map((item) => {
          const user = userByIds[item.userId];
          return [user.name, item.groundwater];
        })
        .value();
      setGroundwaterData(newGroundwaterData);

      // generate pie chart vapour data
      const newVapourData = _(topUsers)
        .reject({ vapour: 0 })
        .orderBy('vapour', 'desc')
        .map((item) => {
          const user = userByIds[item.userId];
          return [user.name, item.vapour];
        })
        .value();
      setVapourData(newVapourData);
    };
    getData();
  }, []);

  return (
    <div>
      <PageTitle
        title="Pie Chart"
        description="View information about user activities."
        className="pe-7s-graph"
      />
      <div className="flex flex-col px-6 pt-2 pb-6 space-y-8">
        {/* PIE CHART */}
        <Card title={'Pie Chart'}>
          <div className="flex flex-col space-y-4">
            <div className="grid grid-cols-1 lg:grid-cols-4">
              <div className="flex flex-col m-5 items-center">
                <Pie
                  data={fileData}
                  height="400px"
                  dataset={{
                    backgroundColor: Array.from(Array(fileData.length)).map(
                      () => getRandomColor()
                    ),
                  }}
                />
                <span className="font-semibold">Measurement</span>
              </div>

              <div className="flex flex-col m-5 items-center">
                <Pie
                  data={soilData}
                  height="400px"
                  dataset={{
                    backgroundColor: Array.from(Array(soilData.length)).map(
                      () => getRandomColor()
                    ),
                  }}
                />
                <span className="font-semibold text-center">
                  Soil HSLs substance
                </span>
              </div>

              <div className="flex flex-col m-5 items-center">
                <Pie
                  data={groundwaterData}
                  height="400px"
                  dataset={{
                    backgroundColor: Array.from(
                      Array(groundwaterData.length)
                    ).map(() => getRandomColor()),
                  }}
                />
                <span className="font-semibold text-center">
                  Groundwater HSLs substance
                </span>
              </div>

              <div className="flex flex-col m-5 items-center">
                <Pie
                  data={vapourData}
                  height="400px"
                  dataset={{
                    backgroundColor: Array.from(
                      Array(groundwaterData.length)
                    ).map(() => getRandomColor()),
                  }}
                />
                <span className="font-semibold text-center">
                  Vapour Intrusion substance
                </span>
              </div>
            </div>
            {/* TABLE */}
            <Table data={tableData} />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default PieChart;
