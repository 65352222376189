export const landUsesOption = [
  {
    id: 1,
    label: 'Residential with garden/accessible soil.(HIL A)',
  },
  {
    id: 2,
    label: 'Residential with minimal opportunities for soil access.(HIL B)',
  },
  {
    id: 3,
    label:
      'Public open space such as parks, playgrounds, playing fields (e.g. ovals), secondary schools and footpaths.(HIL C)',
  },
  {
    id: 4,
    label:
      'Commercial/industrial includes premises such as shops, offices, factories and industrial sites.(HIL D)',
  },
];

export const groundwaterType = [
  {
    id: 1,
    label: 'Drinking Water',
  },
  {
    id: 2,
    label: 'Fresh Water',
  },
  {
    id: 3,
    label: 'Marine Water',
  },
];

export const vapourSampleType = [
  {
    id: 1,
    label: 'Soil',
    unit: 'mg/Kg',
  },
  {
    id: 2,
    label: 'Groundwater',
    unit: 'mg/L',
  },
  {
    id: 3,
    label: 'Soil Vapour',
    unit: ['mg/m', <sup>3</sup>],
  },
];

export const vapourSampleDepth = [
  {
    id: '01',
    label: '0 to < 1 meter',
  },
  {
    id: '12',
    label: '1 to < 2 meter',
  },
  {
    id: '24',
    label: '2 to < 4 meter',
  },
  {
    id: '48',
    label: '4 to < 8 meter',
  },
  {
    id: '8',
    label: '>8 meters',
  },
];

export const vapourSoilTexture = [
  {
    id: 1,
    label: 'Sand',
  },
  {
    id: 2,
    label: 'Silt',
  },
  {
    id: 3,
    label: 'Clay',
  },
];
