import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, Card, PageTitle, Select } from '../../components';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import {
  groundwaterContaminants,
  soilContaminants,
  vapourIntrusionContaminants,
} from './constants';

import { connect } from 'react-redux';
import { RESOURCE_NAMES } from '../../store/reducers/resources';
import { getAllData as _getAlldata } from '../../store/actions/resources';
import { getResource } from '../../store/selectors/resources';
import { SessionUtils } from '../../utils';

const Infocare = ({
  getAllData,
  soil,
  groundwater,
  vapourSoil,
  vapourGroundwater,
  vapourSoilVapour,
}) => {
  let history = useHistory();

  const [soilDown, setSoilDown] = useState([]);
  const [groundwaterDown, setGroundwaterDown] = useState([]);
  const [vapourDown, setVapourDown] = useState([]);

  // filter sub list for the select
  const [soilSubList, setSoilSubList] = useState([]);
  const [groundwaterSubList, setGroundwaterSubList] = useState([]);
  const [vapourSubList, setVapourSubList] = useState([]);

  // filter contaminant value
  const [soilContValue, setSoilContValue] = useState('');
  const [groundwaterContValue, setGroundwaterContValue] = useState('');
  const [vapourContValue, setVapourContValue] = useState('');

  // search and filter substance value
  const [searchValue, setSearchValue] = useState('');
  const [soilSubValue, setSoilSubValue] = useState('');
  const [groundwaterSubValue, setGroundwaterSubValue] = useState('');
  const [vapourSubValue, setVapourSubValue] = useState('');

  const [soilExpandBtn, setSoilExpandBtn] = useState(true);
  const [groundwaterExpandBtn, setGroundwaterExpandBtn] = useState(true);
  const [vapourExpandBtn, setVapourExpandBtn] = useState(true);

  const [filterDisabled, setFilterDisabled] = useState({
    search: false,
    soil: false,
    groundwater: false,
    vapour: false,
  });

  const [soilSubError, setSoilSubError] = useState(false);
  const [groundwaterSubError, setGroundwaterSubError] = useState(false);
  const [vapourSubError, setVapourSubError] = useState(false);

  useEffect(() => {
    getAllData(RESOURCE_NAMES['SOIL-HSLS']);
    getAllData(RESOURCE_NAMES['GROUNDWATER-HSLS']);
    getAllData(RESOURCE_NAMES['VAPOUR-INTRUSION-SOILS']);
    getAllData(RESOURCE_NAMES['VAPOUR-INTRUSION-GROUNDWATERS']);
    getAllData(RESOURCE_NAMES['VAPOUR-INTRUSION-SOIL-VAPOURS']);
  }, []);

  useEffect(() => {
    let ss = [];
    soilContaminants.forEach((cont) => {
      let sub = _(soil)
        .values()
        .orderBy('name', 'asc')
        .filter((item) => {
          if (SessionUtils.getUserData().role === 'admin') {
            return (
              item.group.toLowerCase() == cont.toLowerCase() &&
              item.status === 'active'
            );
          } else {
            return (
              item.group.toLowerCase() == cont.toLowerCase() &&
              item.status === 'active' &&
              (item.userId === SessionUtils.getUserData().id ||
                item.userId === 1)
            );
          }
        })
        .value();
      const newCont = {
        name: cont,
        show: false,
        substances: sub,
      };
      ss.push(newCont);
    });
    setSoilDown(ss);
  }, [soil]);

  useEffect(() => {
    let ss = [];
    groundwaterContaminants.map((cont) => {
      let sub = _(groundwater)
        .values()
        .orderBy('name', 'asc')
        .filter((item) => {
          if (SessionUtils.getUserData().role === 'admin') {
            return (
              item.group.toLowerCase() == cont.toLowerCase() &&
              item.status === 'active'
            );
          } else {
            return (
              item.group.toLowerCase() == cont.toLowerCase() &&
              item.status === 'active' &&
              (item.userId === SessionUtils.getUserData().id ||
                item.userId === 1)
            );
          }
        })
        .value();
      const newCont = {
        name: cont,
        show: false,
        substances: sub,
      };
      ss.push(newCont);
    });
    setGroundwaterDown(ss);
  }, [groundwater]);

  useEffect(() => {
    if (vapourSoil) {
      const newCont = {
        name: 'Soil',
        show: false,
        substances: _(vapourSoil)
          .values()
          .orderBy('name', 'asc')
          .filter((item) => {
            if (SessionUtils.getUserData().role === 'admin') {
              return item.status === 'active';
            } else {
              return (
                item.status === 'active' &&
                (item.userId === SessionUtils.getUserData().id ||
                  item.userId === 1)
              );
            }
          })
          .value(),
      };
      const elementsIndex = vapourDown.findIndex(
        (element) => element.name == 'Soil'
      );
      if (elementsIndex != -1) {
        let newArray = [...vapourDown];
        newArray[elementsIndex] = newCont;
        setVapourDown(newArray);
      } else {
        setVapourDown((oldArray) => [...oldArray, newCont]);
      }
    }
  }, [vapourSoil]);

  useEffect(() => {
    if (vapourGroundwater) {
      const newCont = {
        name: 'Groundwater',
        show: false,
        substances: _(vapourGroundwater)
          .values()
          .orderBy('name', 'asc')
          .filter((item) => {
            if (SessionUtils.getUserData().role === 'admin') {
              return item.status === 'active';
            } else {
              return (
                item.status === 'active' &&
                (item.userId === SessionUtils.getUserData().id ||
                  item.userId === 1)
              );
            }
          })
          .value(),
      };
      const elementsIndex = vapourDown.findIndex(
        (element) => element.name == 'Groundwater'
      );
      if (elementsIndex != -1) {
        let newArray = [...vapourDown];
        newArray[elementsIndex] = newCont;
        setVapourDown(newArray);
      } else {
        setVapourDown((oldArray) => [...oldArray, newCont]);
      }
    }
  }, [vapourGroundwater]);

  useEffect(() => {
    if (vapourSoilVapour) {
      const newCont = {
        name: 'Soil Vapour',
        show: false,
        substances: _(vapourSoilVapour)
          .values()
          .orderBy('name', 'asc')
          .filter((item) => {
            if (SessionUtils.getUserData().role === 'admin') {
              return item.status === 'active';
            } else {
              return (
                item.status === 'active' &&
                (item.userId === SessionUtils.getUserData().id ||
                  item.userId === 1)
              );
            }
          })
          .value(),
      };
      const elementsIndex = vapourDown.findIndex(
        (element) => element.name == 'Soil Vapour'
      );
      if (elementsIndex != -1) {
        let newArray = [...vapourDown];
        newArray[elementsIndex] = newCont;
        setVapourDown(newArray);
      } else {
        setVapourDown((oldArray) => [...oldArray, newCont]);
      }
    }
  }, [vapourSoilVapour]);

  useEffect(() => {
    if (soilDown.every((item) => item.show && item.show === true)) {
      setSoilExpandBtn(false);
    } else {
      setSoilExpandBtn(true);
    }
  }, [soilDown]);

  useEffect(() => {
    if (groundwaterDown.every((item) => item.show && item.show === true)) {
      setGroundwaterExpandBtn(false);
    } else {
      setGroundwaterExpandBtn(true);
    }
  }, [groundwaterDown]);

  useEffect(() => {
    if (vapourDown.every((item) => item.show && item.show === true)) {
      setVapourExpandBtn(false);
    } else {
      setVapourExpandBtn(true);
    }
  }, [vapourDown]);

  const clearAll = () => {
    setFilterDisabled((oldObj) => {
      return {
        ...oldObj,
        search: false,
        soil: false,
        groundwater: false,
        vapour: false,
      };
    });
    setSearchValue('');

    setSoilContValue('');
    setSoilSubValue('');

    setGroundwaterContValue('');
    setGroundwaterSubValue('');

    setVapourContValue('');
    setVapourSubValue('');

    setSoilSubError(false);
    setGroundwaterSubError(false);
    setVapourSubError(false);
  };

  const searchInputHandler = (value) => {
    setFilterDisabled((oldObj) => {
      if (value == '') {
        return {
          ...oldObj,
          search: false,
          soil: false,
          groundwater: false,
          vapour: false,
        };
      }
      return {
        ...oldObj,
        soil: true,
        groundwater: true,
        vapour: true,
      };
    });
    setSearchValue(value);
  };

  const filterSelectHandler = (
    contValue,
    setContValue,
    nameDisabled,
    setSubValue,
    drilldown,
    setSubList
  ) => {
    // set contaminant value
    setContValue(contValue);

    // set disabled
    if (contValue === '') {
      setSoilSubError(false);
      setGroundwaterSubError(false);
      setVapourSubError(false);
      setFilterDisabled((oldObj) => {
        return {
          ...oldObj,
          search: false,
          soil: false,
          groundwater: false,
          vapour: false,
        };
      });
    } else {
      setFilterDisabled((oldObj) => {
        return {
          ...oldObj,
          search: true,
          soil: nameDisabled != 'soil',
          groundwater: nameDisabled != 'groundwater',
          vapour: nameDisabled != 'vapour',
        };
      });
    }

    // reset the sub value
    setSubValue('');

    // set the sub option list
    const s = drilldown
      .filter((item) => item.name.toLowerCase() == contValue.toLowerCase())
      .map(({ substances }) => {
        return substances.map((sub) => {
          return {
            value: sub.name,
            label: sub.name,
          };
        });
      });
    setSubList(s.flat());
  };

  const filterSubmit = (e) => {
    e.preventDefault();
    if (searchValue.trim() !== '') {
      history.push(
        `/infocare/search?keyword=${encodeURIComponent(searchValue.trim())}`
      );
    }

    if (soilContValue !== '') {
      if (soilSubValue !== '') {
        history.push(
          `/infocare/search?keyword=${encodeURIComponent(soilSubValue)}`
        );
      } else {
        setSoilSubError(true);
        setGroundwaterSubError(false);
        setVapourSubError(false);
      }
    }

    if (groundwaterContValue !== '') {
      if (groundwaterSubValue !== '') {
        history.push(
          `/infocare/search?keyword=${encodeURIComponent(groundwaterSubValue)}`
        );
      } else {
        setSoilSubError(false);
        setGroundwaterSubError(true);
        setVapourSubError(false);
      }
    }

    if (vapourContValue !== '') {
      if (vapourSubValue !== '') {
        history.push(
          `/infocare/search?keyword=${encodeURIComponent(vapourSubValue)}`
        );
      } else {
        setSoilSubError(false);
        setGroundwaterSubError(false);
        setVapourSubError(true);
      }
    }
  };

  const openGroup = (drilldown, name, setDrilldown) => {
    const elementsIndex = drilldown.findIndex(
      (element) => element.name == name
    );
    let newArray = [...drilldown];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      show: !newArray[elementsIndex].show,
    };
    setDrilldown(newArray);
  };

  const expandAll = (drillDown, setDrilldown) => {
    setDrilldown(
      drillDown.map((item) => {
        return {
          ...item,
          show: true,
        };
      })
    );
  };

  const collapseAll = (drillDown, setDrilldown) => {
    setDrilldown(
      drillDown.map((item) => {
        return {
          ...item,
          show: false,
        };
      })
    );
  };

  return (
    <div>
      <PageTitle
        title="infoCARE"
        description="Search and view contaminant database."
        className="pe-7s-info"
      />
      <div className="flex flex-col px-6 pt-2 pb-6 space-y-8">
        {/* Search Bar */}
        <form
          className="flex flex-col justify-center w-full bg-white border border-gray-300 shadow-lg rounded-lg pt-8 pb-8 px-8"
          onSubmit={filterSubmit}
        >
          <div className="flex justify-end mb-2 font-bold">
            <span className="cursor-pointer" onClick={clearAll}>
              Clear all
            </span>
          </div>

          {(soilSubError || groundwaterSubError || vapourSubError) && (
            <div className="flex mb-1">
              <p className="font-semibold text-red-600">
                * Please choose the{' '}
                {soilSubError
                  ? 'soil substance'
                  : groundwaterSubError
                  ? 'groundwater substance'
                  : vapourSubError && 'volatile organic compound'}{' '}
                from the list
              </p>
            </div>
          )}

          <label className="relative text-gray-400 focus-within:text-gray-600 block mb-2">
            <img
              className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-3"
              src="/assets/icons/Search-Icon-Inactive.svg"
              alt="search"
            />
            <input
              type="text"
              name="email"
              name="info_search"
              placeholder="Type of contaminants"
              className="appearance-none border border-gray-300 rounded-md w-full pl-12 pr-3 py-2 text-sm focus:border focus:ring-2 focus:ring-blue-200 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200"
              value={searchValue}
              onChange={(e) => searchInputHandler(e.target.value)}
              disabled={filterDisabled.search}
            />
          </label>

          <div className="flex flex-col lg:flex-row">
            <div className="flex flex-col lg:flex-row lg:space-x-2 lg:w-11/12 ">
              <div className="flex flex-col mb-2 space-y-2 lg:mb-0 lg:w-2/6">
                {/* Soil Filter */}
                <Select
                  fullWidth
                  placeholder="Choose Soil Contaminants"
                  data={soilContaminants.map((item) => {
                    return {
                      value: item,
                      label: item,
                    };
                  })}
                  onChange={(e) =>
                    filterSelectHandler(
                      e.target.value,
                      setSoilContValue,
                      'soil',
                      setSoilSubValue,
                      soilDown,
                      setSoilSubList
                    )
                  }
                  value={soilContValue}
                  disabled={filterDisabled.soil}
                  disablePlaceholder={false}
                />
                <Select
                  fullWidth
                  placeholder="Choose Soil Substance"
                  data={soilSubList}
                  onChange={(e) => {
                    if (e.target.value !== '') {
                      setSoilSubError(false);
                    }
                    setSoilSubValue(e.target.value);
                  }}
                  value={soilSubValue}
                  disabled={filterDisabled.soil}
                  disablePlaceholder={false}
                  customClass={classNames({
                    'ring-2 ring-red-400': soilSubError,
                  })}
                />
              </div>
              {/* Groundwater Filter */}
              <div className="flex flex-col mb-2 space-y-2 lg:mb-0 lg:w-2/6">
                <Select
                  fullWidth
                  placeholder="Choose Groundwater Contaminants"
                  data={groundwaterContaminants.map((item) => {
                    return {
                      value: item,
                      label: item,
                    };
                  })}
                  onChange={(e) =>
                    filterSelectHandler(
                      e.target.value,
                      setGroundwaterContValue,
                      'groundwater',
                      setGroundwaterSubValue,
                      groundwaterDown,
                      setGroundwaterSubList
                    )
                  }
                  value={groundwaterContValue}
                  disabled={filterDisabled.groundwater}
                  disablePlaceholder={false}
                />
                <Select
                  fullWidth
                  placeholder="Choose Groundwater Substance"
                  data={groundwaterSubList}
                  onChange={(e) => {
                    if (e.target.value !== '') {
                      setGroundwaterSubError(false);
                    }
                    setGroundwaterSubValue(e.target.value);
                  }}
                  value={groundwaterSubValue}
                  disabled={filterDisabled.groundwater}
                  disablePlaceholder={false}
                  customClass={classNames({
                    'ring-2 ring-red-400': groundwaterSubError,
                  })}
                />
              </div>
              {/* Vapour Filter */}
              <div className="flex flex-col mb-2 space-y-2 lg:mb-0 lg:w-2/6">
                <Select
                  fullWidth
                  placeholder="Choose Vapour Intrusion"
                  data={vapourIntrusionContaminants.map((item) => {
                    return {
                      value: item,
                      label: item,
                    };
                  })}
                  onChange={(e) =>
                    filterSelectHandler(
                      e.target.value,
                      setVapourContValue,
                      'vapour',
                      setVapourSubValue,
                      vapourDown,
                      setVapourSubList
                    )
                  }
                  value={vapourContValue}
                  disabled={filterDisabled.vapour}
                  disablePlaceholder={false}
                />
                <Select
                  fullWidth
                  placeholder="Choose Volatile Organic Compound"
                  data={vapourSubList}
                  onChange={(e) => {
                    if (e.target.value !== '') {
                      setVapourSubError(false);
                    }
                    setVapourSubValue(e.target.value);
                  }}
                  value={vapourSubValue}
                  disabled={filterDisabled.vapour}
                  disablePlaceholder={false}
                  customClass={classNames({
                    'ring-2 ring-red-400': vapourSubError,
                  })}
                />
              </div>
            </div>
            <div className="lg:relative lg:w-1/12">
              <Button
                theme="success"
                text="Search"
                lowercase
                className="lg:absolute lg:bottom-0 lg:right-0"
              />
            </div>
          </div>
        </form>

        {/* Soil */}
        <div className="space-y-8">
          {/*Soil Contaminats drill-down*/}
          <Card title={'Soil Contaminants'}>
            <div className="divide-y">
              <div>
                {soilDown.map(({ name, show }, index) => (
                  <div
                    key={index}
                    className="cursor-pointer inline-flex align-center justify-center mr-4 mb-4 px-2 py-1 border rounded-md border-gray-300 focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg"
                    onClick={() => openGroup(soilDown, name, setSoilDown)}
                  >
                    <span
                      className={classNames({
                        'mr-10': !show,
                        'mr-6': show,
                      })}
                    >
                      {name}
                    </span>
                    <img
                      className={classNames({
                        hidden: !show,
                      })}
                      src="/assets/icons/Dropdown-Icon-Bar.svg"
                      alt="dropdown"
                    />
                  </div>
                ))}
              </div>
              <div className="flex flex-row-reverse pt-2">
                <span
                  className="cursor-pointer underline"
                  onClick={() =>
                    soilExpandBtn
                      ? expandAll(soilDown, setSoilDown)
                      : collapseAll(soilDown, setSoilDown)
                  }
                >
                  {soilExpandBtn ? 'Expand All' : 'Collapse All'}
                </span>
              </div>
            </div>
          </Card>

          {/*Soil Substance*/}
          {soilDown.some((item) => item.show === true) && (
            <div
              className="flex flex-col justify-center w-full bg-white border border-gray-300 shadow-lg rounded-lg pt-8 pb-8 px-8 z-40 border-t-8"
              style={{ borderTopColor: '#20AA4F' }}
            >
              {soilDown
                .filter((item) => item.show === true)
                .map((item, index) => (
                  <div className="divide-y mt-4" key={index}>
                    <div>
                      <h2 className="text-lg font-bold">{item.name}</h2>
                    </div>
                    <div className="pt-4">
                      {item.substances.map((sub, index) => (
                        <Link
                          key={index}
                          to={`/infocare/search?keyword=${encodeURIComponent(
                            sub.name
                          )}`}
                          className="cursor-pointer inline-flex align-center justify-center mr-4 mb-4 px-2 py-1 border rounded-md border-gray-300 focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg"
                        >
                          {sub.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>

        {/* Groundwater */}
        <div className="space-y-8">
          {/*Groundwater Contaminats drill-down*/}
          <Card title={'Groundwater Contaminants'}>
            <div className="divide-y">
              <div>
                {groundwaterDown.map(({ name, show }, index) => (
                  <div
                    key={index}
                    className="cursor-pointer inline-flex align-center justify-center mr-4 mb-4 px-2 py-1 border rounded-md border-gray-300 focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg"
                    onClick={() =>
                      openGroup(groundwaterDown, name, setGroundwaterDown)
                    }
                  >
                    <span
                      className={classNames({
                        'mr-10': !show,
                        'mr-6': show,
                      })}
                    >
                      {name}
                    </span>
                    <img
                      className={classNames({
                        hidden: !show,
                      })}
                      src="/assets/icons/Dropdown-Icon-Bar.svg"
                      alt="dropdown"
                    />
                  </div>
                ))}
              </div>
              <div className="flex flex-row-reverse pt-2">
                <span
                  className="cursor-pointer underline"
                  onClick={() =>
                    groundwaterExpandBtn
                      ? expandAll(groundwaterDown, setGroundwaterDown)
                      : collapseAll(groundwaterDown, setGroundwaterDown)
                  }
                >
                  {groundwaterExpandBtn ? 'Expand All' : 'Collapse All'}
                </span>
              </div>
            </div>
          </Card>

          {/*Groundwater Substance*/}
          {groundwaterDown.some((item) => item.show === true) && (
            <div
              className="flex flex-col justify-center w-full bg-white border border-gray-300 shadow-lg rounded-lg pt-8 pb-8 px-8 z-40 border-t-8"
              style={{ borderTopColor: '#20AA4F' }}
            >
              {groundwaterDown
                .filter((item) => item.show === true)
                .map((item, index) => (
                  <div className="divide-y mt-4" key={index}>
                    <div>
                      <h2 className="text-lg font-bold">{item.name}</h2>
                    </div>
                    <div className="pt-4">
                      {item.substances.map((sub, index) => (
                        <Link
                          key={index}
                          to={`/infocare/search?keyword=${encodeURIComponent(
                            sub.name
                          )}`}
                          className="cursor-pointer inline-flex align-center justify-center mr-4 mb-4 px-2 py-1 border rounded-md border-gray-300 focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg"
                        >
                          {sub.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>

        {/* Vapour */}
        <div className="space-y-8">
          {/*Vapour Contaminats drill-down*/}
          <Card title={'Vapour Contaminants'}>
            <div className="divide-y">
              <div>
                {vapourDown.map(({ name, show }, index) => (
                  <div
                    key={index}
                    className="cursor-pointer inline-flex align-center justify-center mr-4 mb-4 px-2 py-1 border rounded-md border-gray-300 focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg"
                    onClick={() => openGroup(vapourDown, name, setVapourDown)}
                  >
                    <span
                      className={classNames({
                        'mr-10': !show,
                        'mr-6': show,
                      })}
                    >
                      {name}
                    </span>
                    <img
                      className={classNames({
                        hidden: !show,
                      })}
                      src="/assets/icons/Dropdown-Icon-Bar.svg"
                      alt="dropdown"
                    />
                  </div>
                ))}
              </div>
              <div className="flex flex-row-reverse pt-2">
                <span
                  className="cursor-pointer underline"
                  onClick={() =>
                    vapourExpandBtn
                      ? expandAll(vapourDown, setVapourDown)
                      : collapseAll(vapourDown, setVapourDown)
                  }
                >
                  {vapourExpandBtn ? 'Expand All' : 'Collapse All'}
                </span>
              </div>
            </div>
          </Card>

          {/*Vapour Substance*/}
          {vapourDown.some((item) => item.show === true) && (
            <div
              className="flex flex-col justify-center w-full bg-white border border-gray-300 shadow-lg rounded-lg pt-8 pb-8 px-8 z-40 border-t-8"
              style={{ borderTopColor: '#20AA4F' }}
            >
              {vapourDown
                .filter((item) => item.show === true)
                .map((item, index) => (
                  <div className="divide-y mt-4" key={index}>
                    <div>
                      <h2 className="text-lg font-bold">{item.name}</h2>
                    </div>
                    <div className="pt-4">
                      {item.substances.map((sub, index) => (
                        <Link
                          key={index}
                          to={`/infocare/search?keyword=${encodeURIComponent(
                            sub.name
                          )}`}
                          className="cursor-pointer inline-flex align-center justify-center mr-4 mb-4 px-2 py-1 border rounded-md border-gray-300 focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg"
                        >
                          {sub.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  soil: getResource(RESOURCE_NAMES['SOIL-HSLS'])(state),
  groundwater: getResource(RESOURCE_NAMES['GROUNDWATER-HSLS'])(state),
  vapourSoil: getResource(RESOURCE_NAMES['VAPOUR-INTRUSION-SOILS'])(state),
  vapourGroundwater: getResource(
    RESOURCE_NAMES['VAPOUR-INTRUSION-GROUNDWATERS']
  )(state),
  vapourSoilVapour: getResource(
    RESOURCE_NAMES['VAPOUR-INTRUSION-SOIL-VAPOURS']
  )(state),
});

export default connect(mapStateToProps, {
  getAllData: _getAlldata,
})(Infocare);
