import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { HashLink } from 'react-router-hash-link';
import classNames from 'classnames';

/**
 * Component for Stepper/Progress Indicator UI.
 *
 * @component
 */
export const Stepper = ({
  steps,
  ids,
  disabled = false,
  errorIds = [],
  completedIds = [],
}) => {
  const location = useLocation();

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -200;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  // Scrolling detector
  const observerOptions = { root: null, threshold: [0.2], rootMargin: '0px' };
  const observeScroll = (...ids) => {
    for (const id of ids) {
      let callback = (entries) => {
        entries.forEach((change) => {
          if (change.intersectionRatio > 0.2) {
            for (const element of document.getElementsByClassName(id)) {
              element.classList.add('active');
            }
          } else {
            for (const element of document.getElementsByClassName(id)) {
              element.classList.remove('active');
            }
          }
        });
      };
      try {
        new IntersectionObserver(callback, observerOptions).observe(
          document.getElementById(id)
        );
      } catch (e) {}
    }
  };

  // Monitor scroll
  useEffect(() => {
    if (!disabled) observeScroll(...ids);
  }, []);

  return (
    <div className="stepper hidden lg:flex sticky top-16 stepper flex flex-col justify-center w-full bg-white border border-gray-300 shadow-lg rounded-lg pt-10 pb-14 px-28 z-40">
      <div
        className={classNames(
          {
            'opacity-50': disabled,
          },
          'flex items-center'
        )}
      >
        {steps.map((item, idx) => (
          <HashLink
            smooth
            to={`${location.pathname}#${ids[idx]}`}
            scroll={(el) => scrollWithOffset(el)}
            className={`stepGroup transition duration-500 ease-in-out flex flex-grow items-center`}
            key={idx}
          >
            {idx !== 0 && (
              <div
                className={classNames(
                  {
                    error: errorIds.indexOf(ids[idx]) > -1,
                    completed: completedIds.indexOf(ids[idx]) > -1,
                  },
                  'line flex-auto border-t-4',
                  ids[idx]
                )}
              />
            )}
            <div className="flex items-center relative">
              <div
                className={classNames(
                  {
                    error: errorIds.indexOf(ids[idx]) > -1,
                    completed: completedIds.indexOf(ids[idx]) > -1,
                  },
                  'dot rounded-full h-5 w-5',
                  ids[idx]
                )}
              />
              <div
                className={classNames(
                  {
                    error: errorIds.indexOf(ids[idx]) > -1,
                    completed: completedIds.indexOf(ids[idx]) > -1,
                  },
                  'text absolute text-center w-24 2xl:w-max bg-opacity-0 top-0 left-1/2 m-auto mt-8 uppercase whitespace-normal 2xl:whitespace-nowrap text-xs transform -translate-x-1/2',
                  ids[idx]
                )}
              >
                {item}
              </div>
            </div>
            {idx !== steps.length - 1 && (
              <div
                className={classNames(
                  {
                    error: errorIds.indexOf(ids[idx]) > -1,
                    completed: completedIds.indexOf(ids[idx]) > -1,
                  },
                  'line flex-auto border-t-4',
                  ids[idx]
                )}
              />
            )}
          </HashLink>
        ))}
      </div>
    </div>
  );
};
