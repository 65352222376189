import axios from '../axios';

export const setResource = (resourceName, payload) => ({
  type: `resources.${resourceName}.set`,
  payload, // data
});

export const updateResource = (resourceName, payload) => ({
  type: `resources.${resourceName}.update`,
  payload, // { id, data }
});

export const deleteResource = (resourceName, payload) => ({
  type: `resources.${resourceName}.delete`,
  payload, // id
});

// since setResource(resourceName, {}) will always update the state (not overwrite)
export const overwriteResource = (resourceName, payload) => ({
  type: `resources.${resourceName}.overwrite`,
  payload,
});

export const setStatistics = (resourceName, payload) => ({
  type: `resources.${resourceName}.statistics`,
  payload,
});

// overwrite state by default
export const getAllData =
  (resourceName, query = '', overwrite = true) =>
  async () => {
    await axios.get(`/${resourceName}?${query}`, {
      headers: {
        resourceName,
        overwrite,
      },
    });
  };

// update state by default
export const getDataById =
  (resourceName, id, query = '', overwrite = false) =>
  async () => {
    const { data } = await axios.get(`/${resourceName}/${id}?${query}`, {
      headers: {
        resourceName,
        overwrite,
      },
    });
    return data;
  };

export const updateData =
  (resourceName) =>
  (id, update, query = '') =>
  async () => {
    return await axios.patch(`/${resourceName}/${id}?${query}`, update, {
      headers: {
        resourceName,
      },
    });
  };

export const addData = (resourceName) => (payload) => async (dispatch) => {
  const { data } = await axios.post(`/${resourceName}`, payload, {
    headers: {
      resourceName,
    },
  });
  return dispatch(updateResource(resourceName, { id: data.id, data }));
};

export const deleteData = (resourceName, id) => async (dispatch) => {
  await axios.delete(`/${resourceName}/${id}`);
  return dispatch(deleteResource(resourceName, id));
};

export const getStatisticsData =
  (resourceName = 'statistics') =>
  async () => {
    await axios.get(`/${resourceName}/files`, {
      headers: {
        resourceName,
      },
    });
  };
