import React, { useEffect, useState } from 'react';
import { Card, PageTitle, Select } from '../../components';
import _ from 'lodash';
import { Radar } from 'react-chartjs-2';
import { APIService, SessionUtils } from '../../utils';

import { connect } from 'react-redux';
import { RESOURCE_NAMES } from '../../store/reducers/resources';
import { getAllData as _getAlldata } from '../../store/actions/resources';
import { getResource } from '../../store/selectors/resources';

const RadarChart = ({ getAllData, files }) => {
  const [allFiles, setAllFiles] = useState([]);
  const [radarData, setRadarData] = useState({
    labels: ['Soil HQ', 'Groundwater HQ', 'Vapour Intrusion HQ', 'Total HQ'],
  });
  const [radarOptions, setRadarOptions] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllData(RESOURCE_NAMES['FILES']).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!loading && files) {
      let data = _.orderBy(_.values(files), ['created_at'], ['desc']).filter(
        (item) => {
          if (SessionUtils.getUserData().role === 'admin') {
            return item.status === 'active';
          } else {
            return (
              item.status === 'active' &&
              item.userId == SessionUtils.getUserData().id
            );
          }
        }
      );
      setAllFiles(data);
    }
  }, [loading, files]);

  const handleSelectCase = (dataId) => {
    // Reset the data
    setRadarData((oldArr) => {
      return {
        ...oldArr,
        datasets: [],
      };
    });

    // fetch single data from api
    APIService.get.files.view(dataId).then((reply) => {
      // set the radar data
      const rd = [
        {
          label: reply.data.name,
          data: [
            transformZeroValue(reply.data.soil.totalHQ),
            transformZeroValue(reply.data.groundWater.totalHQ),
            transformZeroValue(reply.data.vapour.totalHQ),
            transformZeroValue(reply.data.total.totalHQ),
          ],
          backgroundColor: 'rgba(229, 228, 229, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
          originalData: [
            reply.data.soil.totalHQ,
            reply.data.groundWater.totalHQ,
            reply.data.vapour.totalHQ,
            reply.data.total.totalHQ,
          ],
        },
        {
          label: 'Max',
          data: [
            transformZeroValue(reply.data.soil.maxHQ),
            transformZeroValue(reply.data.groundWater.maxHQ),
            transformZeroValue(reply.data.vapour.maxHQ),
            transformZeroValue(reply.data.total.maxHQ),
          ],
          backgroundColor: 'rgba(229, 228, 229, 0.2)',
          borderColor: 'rgba(37, 99, 235, 1)',
          borderWidth: 1,
          originalData: [
            reply.data.soil.maxHQ,
            reply.data.groundWater.maxHQ,
            reply.data.vapour.maxHQ,
            reply.data.total.maxHQ,
          ],
        },
        {
          label: 'Average',
          data: [
            transformZeroValue(reply.data.soil.avgHQ),
            transformZeroValue(reply.data.groundWater.avgHQ),
            transformZeroValue(reply.data.vapour.avgHQ),
            transformZeroValue(reply.data.total.avgHQ),
          ],
          backgroundColor: 'rgba(229, 228, 229, 0.2)',
          borderColor: 'rgba(110, 231, 183, 1)',
          borderWidth: 1,
          originalData: [
            reply.data.soil.avgHQ,
            reply.data.groundWater.avgHQ,
            reply.data.vapour.avgHQ,
            reply.data.total.avgHQ,
          ],
        },
      ];

      setRadarData((oldArr) => {
        return {
          ...oldArr,
          datasets: rd,
        };
      });

      let maxVal = _.round(reply.data.total.maxHQ, 2);
      let maxR = 1;
      while (maxR <= maxVal * 10) {
        if (maxR % maxVal === maxR) {
          maxR = maxR * 10;
        } else {
          break;
        }
      }

      const newOptions = {
        scales: {
          r: {
            min: 0,
            ticks: {
              callback: function (value, index, values) {
                if (Math.round(Math.pow(10, value)) === 0) {
                  return 0;
                }
                let i = 1;
                for (i; i <= maxR; i = i * 10) {
                  if (Math.round(Math.pow(10, value)) === i) {
                    return Math.round(Math.pow(10, value));
                  }
                }
                return null;
              },
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => {
                const label = context.dataset.label || '';

                const data = context.dataset.originalData[context.dataIndex];
                return `${label} : ${_.round(data, 2)}`;
              },
            },
          },
        },
      };
      setRadarOptions(newOptions);
    });
  };

  const transformZeroValue = (val) => {
    if (val <= 1) {
      return Math.log(1) / Math.log(10);
    } else {
      return Math.log(val) / Math.log(10);
    }
  };

  return (
    <div>
      <PageTitle
        title="Radar chart"
        description="Compare site HQ with all HQ data."
        className="pe-7s-graph3"
      />
      <div className="flex flex-col px-6 pt-2 pb-6 space-y-8">
        {/* RADAR CHART */}
        <Card title={'Radar Chart'}>
          <div className="flex flex-col items-center justify-center space-y-4">
            <Select
              fullWidth
              customClass={'text-sm px-3 py-2'}
              placeholder="Select data case :"
              data={allFiles.map((item) => {
                return {
                  value: item.id,
                  label: item.name,
                };
              })}
              onChange={(e) => handleSelectCase(e.target.value)}
            />
            <div className="w-full lg:w-2/4">
              <Radar data={radarData} options={radarOptions} />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  files: getResource(RESOURCE_NAMES['FILES'])(state),
});

export default connect(mapStateToProps, {
  getAllData: _getAlldata,
})(RadarChart);
