import React, { useEffect, useState } from 'react';
import { Card, PageTitle, Select, Table } from '../../components';
import _ from 'lodash';
import { Scatter } from 'react-chartjs-2';

import { connect } from 'react-redux';
import { RESOURCE_NAMES } from '../../store/reducers/resources';
import { getAllData as _getAlldata } from '../../store/actions/resources';
import { getResource } from '../../store/selectors/resources';
import Swal from 'sweetalert2';
import { SessionUtils } from '../../utils';

const RankingChart = ({ getAllData, files }) => {
  const [allFiles, setAllFiles] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [radarData, setRadarData] = useState({});
  const [radarOptions, setRadarOptions] = useState({});
  const [loading, setLoading] = useState(true);

  const [selectedCase, setSelectedCase] = useState('');

  const [colSort, setColSort] = useState(null);
  const [sortOrder, setSortOrder] = useState(true);

  const dataCase = [
    {
      id: 'soilData',
      label: 'Soil Hazard Quotient',
    },
    {
      id: 'groundWaterData',
      label: 'Groundwater Hazard Quotient',
    },
    {
      id: 'vapourData',
      label: 'Vapour Intrusion Hazard Quotient',
    },
    {
      id: 'total',
      label: 'Total Hazard Quotient',
    },
  ];

  useEffect(() => {
    getAllData(RESOURCE_NAMES['FILES']).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!loading && files) {
      let data = _.orderBy(_.values(files), ['created_at'], ['desc']).filter(
        (item) => {
          if (SessionUtils.getUserData().role === 'admin') {
            return item.status === 'active';
          } else {
            return (
              item.status === 'active' &&
              item.userId == SessionUtils.getUserData().id
            );
          }
        }
      );
      setAllFiles(
        data.map((item) => {
          return {
            ...item,
            total: {
              totalHQ:
                item.soilData.totalHQ +
                item.groundWaterData.totalHQ +
                item.vapourData.totalHQ,
            },
          };
        })
      );
    }
  }, [loading, files]);

  const handleSelectCase = (caseId) => {
    setColSort(null);
    setSortOrder(true);
    const ordered = allFiles;

    const dt = [];
    ordered.map((item, index) => {
      dt.push({
        x: index + 1,
        y: _.round(item[caseId].totalHQ, 2),
      });
    });
    const newRadarData = {
      datasets: [
        {
          label: 'Ranking scatter',
          data: dt,
          backgroundColor: '#20aa4f',
          showLine: false,
        },
      ],
    };

    setTableData(
      ordered.map((item) => {
        return {
          'Site Name': item.name,
          'Soil Hazard Quotient': _.round(item.soilData.totalHQ, 2),
          'Groundwater Hazard Quotient': _.round(
            item.groundWaterData.totalHQ,
            2
          ),
          'Vapour Intrusion Hazard Quotient': _.round(
            item.vapourData.totalHQ,
            2
          ),
          'Total Hazard Quotient': _.round(
            item.soilData.totalHQ +
              item.groundWaterData.totalHQ +
              item.vapourData.totalHQ,
            2
          ),
        };
      })
    );
    setRadarData(newRadarData);

    setSelectedCase(caseId);

    const opt = {
      onClick: (e, element) => {
        if (element.length > 0) {
          let index = element[0].index;
          Swal.fire({
            title: 'Are you sure you wanna see the detailed data?',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
          }).then((result) => {
            if (result.value) {
              window.location.href = `/result/${ordered[index].id}`;
            }
          });
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            title: (context) => {
              return `${ordered[context[0].dataIndex].name}`;
            },
            label: (context) => {
              return `${_.round(
                ordered[context.dataIndex][caseId].totalHQ,
                2
              )}`;
            },
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Ranking',
            font: {
              size: 16,
            },
            color: '#20aa4f',
          },
          ticks: {
            stepSize: 1,
          },
        },
        y: {
          title: {
            display: true,
            text: `${dataCase.find((item) => item.id == caseId).label}`,
            font: {
              size: 16,
            },
            color: '#20aa4f',
          },
        },
      },
    };
    setRadarOptions(opt);
  };

  const chartFilter = (item, sort) => {
    let caseId = '';
    if (item == 'Soil Hazard Quotient') {
      caseId = 'soilData';
    } else if (item == 'Groundwater Hazard Quotient') {
      caseId = 'groundWaterData';
    } else if (item == 'Vapour Intrusion Hazard Quotient') {
      caseId = 'vapourData';
    } else if (item == 'Total Hazard Quotient') {
      caseId = 'total';
    } else {
      return;
    }
    setColSort(item);
    setSortOrder(sort);

    const ordered = _.orderBy(
      allFiles,
      [`${caseId}.totalHQ`],
      [sort ? 'asc' : 'desc']
    );

    const dt = [];
    ordered.map((item, index) => {
      dt.push({
        x: index + 1,
        y: _.round(item[caseId].totalHQ, 2),
      });
    });
    const newRadarData = {
      datasets: [
        {
          label: 'Ranking scatter',
          data: dt,
          backgroundColor: '#20aa4f',
          showLine: false,
        },
      ],
    };

    setTableData(
      ordered.map((item) => {
        return {
          'Site Name': item.name,
          'Soil Hazard Quotient': _.round(item.soilData.totalHQ, 2),
          'Groundwater Hazard Quotient': _.round(
            item.groundWaterData.totalHQ,
            2
          ),
          'Vapour Intrusion Hazard Quotient': _.round(
            item.vapourData.totalHQ,
            2
          ),
          'Total Hazard Quotient': _.round(
            item.soilData.totalHQ +
              item.groundWaterData.totalHQ +
              item.vapourData.totalHQ,
            2
          ),
        };
      })
    );
    setRadarData(newRadarData);
    setSelectedCase(caseId);

    const opt = {
      onClick: (e, element) => {
        if (element.length > 0) {
          let index = element[0].index;
          Swal.fire({
            title: 'Are you sure you wanna see the detailed data?',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
          }).then((result) => {
            if (result.value) {
              window.location.href = `/result/${ordered[index].id}`;
            }
          });
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            title: (context) => {
              let index = context[0].dataIndex;
              return `${ordered[index].name}`;
            },
            label: (context) => {
              let index = context.dataIndex;
              return `${_.round(ordered[index][caseId].totalHQ, 2)}`;
            },
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Ranking',
            font: {
              size: 16,
            },
            color: '#20aa4f',
          },
          ticks: {
            stepSize: 1,
          },
        },
        y: {
          title: {
            display: true,
            text: `${dataCase.find((item) => item.id == caseId).label}`,
            font: {
              size: 16,
            },
            color: '#20aa4f',
          },
        },
      },
    };
    setRadarOptions(opt);
  };

  return (
    <div>
      <PageTitle
        title="Ranking scatter plot"
        description="Ranking of each measurement. Choose the contaminant to view data."
        className="pe-7s-graph1"
      />
      <div className="flex flex-col px-6 pt-2 pb-6 space-y-8">
        {/* RANKING CHART */}
        <Card title={'Ranking Scatter Plot'}>
          <div className="flex flex-col space-y-4">
            <Select
              fullWidth
              customClass={'text-sm px-3 py-2'}
              placeholder="Select data case :"
              data={
                !loading
                  ? dataCase.map((item) => {
                      return {
                        value: item.id,
                        label: item.label,
                      };
                    })
                  : []
              }
              onChange={(e) => handleSelectCase(e.target.value)}
              value={selectedCase}
            />

            <Scatter data={radarData} options={radarOptions} />

            <Table
              data={tableData}
              colSort={colSort}
              sort={sortOrder}
              chartFilter={chartFilter}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  files: getResource(RESOURCE_NAMES['FILES'])(state),
});

export default connect(mapStateToProps, {
  getAllData: _getAlldata,
})(RankingChart);
