import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { APIService, SessionUtils } from '../../utils';
import { Button, Card, PageTitle, Select } from '../../components';
import { groundwaterContaminants } from '../Infocare/constants';

import { connect } from 'react-redux';
import { RESOURCE_NAMES } from '../../store/reducers/resources';
import {
  getDataById as _getDataById,
  addData as _addData,
  updateData as _updateData,
  deleteData as _deleteData,
} from '../../store/actions/resources';
import { getResource } from '../../store/selectors/resources';

const Groundwater = ({
  getDataById,
  addData,
  updateData,
  deleteData,
  groundwater,
}) => {
  let { dataId } = useParams();
  const history = useHistory();

  const [name, setName] = useState('');
  const [group, setGroup] = useState('');
  const [freshWater, setFreshWater] = useState('');
  const [marineWater, setMarineWater] = useState('');
  const [drinkingWater, setDrinkingWater] = useState('');
  const [disabled, setDisabled] = useState(true);

  const [error, setError] = useState('');
  const [isReplace, setIsReplace] = useState(false);

  useEffect(() => {
    if (dataId) {
      getDataById(RESOURCE_NAMES['GROUNDWATER-HSLS'], dataId);
    } else {
      setDisabled(false);
    }
  }, [dataId]);

  useEffect(() => {
    if (groundwater[dataId] && !isReplace) {
      let arr = groundwater[dataId];
      setName(arr.name);
      setGroup(arr.group);
      setFreshWater(arr.freshWater);
      setMarineWater(arr.marineWater);
      setDrinkingWater(arr.drinkingWater);
    }
  }, [groundwater, isReplace]);

  /** Function for create new data */
  const handleAdd = async () => {
    let dt = {
      name: name.trim(),
      group: group,
      freshWater: freshWater,
      marineWater: marineWater,
      drinkingWater: drinkingWater,
      userId: SessionUtils.getUserData().id,
    };
    const reply = await APIService.get.groundwater.all();
    if (
      !reply.data.some(
        (item) =>
          item.name.trim().toLowerCase() == name.trim().toLowerCase() &&
          item.status == 'active'
      )
    ) {
      Swal.fire({
        title: 'Check your data',
        text: 'Review your data before submission.',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Submit',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          return addData(dt)
            .then((res) => {
              setError('');
              return { isConfirmed: true };
            })
            .catch((error) => {
              Swal.fire({
                title: 'An Error occured!',
                icon: 'error',
                confirmButtonColor: '#28a745',
              });
            });
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Swal.fire({
            icon: 'success',
            title: 'Groundwater data successfully created!',
            confirmButtonColor: '#28a745',
          });
          window.location.href = `${process.env.MIX_APP_URL}/groundwater-hsls`;
        }
      });
    } else {
      const redirectId = reply.data.find(
        (item) =>
          item.name.trim().toLowerCase() == name.trim().toLowerCase() &&
          item.status == 'active'
      ).id;
      Swal.fire({
        title: `Groundwater with the name ${name} already exists!`,
        text: 'You have to replace the existing data first OR rename the current data.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#309e1d',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Rename this data',
        confirmButtonText: 'Replace the existing data',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          setError('');
          history.push(`/groundwater-hsls/${redirectId}`);
          setIsReplace(true);
        } else {
          setIsReplace(false);
        }
      });
    }
  };

  /** Function for updating data */
  const handleUpdate = async () => {
    let dt = {
      name: name.trim(),
      group: group,
      freshWater: freshWater,
      marineWater: marineWater,
      drinkingWater: drinkingWater,
      userId: SessionUtils.getUserData().id,
    };
    const reply = await APIService.get.groundwater.all();
    if (
      !reply.data.some(
        (item) =>
          item.name.trim().toLowerCase() !==
            groundwater[dataId].name.toLowerCase() &&
          item.name.trim().toLowerCase() == name.trim().toLowerCase() &&
          item.status == 'active'
      )
    ) {
      Swal.fire({
        title: 'Check your data',
        text: 'Review your data before submission.',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Submit',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          setError('');
          return updateData(dataId, dt)
            .then((res) => {
              return { isConfirmed: true };
            })
            .catch((error) => {
              Swal.fire({
                title: 'An Error occured!',
                icon: 'error',
                confirmButtonColor: '#28a745',
              });
            });
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Swal.fire({
            icon: 'success',
            title: 'Groundwater data successfully updated!',
            confirmButtonColor: '#28a745',
          });
          window.location.href = `${process.env.MIX_APP_URL}/groundwater-hsls`;
        }
      });
    } else {
      const redirectId = reply.data.find(
        (item) =>
          item.name.trim().toLowerCase() == name.trim().toLowerCase() &&
          item.status == 'active'
      ).id;
      Swal.fire({
        title: `Groundwater with the name ${name} already exists!`,
        text: 'You have to replace the existing data first OR rename the current data.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#309e1d',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Rename this data',
        confirmButtonText: 'Replace the existing data',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          setError('');
          history.push(`/groundwater-hsls/${redirectId}`);
          setIsReplace(true);
        } else {
          setIsReplace(false);
        }
      });
    }
  };

  /** Function for deleting data */
  const handleDelete = async () => {
    const result = await Swal.fire({
      title: 'Delete this data?',
      text: 'All of this related data will be deleted permanently',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    });
    if (result.value) {
      try {
        await deleteData(RESOURCE_NAMES['GROUNDWATER-HSLS'], dataId);
        window.location.href = `${process.env.MIX_APP_URL}/groundwater-hsls`;
      } catch (err) {
        await Swal.fire({
          title: 'An Error occured!',
          icon: 'error',
          confirmButtonColor: '#28a745',
        });
      }
    }
  };

  return (
    <div>
      {dataId ? (
        <PageTitle
          title="Edit Groundwater HSLs"
          description="Edit your existing groundwater substance data."
          className="pe-7s-drop"
        />
      ) : (
        <PageTitle
          title="Add New Groundwater HSLs"
          description="Create your new groundwater substance data."
          className="pe-7s-drop"
        />
      )}

      <div className="flex flex-col px-6 pt-2 pb-6 space-y-8">
        <Card
          title={
            dataId
              ? 'Edit Groundwater Contaminant'
              : 'Add New Groundwater Contaminant'
          }
        >
          <div className="flex flex-col space-y-2 lg:space-y-6">
            {error !== '' && <p className="text-red-500">* {error}</p>}

            <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-8">
              <div className="lg:w-1/2">
                <p className="text-sm mb-1">Contaminant Name</p>
                <input
                  className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                  name="contaminant"
                  type="text"
                  placeholder="New Contaminant"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  disabled={disabled}
                />
              </div>
              <div className="lg:w-1/2">
                <p className="text-sm mb-1">Contaminant Group</p>
                <Select
                  fullWidth
                  customClass={'text-sm px-3 py-2'}
                  placeholder="Choose Contaminants Group"
                  data={groundwaterContaminants.map((item) => {
                    return {
                      value: item,
                      label: item,
                    };
                  })}
                  onChange={(e) => setGroup(e.target.value)}
                  value={group}
                  disabled={disabled}
                />
              </div>
            </div>
            {/* Second Row */}
            <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-8">
              <div className="lg:w-1/3">
                <p className="text-sm mb-1">Fresh Water</p>
                <div className="flex flex-row justify-center items-center space-x-2 w-full">
                  <input
                    className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                    name="contaminant"
                    type="number"
                    placeholder={0}
                    onChange={(e) => setFreshWater(e.target.value)}
                    value={freshWater}
                    disabled={disabled}
                  />
                  <span>ug/L</span>
                </div>
              </div>
              <div className="lg:w-1/3">
                <p className="text-sm mb-1">Marine Water</p>
                <div className="flex flex-row justify-center items-center space-x-2 w-full">
                  <input
                    className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                    name="contaminant"
                    type="number"
                    placeholder={0}
                    onChange={(e) => setMarineWater(e.target.value)}
                    value={marineWater}
                    disabled={disabled}
                  />
                  <span>ug/L</span>
                </div>
              </div>
              <div className="lg:w-1/3">
                <p className="text-sm mb-1">Drinking Water</p>
                <div className="flex flex-row justify-center items-center space-x-2 w-full">
                  <input
                    className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                    name="contaminant"
                    type="number"
                    placeholder={0}
                    onChange={(e) => setDrinkingWater(e.target.value)}
                    value={drinkingWater}
                    disabled={disabled}
                  />
                  <span>ug/L</span>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <div
          className={classNames(
            { 'flex-row-reverse': !dataId },
            'flex flex-row justify-between px-4'
          )}
        >
          {dataId &&
            disabled &&
            groundwater[dataId] &&
            (SessionUtils.getUserData().role == 'admin' ||
              SessionUtils.getUserData().id == groundwater[dataId].userId) && (
              <span
                className="cursor-pointer text-green-500"
                onClick={() => setDisabled(false)}
              >
                Edit
              </span>
            )}

          {dataId &&
            !disabled &&
            groundwater[dataId] &&
            (SessionUtils.getUserData().role == 'admin' ||
              SessionUtils.getUserData().id == groundwater[dataId].userId) && (
              <span
                className="cursor-pointer  text-red-500"
                onClick={handleDelete}
              >
                Delete
              </span>
            )}

          {dataId ? (
            !disabled &&
            groundwater[dataId] &&
            (SessionUtils.getUserData().role == 'admin' ||
              SessionUtils.getUserData().id == groundwater[dataId].userId) && (
              <Button
                lowercase
                theme="success"
                text="Update Data"
                onClick={handleUpdate}
                disabled={
                  name.trim() === '' ||
                  group.trim() === '' ||
                  freshWater === '' ||
                  marineWater === '' ||
                  drinkingWater === ''
                }
              />
            )
          ) : (
            <Button
              lowercase
              theme="success"
              text="Submit"
              onClick={handleAdd}
              disabled={
                name.trim() === '' ||
                group.trim() === '' ||
                freshWater === '' ||
                drinkingWater === '' ||
                marineWater === ''
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  groundwater: getResource(RESOURCE_NAMES['GROUNDWATER-HSLS'])(state),
});

export default connect(mapStateToProps, {
  getDataById: _getDataById,
  addData: _addData(RESOURCE_NAMES['GROUNDWATER-HSLS']),
  updateData: _updateData(RESOURCE_NAMES['GROUNDWATER-HSLS']),
  deleteData: _deleteData,
})(Groundwater);
