import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import { SelectTable } from './SelectTable';

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * Component for Table component.
 *
 * Provides table display for an array of one-level-deep object.
 *
 * @component
 */
export const Table = ({
  data,
  show = 5,
  hide = [],
  NL = 1, // Set how the zero value show. if NL=1 show "0" , NL=2 show "0 (NL)" , NL=3 show "NL"
  hidePagination = false,
  headerText = '',
  headerBgColor = 'bg-gray-50',
  headerTextColor = 'text-gray-900',
  colSort = null,
  sort = true,
  chartFilter,
}) => {
  if (data == null || data.length === 0) return null;

  const [tableData, setTableData] = useState(data);
  const [rowPerPages, setRowPerPages] = useState(show);
  const [totalPages, setTotalPages] = useState(
    tableData.length < rowPerPages
      ? 1
      : Math.ceil(tableData.length / rowPerPages)
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState(tableData);

  const [sortedField, setSortedField] = useState(colSort);
  const [ascending, setAscending] = useState(sort);
  const prevSortedField = usePrevious(sortedField);

  const goToPage = (step) => {
    if (step === -1) setCurrentPage(Math.max(1, currentPage + step));
    else setCurrentPage(Math.min(totalPages, currentPage + step));
  };

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    let start = rowPerPages * (currentPage - 1);
    let end = rowPerPages * currentPage;
    if (start < 0) start = 0;
    if (end > tableData.length) end = tableData.length;
    setCurrentData(tableData.slice(start, end));
  }, [currentPage, rowPerPages, tableData]);

  useEffect(() => {
    setCurrentPage(1);
    if (tableData.length < rowPerPages) setTotalPages(1);
    else setTotalPages(Math.ceil(tableData.length / rowPerPages));
  }, [rowPerPages]);

  useEffect(() => {
    setTableData(
      _.orderBy(
        tableData,
        (o) => (o[sortedField] === '-' ? 0 : o[sortedField]), // assumes "-" is equal to 0
        [ascending ? 'asc' : 'desc']
      )
    );
  }, [sortedField, ascending]);

  useEffect(() => {
    setSortedField(colSort);
    setAscending(sort);
  }, [colSort, sort]);

  return (
    <div className="flex flex-col space-y-4">
      <table className="responsive w-full flex flex-row flex-no-wrap text-sm rounded sm:rounded-none overflow-hidden border-collapse">
        {/*Table header*/}
        <thead className={`${headerBgColor} ${headerTextColor} font-semibold`}>
          {currentData.map((item, index) => (
            <tr
              key={index}
              className="flex flex-col flex-nowrap text-left rounded-l-lg border-b-2 border-xviolet sm:border-gray-300 sm:rounded-none sm:mb-0 sm:table-row"
            >
              {Object.keys(currentData[0]).map((item, index) => (
                <th
                  key={index}
                  className={classNames(
                    {
                      hidden: hide.indexOf(item) > -1,
                    },
                    'py-1 px-2 h-12 border lg:border-0 border-white font-semibold'
                  )}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setSortedField(item);
                      setAscending(!ascending);
                      chartFilter && chartFilter(item, !ascending);
                    }}
                    className="flex flex-row items-center space-x-1"
                  >
                    <span
                      className={classNames(
                        { 'font-bold': item === sortedField },
                        headerText
                      )}
                    >
                      {item === 'Frequency Range (cm -1)' ? (
                        <span>
                          Frequency Range (cm<sup>-1</sup>)
                        </span>
                      ) : (
                        item
                      )}
                    </span>

                    {item === sortedField && ascending && <b>&darr;</b>}
                    {item === sortedField && !ascending && <b>&uarr;</b>}
                  </button>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {/*Table body*/}
        <tbody className="flex-1 sm:flex-none text-gray-800">
          {currentData.map((item, index) => (
            <tr
              key={index}
              className="flex flex-col flex-nowrap text-left rounded-r-lg border-b-2 border-xviolet hover:bg-gray-100 sm:border-gray-200 sm:rounded-none sm:mb-0 sm:table-row"
            >
              {Object.entries(item).map(([key, value], index) => (
                <td
                  key={index}
                  className={classNames(
                    {
                      hidden: hide.indexOf(key) > -1,
                    },
                    'py-1 px-2 h-12 border border-white'
                  )}
                >
                  {value == 0
                    ? NL == 1
                      ? 0
                      : NL == 2
                      ? '0 (NL)'
                      : NL == 3 && 'NL'
                    : value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {/*Table pagination*/}
      {!hidePagination && (
        <div className="flex flex-row self-end content-center text-xs">
          <span className="self-center">Rows per page:</span>
          <span className="self-center">
            <SelectTable
              onChange={(e) => setRowPerPages(e.target.value)}
              data={[
                { value: 5, label: '5' },
                { value: 10, label: '10' },
                { value: 20, label: '20' },
                { value: data.length, label: 'All' },
              ]}
              value={String(rowPerPages)}
            />
          </span>
          <span className="self-center">
            {rowPerPages * (currentPage - 1) + 1} -{' '}
            {Math.min(rowPerPages * currentPage, tableData.length)} of{' '}
            {tableData.length}
          </span>
          <button
            type="button"
            disabled={currentPage === 1}
            onClick={() => goToPage(-1)}
            className="self-center rounded-full hover:bg-gray-100 disabled:opacity-30 disabled:pointer-events-none"
          >
            <span className="text-gray-600 pe-7s-angle-left text-3xl font-semibold" />
          </button>
          <button
            type="button"
            disabled={currentPage === totalPages}
            onClick={() => goToPage(1)}
            className="self-center rounded-full hover:bg-gray-100 disabled:opacity-30 disabled:pointer-events-none"
          >
            <span className="text-gray-600 pe-7s-angle-right text-3xl font-semibold" />
          </button>
        </div>
      )}
    </div>
  );
};
