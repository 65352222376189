import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { MainLayout, PrivateRoute } from './components';

import Dashboard from './views/Dashboard/Dashboard';
import Infocare from './views/Infocare/Infocare';
import { InfocareTable } from './views/Infocare';
import { Auth } from './views/Session/Auth';
import Soil from './views/Soil/Soil';
import SoilForm from './views/Soil/SoilForm';

import Groundwater from './views/Groundwater/Groundwater';
import GroundwaterForm from './views/Groundwater/GroundwaterForm';

import Vapour from './views/Vapour/Vapour';
import VapourForm from './views/Vapour/VapourForm';
import FileForm from './views/File/FileForm';
import Result from './views/File/Result';
import RadarChart from './views/Dashboard/RadarChart';
import RankingChart from './views/Dashboard/RankingChart';
import PieChart from './views/Dashboard/PieChart';

import { Provider as ReduxProvider } from 'react-redux';
import store from './store';
import UserGuide from './views/UserGuide/UserGuide';

const App = () => (
  <Router>
    <MainLayout>
      <Switch>
        <Route exact path="/login" component={Auth} />
        <Route path="/login/:token+" component={Auth} />

        <PrivateRoute exact path="/" component={Dashboard} />

        <PrivateRoute exact path="/infocare" component={Infocare} />
        <PrivateRoute path="/infocare/search" component={InfocareTable} />

        <PrivateRoute exact path="/soil-hsls" component={Soil} />
        <PrivateRoute exact path="/soil-hsls/new" component={SoilForm} />
        <PrivateRoute path="/soil-hsls/:dataId" component={SoilForm} />

        <PrivateRoute exact path="/groundwater-hsls" component={Groundwater} />
        <PrivateRoute
          exact
          path="/groundwater-hsls/new"
          component={GroundwaterForm}
        />
        <PrivateRoute
          path="/groundwater-hsls/:dataId"
          component={GroundwaterForm}
        />

        <PrivateRoute exact path="/vapour" component={Vapour} />
        <PrivateRoute
          exact
          path="/vapour/:vapourType/new"
          component={VapourForm}
        />
        <PrivateRoute
          path="/vapour/:vapourType/:dataId"
          component={VapourForm}
        />

        <PrivateRoute exact path="/file/new" component={FileForm} />
        <PrivateRoute exact path="/result" component={Result} />
        <PrivateRoute path="/result/:dataId" component={FileForm} />

        <PrivateRoute
          exact
          path="/overview/stat/radar-chart"
          component={RadarChart}
        />
        <PrivateRoute
          path="/overview/stat/ranking-scatter"
          component={RankingChart}
        />
        <PrivateRoute
          exact
          path="/overview/stat/pie-chart"
          component={PieChart}
        />
        <PrivateRoute path="/user-guide" component={UserGuide} />
      </Switch>
    </MainLayout>
  </Router>
);

ReactDOM.render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>,
  document.getElementById('root')
);
