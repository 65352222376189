import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RESOURCE_NAMES } from '../../store/reducers/resources';
import {
  getAllData as _getAlldata,
  deleteData as _deleteData,
} from '../../store/actions/resources';
import { getResource } from '../../store/selectors/resources';
import { SessionUtils, UserService } from '../../utils';
import { Link } from 'react-router-dom';
import { Card, PageTitle, Table } from '../../components';
import Swal from 'sweetalert2';
import _ from 'lodash';

const Result = ({ getAllData, deleteData, result }) => {
  const [resultTable, setResultTable] = useState([]);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllData(RESOURCE_NAMES['FILES']).then(() => {
      setLoading(false);
    });
    UserService.getAllUsers().then((res) => {
      setUserData(res);
    });
  }, []);

  useEffect(() => {
    if (!loading && result && userData) {
      let newResult = _.orderBy(_.values(result), ['created_at'], ['desc'])
        .filter((item) => {
          if (SessionUtils.getUserData().role === 'admin') {
            return item.status === 'active';
          } else {
            return (
              item.status === 'active' &&
              item.userId == SessionUtils.getUserData().id
            );
          }
        })
        .map((item) => {
          const actions = (
            <div className="flex flex-row flex-grow space-x-2">
              <Link
                to={`/result/${item.id}`}
                className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-blue-400 text-blue-400 hover:bg-blue-500 hover:text-white focus:outline-none"
              >
                <div className="self-center align-middle font-normal text-lg pe-7s-look transition duration-200" />
              </Link>
              <button
                onClick={() => handleDelete(item.id)}
                className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-red-400 text-red-400 hover:bg-red-600 hover:text-white focus:outline-none"
              >
                <div className="self-center align-middle font-normal text-lg pe-7s-trash transition duration-200" />
              </button>
            </div>
          );

          let username = '';
          if (SessionUtils.getUserData().role === 'admin') {
            username = userData.find((user) => user.id === item.userId).name;
          } else {
            username = SessionUtils.getUserData().name;
          }

          return {
            Username: username,
            'Site Name': item.name,
            'Soil Hazard Quotient': _.round(item.soilData.totalHQ, 2),
            'Groundwater Hazard Quotient': _.round(
              item.groundWaterData.totalHQ,
              2
            ),
            'Vapour Intrusion Hazard Quotient': _.round(
              item.vapourData.totalHQ,
              2
            ),
            'Total Hazard Quotient': _.round(
              item.soilData.totalHQ +
                item.groundWaterData.totalHQ +
                item.vapourData.totalHQ,
              2
            ),
            Actions: actions,
          };
        });
      setResultTable(newResult);
    }
  }, [result, userData, loading]);

  /** Function for deleting data */
  const handleDelete = async (dataId) => {
    const result = await Swal.fire({
      title: 'Delete this data?',
      text: 'All of this related data will be deleted permanently',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    });
    if (result.value) {
      try {
        deleteData(RESOURCE_NAMES['FILES'], dataId);
      } catch (err) {
        await Swal.fire({
          title: 'An Error occured!',
          icon: 'error',
        });
      }
    }
  };

  return (
    <div>
      <PageTitle
        title="Previous Results"
        description="See all data inserted in this app."
        className="pe-7s-folder"
      />
      <div className="flex flex-col px-6 pt-2 pb-6 space-y-8">
        <Card title="Previous Result">
          <Table data={resultTable} NL={2} show={10} />
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  result: getResource(RESOURCE_NAMES['FILES'])(state),
});

export default connect(mapStateToProps, {
  getAllData: _getAlldata,
  deleteData: _deleteData,
})(Result);
