import axios from 'axios';
import { SessionUtils } from './SessionUtils';

// Send get request and returns a Promise from Axios
const sendGet = async (url, params = {}) => {
  return axios({
    method: 'GET',
    url: url,
    headers: {
      Authorization: `Bearer ${SessionUtils.getToken()}`,
    },
    params: params,
  });
};

// Send post request and returns a Promise from Axios
const sendPost = async (url, payload = {}) => {
  return axios({
    method: 'POST',
    url: url,
    headers: {
      Authorization: `Bearer ${SessionUtils.getToken()}`,
      'content-type': 'application/json',
    },
    data: payload,
  });
};

// Send patch request and returns a Promise from Axios
const sendPatch = async (url, payload = {}) => {
  return axios({
    method: 'PATCH',
    url: url,
    headers: {
      Authorization: `Bearer ${SessionUtils.getToken()}`,
      'content-type': 'application/json',
    },
    data: payload,
  });
};

// Send delete request and returns a Promise from Axios
const sendDelete = async (url) => {
  return axios({
    method: 'DELETE',
    url: url,
    headers: {
      Authorization: `Bearer ${SessionUtils.getToken()}`,
      'content-type': 'application/json',
    },
  });
};

/**
 * Holds various function for communicating with riskCARE backend.
 *
 * @namespace
 */
export const APIService = {
  get: {
    searchAll: (keyword) =>
      sendGet(`${process.env.MIX_APP_URL}/api/search?all=${keyword}`),
    files: {
      all: () => sendGet(`${process.env.MIX_APP_URL}/api/files`),
      view: (id) => sendGet(`${process.env.MIX_APP_URL}/api/files/${id}`),
    },
    soil: {
      all: () => sendGet(`${process.env.MIX_APP_URL}/api/soil-hsls`),
      view: (id) => sendGet(`${process.env.MIX_APP_URL}/api/soil-hsls/${id}`),
      group: (group) =>
        sendGet(`${process.env.MIX_APP_URL}/api/soil-hsls?group=${group}`),
    },
    groundwater: {
      all: () => sendGet(`${process.env.MIX_APP_URL}/api/groundwater-hsls`),
      view: (id) =>
        sendGet(`${process.env.MIX_APP_URL}/api/groundwater-hsls/${id}`),
      group: (group) =>
        sendGet(
          `${process.env.MIX_APP_URL}/api/groundwater-hsls?group=${group}`
        ),
    },
    vapourSoil: {
      all: () =>
        sendGet(`${process.env.MIX_APP_URL}/api/vapour-intrusion-soils`),
      view: (id) =>
        sendGet(`${process.env.MIX_APP_URL}/api/vapour-intrusion-soils/${id}`),
    },
    vapourGroundWater: {
      all: () =>
        sendGet(`${process.env.MIX_APP_URL}/api/vapour-intrusion-groundwaters`),
      view: (id) =>
        sendGet(
          `${process.env.MIX_APP_URL}/api/vapour-intrusion-groundwaters/${id}`
        ),
    },
    vapourSoilVapour: {
      all: () =>
        sendGet(`${process.env.MIX_APP_URL}/api/vapour-intrusion-soil-vapours`),
      view: (id) =>
        sendGet(
          `${process.env.MIX_APP_URL}/api/vapour-intrusion-soil-vapours/${id}`
        ),
    },
    pieChart: () => sendGet(`${process.env.MIX_APP_URL}/api/piechart/files`),
  },
  post: {
    files: {
      review: (file) =>
        sendPost(`${process.env.MIX_APP_URL}/api/files/review`, file),
      submit: (data) => sendPost(`${process.env.MIX_APP_URL}/api/files`, data),
    },
  },
  patch: {},
  delete: {},
};
