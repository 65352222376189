import React from 'react';
import { Button, PageTitle } from '../../components';
import { Accordion, AccordionItem } from 'react-sanfona';
import RiskCare from '../../components/RiskCare';
import dashboardImg from '../../../../public/assets/images/user-guide/dashboard.png';
import radarChart1Img from '../../../../public/assets/images/user-guide/radar-chart-1.png';
import radarChart2Img from '../../../../public/assets/images/user-guide/radar-chart-2.png';
import rankingScatter1Img from '../../../../public/assets/images/user-guide/ranking-scatter-1.png';
import rankingScatter2Img from '../../../../public/assets/images/user-guide/ranking-scatter-2.png';
import pieChart1Img from '../../../../public/assets/images/user-guide/pie-chart-1.png';
import infocare2Img from '../../../../public/assets/images/user-guide/infocare-2.png';
import infocare3Img from '../../../../public/assets/images/user-guide/infocare-3.png';
import soil1Img from '../../../../public/assets/images/user-guide/soil-1.png';
import soil2Img from '../../../../public/assets/images/user-guide/soil-2.png';
import groundwater1Img from '../../../../public/assets/images/user-guide/groundwater-1.png';
import groundwater2Img from '../../../../public/assets/images/user-guide/groundwater-2.png';
import vapour1Img from '../../../../public/assets/images/user-guide/vapour-1.png';
import vapour2Img from '../../../../public/assets/images/user-guide/vapour-2.png';
import newFile1Img from '../../../../public/assets/images/user-guide/new-file-1.png';
import newFile2Img from '../../../../public/assets/images/user-guide/new-file-2.png';
import newFile3Img from '../../../../public/assets/images/user-guide/new-file-3.png';
import newFile4Img from '../../../../public/assets/images/user-guide/new-file-4.png';
import result1Img from '../../../../public/assets/images/user-guide/result-1.png';

const UserGuide = () => {
  return (
    <div>
      <PageTitle
        title="User guide"
        className="pe-7s-bookmarks"
        right={
          <Button
            lowercase
            theme="success"
            href="/riskCARE_User_Guide_Oct2021.pdf"
            text="Download"
          />
        }
      />
      <div className="flex flex-col px-6 pt-2 pb-12 space-y-8">
        <Accordion className="guide flex flex-col shadow-2xl">
          <AccordionItem
            titleClassName="flex items-center justify-between border-collapse border-t border-b border-white w-full px-8 py-4 text-left bg-xgreen text-white font-semibold hover:underline"
            bodyClassName="bg-white border-r-2 border-l-2 border-gray-100 relative box-border px-8 text-sm"
            title="Introduction"
            expanded
          >
            <p>
              <b>
                <RiskCare />
              </b>{' '}
              was developed and patented by the Cooperative Research Centre for
              Contamination Assessment and Remediation of the Environment (CRC
              CARE).
            </p>
            <p>
              <RiskCare /> is an online site assessment tool that determines
              whether site contamination poses an actual or potential risk to
              human health and the environment, on or off the site, of
              sufficient magnitude to justify remediation suitable to the
              existing or proposed land use. <RiskCare /> uses extensive
              implementation information from the National Environment
              Protection (Assessment of Site Contamination) Measure (ASC NEPM)
              to assess site contamination. <RiskCare /> provides a nationally
              consistent system to efficiently and effectively assess site
              contamination to ensure that the community – which includes
              regulators, site assessors, environmental auditors, landowners,
              developers, and industry – follows acceptable environmental
              management practices.
            </p>

            <p>
              According to the ASC NEPM<sup>1</sup>,{' '}
              <b>health screening levels (HSLs)</b> have been developed for a
              broad range of metals and organic substances. HSLs are
              scientifically based, generic assessment criteria designed to be
              used in the first stage (Tier 1 or ‘screening’) of an assessment
              of potential risks to human health from chronic exposure to
              contaminants. They are intentionally conservative and are based on
              a reasonable worst-case scenario for four generic land-use
              settings:
            </p>

            <p>
              <b>HSL A (residential):</b> residential with garden/accessible
              soil (homegrown produce with less than 10 per cent fruit and
              vegetable intake, no poultry), including children’s day-care
              centres, preschools and primary schools
            </p>

            <p>
              <b>HSL B (residential):</b> residential with minimal opportunities
              for soil access, including dwellings with fully and permanently
              paved yard space, such as high-rise buildings and flats
            </p>

            <p>
              <b>HSL C (recreational):</b> public open space such as parks,
              playgrounds, playing fields (e.g. ovals), secondary schools and
              footpaths; it does not include undeveloped public open space (such
              as urban bushland and reserves), which should be subject to a
              site-specific assessment where appropriate
            </p>

            <p>
              <b>HSL D (commercial/industrial):</b> commercial/industrial such
              as shops, offices, factories and industrial sites.
            </p>

            <p>
              In <RiskCare />, risk/hazard quotient (HQ) and risk/hazard index
              (HI) are applied to evaluate and rank contaminated sites. HQ for
              each contaminant is calculated using the ratio of real measurement
              result and the regulated HSL. The HQs for each contaminant can be
              summed to produce a total HI:
            </p>

            <p>
              HQ = Value<sub>contaminant</sub> / HSL <br />
              HI = Σ HQs
            </p>
            <p>
              <RiskCare /> provides statistical analysis for contaminated site
              management. A radar plot with total HQ, soil HQ, groundwater HQ
              and vapour intrusion HQ is provided for each evaluated site. Users
              can compare and rank their sites based on the valu es of each HQ.
            </p>
            <p>
              <b>
                infoCARE<sup>TM</sup>
              </b>
              , developed and patented by CRC CARE, is also available within{' '}
              <RiskCare />. infoCARE<sup>TM</sup> is a search engine that
              provides information on HILs for regulated contaminants in soil,
              groundwater, and vapour intrusion. Users can search for
              contaminants by entering the analyte’s name or skimming through
              the contaminant catalogue. The <RiskCare /> database and infoCARE
              <sup>TM</sup> search engine provide detailed information on the
              regulated HSLs for soil contaminants, groundwater contaminants and
              vapour intrusion.
            </p>
            <p>
              <hr className="mb-2 border-black" />
              <sup>1</sup> National Environment Protection (Assessment of Site
              Contamination) Measure 1999, F2013C00288,{' '}
              <a
                href="https://www.legislation.gov.au/Details/F2013C00288"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                www.legislation.gov.au/Details/F2013C00288
              </a>
            </p>
          </AccordionItem>
          <AccordionItem
            titleClassName="flex items-center justify-between border-collapse border-t border-b border-white w-full px-8 py-4 text-left bg-xgreen text-white font-semibold hover:underline"
            bodyClassName="bg-white border-r-2 border-l-2 border-gray-100 relative box-border px-8 text-sm"
            title="Using the dashboard"
          >
            <p>
              Under Overview, in the dashboard menu, you can see the total
              number of files you have entered or measured (note: administrators
              can also see files entered or measured by other users).
            </p>
            <p>
              The chart shows, by date, how many measurements were made each
              day.
            </p>
            {/* Image */}
            <img src={dashboardImg} alt="Dashboard view" />
            <br />
          </AccordionItem>
          <AccordionItem
            titleClassName="flex items-center justify-between border-collapse border-t border-b border-white w-full px-8 py-4 text-left bg-xgreen text-white font-semibold hover:underline"
            bodyClassName="bg-white border-r-2 border-l-2 border-gray-100 relative box-border px-8 text-sm"
            title="Viewing files as a radar chart"
          >
            <p>
              In the radar chart menu, you can view the resulting files as a
              radar chart to compare site HQ with all HQ data. Above the graph,
              you can select from the drop-down list the files you want to
              display.
            </p>
            {/* Image */}
            <img src={radarChart1Img} alt="Radar chart 1" />
            <br />
            <p>
              The following is an example of how to display HQ data for one of
              the files as a radar graph.
            </p>
            {/* Image */}
            <img src={radarChart2Img} alt="Radar chart 2" />
            <br />
          </AccordionItem>
          <AccordionItem
            titleClassName="flex items-center justify-between border-collapse border-t border-b border-white w-full px-8 py-4 text-left bg-xgreen text-white font-semibold hover:underline"
            bodyClassName="bg-white border-r-2 border-l-2 border-gray-100 relative box-border px-8 text-sm"
            title="Ranking files as a scatter plot"
          >
            <p>
              In the ranking scatter plot menu, you can rank files based on the
              values of the current HQ. Above the chart, you can select from a
              drop-down list the HQ you want to display.
            </p>
            {/* Image */}
            <img src={rankingScatter1Img} alt="Rangkin scatter plot 1" />
            <br />
            <p>
              The following is an example of how to display the ranking of soil
              HQ as a scatter plot.
            </p>
            {/* Image */}
            <img src={rankingScatter2Img} alt="Rangkin scatter plot 2" />
            <br />
          </AccordionItem>
          <AccordionItem
            titleClassName="flex items-center justify-between border-collapse border-t border-b border-white w-full px-8 py-4 text-left bg-xgreen text-white font-semibold hover:underline"
            bodyClassName="bg-white border-r-2 border-l-2 border-gray-100 relative box-border px-8 text-sm"
            title="Viewing a pie chart of user activity"
          >
            <p>
              In the pie chart menu, you can view the number of measurements and
              the number of soil HSLs, groundwater HSLs, and vapour intrusion
              for each user of the <RiskCare /> application. Data are presented
              as pie charts and also in a table.
            </p>
            {/* Image */}
            <img src={pieChart1Img} alt="Pie chart 1" />
            <br />
          </AccordionItem>
          <AccordionItem
            titleClassName="flex items-center justify-between border-collapse border-t border-b border-white w-full px-8 py-4 text-left bg-xgreen text-white font-semibold hover:underline"
            bodyClassName="bg-white border-r-2 border-l-2 border-gray-100 relative box-border px-8 text-sm"
            title="Using infoCARE™ – Searching for contaminants"
          >
            <p>
              In the infoCARE<sup>TM</sup> menu, you can search and view the
              contaminant data in the database. Features available by clicking
              on ‘
              <strong>
                infoCARETM<sup>TM</sup>
              </strong>
              ’ include:
              <ol style={{ listStyleType: 'lower-alpha' }}>
                <li>
                  search by keyword field by entering text into ‘
                  <strong>Type of Contaminants</strong>’ (a)
                </li>
                <li>
                  search by type of contaminant and substance/compound (b)
                </li>
                <li>
                  after specifying the search terms based on keywords (a) or
                  contaminants and substance/compound (b), click ‘
                  <strong>Search</strong>’ (c) to display the results page
                </li>
                <li>
                  use the ‘<strong>Clear All</strong>’ button (d) to clear the
                  search filter and start a new search.
                </li>
              </ol>
            </p>
            <p>
              You can also see the list of contaminants listed below the filter:
              soil, groundwater and vapour contaminants.
            </p>
            <p>
              In the example below (h), the data have not been expanded. If you
              press ‘<strong>Expand All</strong>’ (e), a list of
              substances/compounds will be displayed (f). To hide the
              substance/compound list, click ‘<strong>Collapse All</strong>’
              (g). You can also select one or more contaminants to display by
              selecting the contaminant/substance (i) to view individually (j).
            </p>
            {/* Image */}
            <img src={infocare2Img} alt="infoCARE 1" />
            <br />
            <p>
              The search results for contaminants/substances in the application
              database are displayed as follows:
            </p>
            {/* Image */}
            <img src={infocare3Img} alt="infoCARE 2" />
            <br />
          </AccordionItem>
          <AccordionItem
            titleClassName="flex items-center justify-between border-collapse border-t border-b border-white w-full px-8 py-4 text-left bg-xgreen text-white font-semibold hover:underline"
            bodyClassName="bg-white border-r-2 border-l-2 border-gray-100 relative box-border px-8 text-sm"
            title="Listing soil HSLs"
          >
            <p>
              All the soil contaminants are listed in the Soil HSLs list menu. A
              table of the current list of contaminants provides:
              <ol style={{ listStyleType: 'decimal' }}>
                <li>chemical name</li>
                <li>chemical group</li>
                <li>residential A</li>
                <li>residential B</li>
                <li>recreational C</li>
                <li>commercial/industrial D.</li>
              </ol>
            </p>
            <p>
              Click on the eye icon (a) for details of each contaminant and to
              edit/recalibrate these. Click on the bin icon (b) to delete the
              contaminant from the list. Click on ‘
              <strong>Add contaminants</strong>’ (c) to add a new soil
              contaminant into the riskCARE<sup>TM</sup> system.
            </p>
            {/* Image */}
            <img src={soil1Img} alt="Listing soil HSLs 1" />
            <br />
            <p>
              How to add a new contaminant or to edit an existing contaminant.
            </p>
            <p>
              <ol style={{ listStyleType: 'decimal' }}>
                <li>
                  First, the user should provide the (a):
                  <ul>
                    <li>contaminant name</li>
                    <li>contaminant group</li>
                    <li>residential A</li>
                    <li>residential B</li>
                    <li>recreational C</li>
                    <li>commercial/industrial D</li>
                  </ul>
                </li>
                <br />
                <li>
                  Click on ‘<strong>Submit</strong>’ (b) to save the
                  contaminant.
                </li>
              </ol>
            </p>
            {/* Image */}
            <img src={soil2Img} alt="Listing soil HSLs 2" />
            <br />
          </AccordionItem>
          <AccordionItem
            titleClassName="flex items-center justify-between border-collapse border-t border-b border-white w-full px-8 py-4 text-left bg-xgreen text-white font-semibold hover:underline"
            bodyClassName="bg-white border-r-2 border-l-2 border-gray-100 relative box-border px-8 text-sm"
            title="Listing groundwater HSLs"
          >
            <p>
              All the groundwater contaminants are listed in the Groundwater
              HSLs list menu. A table of the current list of contaminants
              provides:
              <ol style={{ listStyleType: 'decimal' }}>
                <li>substance name</li>
                <li>chemical group</li>
                <li>fresh water</li>
                <li>marine water</li>
                <li>drinking water.</li>
              </ol>
            </p>
            <p>
              Click on the eye icon (a) for details of each contaminant and to
              edit/recalibrate these. Click on the bin icon (b) to delete the
              contaminant from the list. Click on ‘
              <strong>Add contaminants</strong>’ (c) to add a new groundwater
              contaminant into the riskCARE<sup>TM</sup> system.
            </p>
            {/* Image */}
            <img src={groundwater1Img} alt="Listing groundwater HSLs 1" />
            <br />
            <p>
              How to add a new contaminant or to edit an existing contaminant.
            </p>
            <p>
              <ol style={{ listStyleType: 'decimal' }}>
                <li>
                  First, the user should provide the (a):
                  <ul>
                    <li>contaminant name</li>
                    <li>contaminant group</li>
                    <li>fresh water</li>
                    <li>marine water</li>
                    <li>drinking water</li>
                  </ul>
                </li>
                <br />
                <li>
                  Click on ‘<strong>Submit</strong>’ (b) to save the
                  contaminant.
                </li>
              </ol>
            </p>
            {/* Image */}
            <img src={groundwater2Img} alt="Listing groundwater HSLs 2" />
            <br />
          </AccordionItem>
          <AccordionItem
            titleClassName="flex items-center justify-between border-collapse border-t border-b border-white w-full px-8 py-4 text-left bg-xgreen text-white font-semibold hover:underline"
            bodyClassName="bg-white border-r-2 border-l-2 border-gray-100 relative box-border px-8 text-sm"
            title="Listing vapour intrusion"
          >
            <p>
              In the Vapour intrusion list menu, all the contaminants are
              listed. There are 3 types of tables on this page:
              <ol style={{ listStyleType: 'decimal' }}>
                <li>Soil Vapour HSLs for Vapour Intrusion (mg/kg)</li>
                <li>Groundwater HSLs for Vapour Intrusion (mg/L)</li>
                <li>Soil Vapour HSLs for Vapour Intrusion (mg/m3).</li>
              </ol>
            </p>
            <p>
              Click on the eye icon (a) for details of each contaminant and to
              edit/recalibrate these. Click on the bin icon (b) to delete the
              contaminant from the list. Click on ‘
              <strong>Add contaminants</strong>’ (c) to add a new vapour
              contaminant into the riskCARE<sup>TM</sup> system.
            </p>
            {/* Image */}
            <img src={vapour1Img} alt="Listing vapour intrusion 1" />
            <br />
            <p>
              How to add a new contaminant or to edit an existing contaminant.
            </p>
            <p>
              <ol style={{ listStyleType: 'decimal' }}>
                <li>
                  First, the user should provide the:
                  <ul>
                    <li>contaminant name (a)</li>
                    <li>
                      sand (HSL A&amp;B – Low_high Density Residential, HSL C –
                      Recreational/Open Space, and HSL D –
                      Commercial/Industrial) – (b)
                    </li>
                    <li>
                      silt (HSL A&amp;B – Low_high Density Residential, HSL C –
                      Recreational/Open Space, and HSL D –
                      Commercial/Industrial) – (c)
                    </li>
                    <li>
                      clay (HSL A&amp;B – Low_high Density Residential, HSL C –
                      Recreational/Open Space, and HSL D –
                      Commercial/Industrial) – (d)
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  Click on ‘<strong>Submit</strong>’ (b) to save the
                  contaminant.
                </li>
              </ol>
            </p>
            {/* Image */}
            <img src={vapour2Img} alt="Listing vapour intrusion 2" />
            <br />
          </AccordionItem>
          <AccordionItem
            titleClassName="flex items-center justify-between border-collapse border-t border-b border-white w-full px-8 py-4 text-left bg-xgreen text-white font-semibold hover:underline"
            bodyClassName="bg-white border-r-2 border-l-2 border-gray-100 relative box-border px-8 text-sm"
            title="Creating a new file"
          >
            <p>
              <ol style={{ listStyleType: 'decimal' }}>
                <li>
                  Under ‘<strong>App</strong>’, click on ‘
                  <strong>Create new file</strong>’.
                </li>
                <li>
                  Fill in the ‘<strong>Site name</strong>’ and ‘
                  <strong>Site description</strong>’ fields (a).
                </li>
                <li>
                  Choose the ‘<strong>Land uses</strong>’ (b).
                </li>
                <li>
                  Fill in the ‘<strong>Soil Contaminants</strong>’ data fields
                  (c) and click ‘<strong>Add</strong>’ (d) to add the soil
                  contaminant data.
                </li>
                <li>
                  Fill in the ‘<strong>Groundwater Contaminants</strong>’ data
                  fields (e) and click ‘<strong>Add</strong>’ (f) to add the
                  groundwater contaminant data.
                </li>
                <li>
                  Fill in the ‘<strong>Vapour Intrusion</strong>’ data fields
                  (g) and click ‘<strong>Add</strong>’ (h) to add the vapour
                  contaminant data.
                </li>
                <li>
                  Click on ‘<strong>Next Step</strong>’ (j) to view the
                  prediction results or click ‘<strong>Reset</strong>’ (i) to
                  erase all data and start over.
                </li>
              </ol>
            </p>
            {/* Image */}
            <img src={newFile1Img} alt="Creating a new file 1" />
            <br />
            <p>
              <strong>Review and Submit Page</strong>
            </p>
            <p>
              Preview the results and check if the data that have been entered
              are correct. If there are errors, click ‘<strong>Edit</strong>’
              (b) to change the input in the <strong>Create New File</strong>{' '}
              form. If the data that have been entered are correct, press ‘
              <strong>Submit</strong>’ (a) to save the file.
            </p>
            {/* Image */}
            <img src={newFile3Img} alt="Creating a new file 2" />
            <br />
          </AccordionItem>
          <AccordionItem
            titleClassName="flex items-center justify-between border-collapse border-t border-b border-white w-full px-8 py-4 text-left bg-xgreen text-white font-semibold hover:underline"
            bodyClassName="bg-white border-r-2 border-l-2 border-gray-100 relative box-border px-8 text-sm"
            title="Viewing results"
          >
            <p>
              The Results menu lists all the existing files and their results.
              The table provides the following information:
              <ol style={{ listStyleType: 'decimal' }}>
                <li>
                  username (of the user who performed the measurement and who
                  uploaded the file)
                </li>
                <li>site name</li>
                <li>soil HQ</li>
                <li>groundwater HQ</li>
                <li>vapour intrusion HQ</li>
                <li>total HQ.</li>
              </ol>
            </p>
            <p>
              The user can click on any of the column names to sort, the eye
              icon (a) to view details, and the bin icon (b) to delete a file.
            </p>
            {/* Image */}
            <img src={result1Img} alt="Viewing results" />
            <br />
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default UserGuide;
