import React from 'react';
import classNames from 'classnames';

/**
 * Component for Select/Dropdown Menu UI in table component.
 *
 * @component
 */
export const SelectTable = ({
  data,
  placeholder,
  fullWidth = false,
  ...rest
}) => (
  <select
    className={classNames(
      {
        'w-full': fullWidth,
      },
      'pr-2 cursor-pointer py-1 border-b border-gray-400 focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg'
    )}
    {...rest}
  >
    {placeholder && (
      <option value="" disabled selected hidden>
        {placeholder}
      </option>
    )}
    {data.map((item, index) => (
      <option key={index} value={item.value}>
        {item.label}
      </option>
    ))}
  </select>
);
