import React, { useState, useEffect } from 'react';
import 'chartkick/chart.js';
import { AreaChart } from 'react-chartkick';

import { Card, PageTitle } from '../../components';

import { connect } from 'react-redux';
import { RESOURCE_NAMES } from '../../store/reducers/resources';
import { getStatisticsData as _getAlldata } from '../../store/actions/resources';
import { getResource } from '../../store/selectors/resources';

const Dashboard = ({ getAllData, statistics }) => {
  /** State for storing total files input and total files box data */
  const [totalFilesInput, setTotalFilesInput] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);

  /** State for storing report chart data */
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    getAllData(RESOURCE_NAMES['STATISTICS']);
  }, []);

  useEffect(() => {
    setTotalFilesInput(statistics.thisFiles);

    if (statistics.roles === 'admin') {
      setTotalFiles(statistics.totalFiles);
    } else {
      setTotalFiles(0);
    }

    let hash = {};
    if (statistics.totalFilesByDate) {
      statistics.totalFilesByDate.forEach(function (c) {
        hash[c.date] = c.total;
      });
      setChartData(hash);
    }
  }, [statistics]);

  return (
    <div>
      <PageTitle
        title="Dashboard"
        description="Welcome! What do you want to do?"
        className="pe-7s-rocket"
      />

      <div className="dashboard-content flex flex-col px-6 pt-2 pb-12 space-y-8">
        <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-8 lg:space-y-0">
          {/*Total files input*/}
          <div className="flex flex-row w-full justify-between px-4 py-5 shadow-md totalFilesInput">
            <div className="flex flex-col text-sm">
              <span className="font-semibold text-gray-300">
                Total files input
              </span>
              <span className="text-gray-400">Report based on your input</span>
            </div>
            <span className="font-semibold text-3xl text-gray-200">
              {totalFilesInput}
            </span>
          </div>

          {/*Total files (only for admins)*/}
          {totalFiles > 0 && (
            <div className="flex flex-row w-full justify-between px-4 py-5 shadow-md totalFiles">
              <div className="flex flex-col text-sm">
                <span className="font-semibold text-gray-100">Total files</span>
                <span className="text-gray-200">Stored files on database</span>
              </div>
              <span className="font-semibold text-3xl text-gray-200">
                {totalFiles}
              </span>
            </div>
          )}
        </div>

        {/*Report chart section*/}
        <Card title="Report based on your input" icon="lnr lnr-apartment">
          <AreaChart data={chartData} />
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  statistics: getResource(RESOURCE_NAMES['STATISTICS'])(state),
});

export default connect(mapStateToProps, {
  getAllData: _getAlldata,
})(Dashboard);
