import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import { SelectTable } from './SelectTable';

// Save previous value of a state
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const isObject = (obj) => obj.constructor.name === 'Object';

const depthOf = function (object) {
  let level = 1;
  for (let key in object) {
    if (key == 'Actions') continue;
    if (!object.hasOwnProperty(key)) continue;

    if (typeof object[key] == 'object') {
      let depth = depthOf(object[key]) + 1;
      level = Math.max(depth, level);
    }
  }
  return level;
};

/**
 * Component for Table component in Vapour
 *
 * Provides table display for an array of two-level-deep object.
 *
 * @component
 */
export const TableVapour = ({
  data,
  show = 5,
  headerBgColor = 'bg-gray-100',
  headerTextColor = 'text-gray-900',
  headerText = '',
  hidePagination = false,
}) => {
  if (data == null || data.length === 0) return null;

  const [tableData, setTableData] = useState(data);
  const [rowPerPages, setRowPerPages] = useState(show);
  const [totalPages, setTotalPages] = useState(
    tableData.length < rowPerPages
      ? 1
      : Math.ceil(tableData.length / rowPerPages)
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState(tableData);

  const [sortedField, setSortedField] = useState(null);
  const [ascending, setAscending] = useState(true);
  const prevSortedField = usePrevious(sortedField);

  const [tableHeaderDepth, setTableHeaderDepth] = useState([]);
  const [tableHeaderHasChildren, setTableHeaderHasChildren] = useState([]);
  const [tableHeader2ndRow, setTableHeader2ndRow] = useState([]);

  const goToPage = (step) => {
    if (step === -1) setCurrentPage(Math.max(1, currentPage + step));
    else setCurrentPage(Math.min(totalPages, currentPage + step));
  };

  useEffect(() => {
    let start = rowPerPages * (currentPage - 1);
    let end = rowPerPages * currentPage;
    if (start < 0) start = 0;
    if (end > tableData.length) end = tableData.length;
    setCurrentData(tableData.slice(start, end));
  }, [currentPage, rowPerPages, tableData]);

  useEffect(() => {
    setCurrentPage(1);
    if (tableData.length < rowPerPages) setTotalPages(1);
    else setTotalPages(Math.ceil(tableData.length / rowPerPages));
  }, [rowPerPages]);

  useEffect(() => {
    if (Array.isArray(sortedField)) {
      setTableData(
        _.orderBy(
          tableData,
          [sortedField].flat(),
          [
            ascending ? 'asc' : 'desc',
            ascending ? 'asc' : 'desc',
            ascending ? 'asc' : 'desc',
            ascending ? 'asc' : 'desc',
            ascending ? 'asc' : 'desc',
          ].flat()
        )
      );
    } else {
      if (prevSortedField !== sortedField) setAscending(true);
      setTableData(
        _.orderBy(
          tableData,
          [sortedField].flat(),
          [ascending ? 'asc' : 'desc'].flat()
        )
      );
    }
  }, [sortedField, ascending]);

  useEffect(() => {
    let newData = data.map((item) => {
      if (item['Actions']) {
        return {
          Username: item['Username'],
          Chemical: item['Chemical'],
          'Soil Type': item['Soil Type'],
          hslab0: Number(
            item['HSL A&B - Low_high density residential']['0 to <1 m']
          ),
          hslab1: Number(
            item['HSL A&B - Low_high density residential']['1 to <2 m']
          ),
          hslab2: Number(
            item['HSL A&B - Low_high density residential']['2 to <4 m']
          ),
          hslab3: Number(
            item['HSL A&B - Low_high density residential']['4 to <8 m']
          ),
          hslab4: Number(
            item['HSL A&B - Low_high density residential']['8 m +']
          ),

          hslc0: Number(item['HSL C - Recreational / Open space']['0 to <1 m']),
          hslc1: Number(item['HSL C - Recreational / Open space']['1 to <2 m']),
          hslc2: Number(item['HSL C - Recreational / Open space']['2 to <4 m']),
          hslc3: Number(item['HSL C - Recreational / Open space']['4 to <8 m']),
          hslc4: Number(item['HSL C - Recreational / Open space']['8 m +']),

          hsld0: Number(item['HSL D - Commercial / Industrial']['0 to <1 m']),
          hsld1: Number(item['HSL D - Commercial / Industrial']['1 to <2 m']),
          hsld2: Number(item['HSL D - Commercial / Industrial']['2 to <4 m']),
          hsld3: Number(item['HSL D - Commercial / Industrial']['4 to <8 m']),
          hsld4: Number(item['HSL D - Commercial / Industrial']['8 m +']),
          Actions: item['Actions'],
        };
      }

      return {
        Chemical: item['Chemical'],
        'Soil Type': item['Soil Type'],
        hslab0: Number(
          item['HSL A&B - Low_high density residential']['0 to <1 m']
        ),
        hslab1: Number(
          item['HSL A&B - Low_high density residential']['1 to <2 m']
        ),
        hslab2: Number(
          item['HSL A&B - Low_high density residential']['2 to <4 m']
        ),
        hslab3: Number(
          item['HSL A&B - Low_high density residential']['4 to <8 m']
        ),
        hslab4: Number(item['HSL A&B - Low_high density residential']['8 m +']),

        hslc0: Number(item['HSL C - Recreational / Open space']['0 to <1 m']),
        hslc1: Number(item['HSL C - Recreational / Open space']['1 to <2 m']),
        hslc2: Number(item['HSL C - Recreational / Open space']['2 to <4 m']),
        hslc3: Number(item['HSL C - Recreational / Open space']['4 to <8 m']),
        hslc4: Number(item['HSL C - Recreational / Open space']['8 m +']),

        hsld0: Number(item['HSL D - Commercial / Industrial']['0 to <1 m']),
        hsld1: Number(item['HSL D - Commercial / Industrial']['1 to <2 m']),
        hsld2: Number(item['HSL D - Commercial / Industrial']['2 to <4 m']),
        hsld3: Number(item['HSL D - Commercial / Industrial']['4 to <8 m']),
        hsld4: Number(item['HSL D - Commercial / Industrial']['8 m +']),
      };
    });

    setTableData(newData);
    setTableHeaderDepth(new Array(depthOf(data[0])).fill(null));

    let newMergedChild = [];
    let newTwoRows = [];
    let idxx = 0;
    let type = 'ab';
    for (const key in data[0]) {
      const val = data[0][key];
      if (isObject(val)) {
        if (idxx == 1) {
          type = 'c';
        } else if (idxx == 2) {
          type = 'd';
        }
        if (key != 'Actions') {
          newTwoRows.push(key);
          let j = 0;
          for (const name in val) {
            newMergedChild.push({ key: `hsl${type}${j}`, title: name });
            j++;
          }
          idxx = idxx + 1;
        }
      }
    }
    setTableHeaderHasChildren(newTwoRows);
    setTableHeader2ndRow(newMergedChild);
  }, [data]);

  const rowspan = (key, indexC, value) => {
    const next = indexC + 1;
    const next2 = indexC + 2;
    // first rowspan
    if (key == 'Chemical' || key == 'Actions') {
      if (
        currentData[next2] &&
        currentData[next][key] == value &&
        currentData[next2][key] == value
      ) {
        return 3;
      }
      if (currentData[next] && currentData[next][key] == value) {
        return 2;
      }
    }

    if (key == 'Username') {
      let a = currentData[indexC]['Chemical'];
      if (
        currentData[next2] &&
        currentData[next][key] == value &&
        currentData[next2][key] == value &&
        currentData[next]['Chemical'] == a &&
        currentData[next2]['Chemical'] == a
      ) {
        return 3;
      }

      if (
        currentData[next] &&
        currentData[next][key] == value &&
        currentData[next]['Chemical'] == a
      ) {
        return 2;
      }
    }
  };

  const hideCol = (key, indexC, value) => {
    const prev = indexC - 1;

    if (key == 'Chemical' || key == 'Actions') {
      if (currentData[prev]) {
        if (currentData[prev][key] == value) {
          return true;
        }
      }
    }

    if (key == 'Username') {
      let a = currentData[indexC]['Chemical'];
      if (currentData[prev]) {
        if (
          currentData[prev][key] == value &&
          currentData[prev]['Chemical'] == a
        ) {
          return true;
        }
      }
    }
  };

  return (
    <div className="flex flex-col space-y-4 p-0">
      <table className="w-full min-w-full inline-table text-sm rounded overflow-auto border-collapse">
        {/*Table header*/}
        <thead className={`font-semibold ${headerBgColor} ${headerTextColor}`}>
          {tableHeaderDepth.map((item, index) => (
            <tr
              key={index}
              className="text-left rounded-r-lg border-b-2 border-xviolet sm:border-gray-200 sm:mb-0"
            >
              {index === 0 &&
                Object.entries(data[0]).map(([item, children], index) => (
                  <th
                    key={index}
                    colSpan={
                      tableHeaderHasChildren.find((x) => x === item)
                        ? Object.keys(children).length
                        : 1
                    }
                    rowSpan={
                      tableHeaderHasChildren.find((x) => x === item) ? 1 : 2
                    }
                    className="p-3 border border-white font-semibold"
                  >
                    <button
                      type="button"
                      onClick={() => {
                        if (item == 'HSL A&B - Low_high density residential') {
                          setSortedField([
                            'hslab0',
                            'hslab1',
                            'hslab2',
                            'hslab3',
                            'hslab4',
                          ]);
                        } else if (
                          item == 'HSL C - Recreational / Open space'
                        ) {
                          setSortedField([
                            'hslc0',
                            'hslc1',
                            'hslc2',
                            'hslc3',
                            'hslc4',
                          ]);
                        } else if (item == 'HSL D - Commercial / Industrial') {
                          setSortedField([
                            'hsld0',
                            'hsld1',
                            'hsld2',
                            'hsld3',
                            'hsld4',
                          ]);
                        } else {
                          setSortedField(item);
                        }
                        setAscending(!ascending);
                      }}
                      className="flex flex-row items-center space-x-1"
                    >
                      <span
                        className={classNames(
                          {
                            'font-bold': () => {
                              if (
                                item == 'HSL A&B - Low_high density residential'
                              ) {
                                return sortedField[0] == 'hslab0';
                              } else if (
                                item == 'HSL C - Recreational / Open space'
                              ) {
                                return sortedField[0] == 'hslc0';
                              } else if (
                                item == 'HSL D - Commercial / Industrial'
                              ) {
                                return sortedField[0] == 'hsld0';
                              } else {
                                return item === sortedField;
                              }
                            },
                          },
                          headerText
                        )}
                      >
                        {item}
                      </span>
                      {item === sortedField && ascending && <b>&darr;</b>}
                      {item === sortedField && !ascending && <b>&uarr;</b>}

                      {item === 'HSL A&B - Low_high density residential' &&
                        sortedField &&
                        sortedField[0] == 'hslab0' &&
                        ascending && <b>&darr;</b>}
                      {item === 'HSL A&B - Low_high density residential' &&
                        sortedField &&
                        sortedField[0] == 'hslab0' &&
                        !ascending && <b>&uarr;</b>}

                      {item === 'HSL C - Recreational / Open space' &&
                        sortedField &&
                        sortedField[0] == 'hslc0' &&
                        ascending && <b>&darr;</b>}
                      {item === 'HSL C - Recreational / Open space' &&
                        sortedField &&
                        sortedField[0] == 'hslc0' &&
                        !ascending && <b>&uarr;</b>}

                      {item === 'HSL D - Commercial / Industrial' &&
                        sortedField &&
                        sortedField[0] == 'hsld0' &&
                        ascending && <b>&darr;</b>}
                      {item === 'HSL D - Commercial / Industrial' &&
                        sortedField &&
                        sortedField[0] == 'hsld0' &&
                        !ascending && <b>&uarr;</b>}
                    </button>
                  </th>
                ))}
              {index > 0 &&
                tableHeader2ndRow.map((item, index) => (
                  <th
                    key={index}
                    className="p-3 border border-white font-semibold"
                  >
                    <button
                      type="button"
                      onClick={() => {
                        setSortedField(item.key);
                        setAscending(!ascending);
                      }}
                      className="flex flex-row items-center space-x-1"
                    >
                      {item.key === sortedField && ascending && <b>&darr;</b>}
                      {item.key === sortedField && !ascending && <b>&uarr;</b>}

                      <span
                        className={classNames(
                          { 'font-bold': item.key === sortedField },
                          headerText,
                          'font-semibold'
                        )}
                      >
                        {item.title}
                      </span>
                    </button>
                  </th>
                ))}
            </tr>
          ))}
        </thead>

        <tbody className="flex-1 sm:flex-none text-gray-800">
          {depthOf(currentData[0]) === 1 &&
            currentData.map((item, indexC) => (
              <tr
                key={indexC}
                className="text-left rounded-r-lg border-b-2 border-xviolet sm:border-gray-200 sm:mb-0"
              >
                {Object.entries(item).map(([key, value], index) => (
                  <td
                    key={index}
                    className={classNames(
                      {
                        hidden: hideCol(key, indexC, value),
                      },
                      'p-3 border border-white hover:bg-gray-100'
                    )}
                    rowSpan={rowspan(key, indexC, value)}
                  >
                    {value == 0 ? 'NL' : value}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>

      {/*Table pagination*/}
      {!hidePagination && (
        <div className="flex flex-row self-end content-center text-xs">
          <span className="self-center">Rows per page:</span>
          <span className="self-center">
            <SelectTable
              onChange={(e) => setRowPerPages(e.target.value)}
              data={[
                { value: 5, label: '5' },
                { value: 10, label: '10' },
                { value: 20, label: '20' },
                { value: data.length, label: 'All' },
              ]}
              value={String(rowPerPages)}
            />
          </span>
          <span className="self-center">
            {rowPerPages * (currentPage - 1) + 1} -{' '}
            {Math.min(rowPerPages * currentPage, tableData.length)} of{' '}
            {tableData.length}
          </span>
          <button
            type="button"
            disabled={currentPage === 1}
            onClick={() => goToPage(-1)}
            className="self-center rounded-full hover:bg-gray-100 disabled:opacity-30 disabled:pointer-events-none"
          >
            <span className="text-gray-600 pe-7s-angle-left text-3xl font-semibold" />
          </button>
          <button
            type="button"
            disabled={currentPage === totalPages}
            onClick={() => goToPage(1)}
            className="self-center rounded-full hover:bg-gray-100 disabled:opacity-30 disabled:pointer-events-none"
          >
            <span className="text-gray-600 pe-7s-angle-right text-3xl font-semibold" />
          </button>
        </div>
      )}
    </div>
  );
};
