import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Button, Card, PageTitle } from '../../components';
import { SessionUtils, UserService } from '../../utils';
import { TableVapour } from '../../components/TableVapour';

import { connect } from 'react-redux';
import { RESOURCE_NAMES } from '../../store/reducers/resources';
import {
  getAllData as _getAlldata,
  deleteData as _deleteData,
} from '../../store/actions/resources';
import { getResource } from '../../store/selectors/resources';

const transform = (arrData) => {
  return arrData.map((item) => {
    const { Username, name, sand, silt, clay, Actions } = item;

    return [
      {
        Username,
        Chemical: name,
        'Soil Type': 'Sand',
        'HSL A&B - Low_high density residential': {
          '0 to <1 m': sand.ab[0],
          '1 to <2 m': sand.ab[1],
          '2 to <4 m': sand.ab[2],
          '4 to <8 m': sand.ab[3],
          '8 m +': sand.ab[4],
        },
        'HSL C - Recreational / Open space': {
          '0 to <1 m': sand.c[0],
          '1 to <2 m': sand.c[1],
          '2 to <4 m': sand.c[2],
          '4 to <8 m': sand.c[3],
          '8 m +': sand.c[4],
        },
        'HSL D - Commercial / Industrial': {
          '0 to <1 m': sand.d[0],
          '1 to <2 m': sand.d[1],
          '2 to <4 m': sand.d[2],
          '4 to <8 m': sand.d[3],
          '8 m +': sand.d[4],
        },
        Actions,
      },
      {
        Username,
        Chemical: name,
        'Soil Type': 'Silt',
        'HSL A&B - Low_high density residential': {
          '0 to <1 m': silt.ab[0],
          '1 to <2 m': silt.ab[1],
          '2 to <4 m': silt.ab[2],
          '4 to <8 m': silt.ab[3],
          '8 m +': silt.ab[4],
        },
        'HSL C - Recreational / Open space': {
          '0 to <1 m': silt.c[0],
          '1 to <2 m': silt.c[1],
          '2 to <4 m': silt.c[2],
          '4 to <8 m': silt.c[3],
          '8 m +': silt.c[4],
        },
        'HSL D - Commercial / Industrial': {
          '0 to <1 m': silt.d[0],
          '1 to <2 m': silt.d[1],
          '2 to <4 m': silt.d[2],
          '4 to <8 m': silt.d[3],
          '8 m +': silt.d[4],
        },
        Actions,
      },
      {
        Username,
        Chemical: name,
        'Soil Type': 'Clay',
        'HSL A&B - Low_high density residential': {
          '0 to <1 m': clay.ab[0],
          '1 to <2 m': clay.ab[1],
          '2 to <4 m': clay.ab[2],
          '4 to <8 m': clay.ab[3],
          '8 m +': clay.ab[4],
        },
        'HSL C - Recreational / Open space': {
          '0 to <1 m': clay.c[0],
          '1 to <2 m': clay.c[1],
          '2 to <4 m': clay.c[2],
          '4 to <8 m': clay.c[3],
          '8 m +': clay.c[4],
        },
        'HSL D - Commercial / Industrial': {
          '0 to <1 m': clay.d[0],
          '1 to <2 m': clay.d[1],
          '2 to <4 m': clay.d[2],
          '4 to <8 m': clay.d[3],
          '8 m +': clay.d[4],
        },
        Actions,
      },
    ];
  });
};

const Vapour = ({
  getAllData,
  deleteData,
  vapourSoil,
  vapourGroundwater,
  vapourSoilVapour,
}) => {
  const [vapourSoilTable, setVapourSoilTable] = useState([]);
  const [vapourGroundwaterTable, setVapourGroundwaterTable] = useState([]);
  const [vapourSoilVapourTable, setVapourSoilVapourTable] = useState([]);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    getAllData(RESOURCE_NAMES['VAPOUR-INTRUSION-SOILS']);
    getAllData(RESOURCE_NAMES['VAPOUR-INTRUSION-GROUNDWATERS']);
    getAllData(RESOURCE_NAMES['VAPOUR-INTRUSION-SOIL-VAPOURS']);
    UserService.getAllUsers().then((res) => {
      setUserData(res);
    });
  }, []);

  useEffect(() => {
    if (vapourSoil && userData) {
      let newArr = _.orderBy(_.values(vapourSoil), ['created_at'], ['desc'])
        .filter((item) => {
          if (SessionUtils.getUserData().role === 'admin') {
            return item.status === 'active';
          } else {
            return (
              item.status === 'active' &&
              (item.userId === SessionUtils.getUserData().id ||
                item.userId === 1)
            );
          }
        })
        .map((item) => {
          const actions =
            SessionUtils.getUserData().role === 'admin' ||
            item.userId === SessionUtils.getUserData().id ? (
              <div className="flex flex-row flex-grow space-x-2">
                <Link
                  to={`/vapour/soil/${item.id}`}
                  className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-blue-400 text-blue-400 hover:bg-blue-500 hover:text-white focus:outline-none"
                >
                  <div className="self-center align-middle font-normal text-lg pe-7s-look transition duration-200" />
                </Link>
                <button
                  onClick={() => handleDelete(item.id, 'soil')}
                  className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-red-400 text-red-400 hover:bg-red-600 hover:text-white focus:outline-none"
                >
                  <div className="self-center align-middle font-normal text-lg pe-7s-trash transition duration-200" />
                </button>
              </div>
            ) : (
              <Link
                to={`/vapour/soil/${item.id}`}
                className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-blue-400 text-blue-400 hover:bg-blue-500 hover:text-white focus:outline-none"
              >
                <div className="self-center align-middle font-normal text-lg pe-7s-look transition duration-200" />
              </Link>
            );

          let username = '';
          if (SessionUtils.getUserData().role === 'admin') {
            username = userData.find((user) => user.id === item.userId).name;
          } else {
            if (SessionUtils.getUserData().id === item.userId) {
              username = SessionUtils.getUserData().name;
            } else {
              username = 'Administrator';
            }
          }

          return {
            ...item,
            Username: username,
            Actions: actions,
          };
        });

      let newVapourSoil = transform(newArr).flat();
      setVapourSoilTable(newVapourSoil);
    }
  }, [vapourSoil, userData]);

  useEffect(() => {
    if (vapourGroundwater && userData) {
      let newArr = _.orderBy(
        _.values(vapourGroundwater),
        ['created_at'],
        ['desc']
      )
        .filter((item) => {
          if (SessionUtils.getUserData().role === 'admin') {
            return item.status === 'active';
          } else {
            return (
              item.status === 'active' &&
              (item.userId === SessionUtils.getUserData().id ||
                item.userId === 1)
            );
          }
        })
        .map((item) => {
          const actions =
            SessionUtils.getUserData().role === 'admin' ||
            item.userId === SessionUtils.getUserData().id ? (
              <div className="flex flex-row flex-grow space-x-2">
                <Link
                  to={`/vapour/groundwater/${item.id}`}
                  className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-blue-400 text-blue-400 hover:bg-blue-500 hover:text-white focus:outline-none"
                >
                  <div className="self-center align-middle font-normal text-lg pe-7s-look transition duration-200" />
                </Link>
                <button
                  onClick={() => handleDelete(item.id, 'groundwater')}
                  className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-red-400 text-red-400 hover:bg-red-600 hover:text-white focus:outline-none"
                >
                  <div className="self-center align-middle font-normal text-lg pe-7s-trash transition duration-200" />
                </button>
              </div>
            ) : (
              <Link
                to={`/vapour/groundwater/${item.id}`}
                className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-blue-400 text-blue-400 hover:bg-blue-500 hover:text-white focus:outline-none"
              >
                <div className="self-center align-middle font-normal text-lg pe-7s-look transition duration-200" />
              </Link>
            );

          let username = '';
          if (SessionUtils.getUserData().role === 'admin') {
            username = userData.find((user) => user.id === item.userId).name;
          } else {
            if (SessionUtils.getUserData().id === item.userId) {
              username = SessionUtils.getUserData().name;
            } else {
              username = 'Administrator';
            }
          }

          return {
            ...item,
            Username: username,
            Actions: actions,
          };
        });

      let newVapourGroundwater = transform(newArr).flat();
      setVapourGroundwaterTable(newVapourGroundwater);
    }
  }, [vapourGroundwater, userData]);

  useEffect(() => {
    if (vapourSoilVapour && userData) {
      let newArr = _.orderBy(
        _.values(vapourSoilVapour),
        ['created_at'],
        ['desc']
      )
        .filter((item) => {
          if (SessionUtils.getUserData().role === 'admin') {
            return item.status === 'active';
          } else {
            return (
              item.status === 'active' &&
              (item.userId === SessionUtils.getUserData().id ||
                item.userId === 1)
            );
          }
        })
        .map((item) => {
          const actions =
            SessionUtils.getUserData().role === 'admin' ||
            item.userId === SessionUtils.getUserData().id ? (
              <div className="flex flex-row flex-grow space-x-2">
                <Link
                  to={`/vapour/soil-vapour/${item.id}`}
                  className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-blue-400 text-blue-400 hover:bg-blue-500 hover:text-white focus:outline-none"
                >
                  <div className="self-center align-middle font-normal text-lg pe-7s-look transition duration-200" />
                </Link>
                <button
                  onClick={() => handleDelete(item.id, 'soil-vapour')}
                  className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-red-400 text-red-400 hover:bg-red-600 hover:text-white focus:outline-none"
                >
                  <div className="self-center align-middle font-normal text-lg pe-7s-trash transition duration-200" />
                </button>
              </div>
            ) : (
              <Link
                to={`/vapour/soil-vapour/${item.id}`}
                className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-blue-400 text-blue-400 hover:bg-blue-500 hover:text-white focus:outline-none"
              >
                <div className="self-center align-middle font-normal text-lg pe-7s-look transition duration-200" />
              </Link>
            );

          let username = '';
          if (SessionUtils.getUserData().role === 'admin') {
            username = userData.find((user) => user.id === item.userId).name;
          } else {
            if (SessionUtils.getUserData().id === item.userId) {
              username = SessionUtils.getUserData().name;
            } else {
              username = 'Administrator';
            }
          }

          return {
            ...item,
            Username: username,
            Actions: actions,
          };
        });

      let newVapourSoilVapour = transform(newArr).flat();
      setVapourSoilVapourTable(newVapourSoilVapour);
    }
  }, [vapourSoilVapour]);

  /** Function for deleting data */
  const handleDelete = async (dataId, vapourType) => {
    const result = await Swal.fire({
      title: 'Delete this data?',
      text: 'All of this related data will be deleted permanently',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    });
    if (result.value) {
      try {
        if (vapourType === 'soil') {
          deleteData(RESOURCE_NAMES['VAPOUR-INTRUSION-SOILS'], dataId);
        } else if (vapourType === 'groundwater') {
          deleteData(RESOURCE_NAMES['VAPOUR-INTRUSION-GROUNDWATERS'], dataId);
        } else if (vapourType === 'soil-vapour') {
          deleteData(RESOURCE_NAMES['VAPOUR-INTRUSION-SOIL-VAPOURS'], dataId);
        }
      } catch (err) {
        await Swal.fire({
          title: 'An Error occured!',
          icon: 'error',
        });
      }
    }
  };

  return (
    <div>
      <PageTitle
        title="Vapour intrusion"
        description="The current list of Volatile Organic Compounds (VOCs)."
        className="bi-wind opacity-90"
      />
      <div className="flex flex-col px-6 pt-2 pb-6 space-y-8">
        {/* Vapour Soil */}
        <Card
          title={['Soil Vapour HSLs for Vapour Intrusion (mg/kg)']}
          uppercase={false}
          right={
            <Button
              to="/vapour/soil/new"
              theme="success"
              text="Add Contaminants"
            />
          }
        >
          <TableVapour
            data={vapourSoilTable}
            show={10}
            headerBgColor="bg-xgreen"
            headerTextColor="text-white"
            headerText="capitalize-first"
          />
        </Card>

        {/* Vapour Groundwater */}
        <Card
          title={['Groundwater HSLs for Vapour Intrusion (mg/L)']}
          uppercase={false}
          right={
            <Button
              to="/vapour/groundwater/new"
              theme="success"
              text="Add Contaminants"
            />
          }
        >
          <TableVapour
            data={vapourGroundwaterTable}
            show={10}
            headerBgColor="bg-xgreen"
            headerTextColor="text-white"
            headerText="capitalize-first"
          />
        </Card>

        {/* Vapour Soil Vapour*/}
        <Card
          title={[
            'Soil Vapour HSLs for Vapour Intrusion (mg/m',
            <sup>3</sup>,
            ')',
          ]}
          uppercase={false}
          right={
            <Button
              to="/vapour/soil-vapour/new"
              theme="success"
              text="Add Contaminants"
            />
          }
        >
          <TableVapour
            data={vapourSoilVapourTable}
            show={10}
            headerBgColor="bg-xgreen"
            headerTextColor="text-white"
            headerText="capitalize-first"
          />
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  vapourSoil: getResource(RESOURCE_NAMES['VAPOUR-INTRUSION-SOILS'])(state),
  vapourGroundwater: getResource(
    RESOURCE_NAMES['VAPOUR-INTRUSION-GROUNDWATERS']
  )(state),
  vapourSoilVapour: getResource(
    RESOURCE_NAMES['VAPOUR-INTRUSION-SOIL-VAPOURS']
  )(state),
});

export default connect(mapStateToProps, {
  getAllData: _getAlldata,
  deleteData: _deleteData,
})(Vapour);
