import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import jwtDecode from 'jwt-decode';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

import { SessionUtils, UserService } from '../../utils';

/**
 * Component for Authentication Page.
 *
 * Contains spinner for loading indicator.
 *
 * @component
 */
export const Auth = () => {
  /** State for storing user verification status */
  const [valid, setValid] = useState(false);

  /** State for storing token extracted from URL */
  const token = useParams().token;

  /** Function for checking authentication status. If not authenticated, redirect to riskCARE */
  const handleLogin = async () => {
    if (await SessionUtils.isAuthed()) {
      setValid(true);
    } else {
      window.location.href = `${process.env.MIX_LOGIN_ROUTE}/?callback=${process.env.MIX_APP_URL}`;
    }
  };

  /** Function for handling token checking from riskCARE login callback */
  const handleToken = async () => {
    const tokenDecrypted = await AES.decrypt(
      token,
      process.env.MIX_JWT_SECRET
    ).toString(Utf8);
    const verifiedData = await UserService.verifyGet(tokenDecrypted);
    const parsedJwt = await jwtDecode(tokenDecrypted);

    if (verifiedData) {
      const userData = verifiedData;
      const exp = await parsedJwt.exp;
      SessionUtils.setUserData(tokenDecrypted, userData, exp);
      window.location.href = process.env.MIX_APP_URL;
    } else {
      SessionUtils.clearSession();
      alert('Authentication is not permitted!');
    }
  };

  /** Effect to call function based on token parameter existence */
  useEffect(() => {
    if (token) {
      handleToken();
    } else {
      handleLogin();
    }
  }, []);

  return (
    <div className="flex flex-col flex-grow w-full h-full content-center justify-center px-6 py-12">
      {valid && <Redirect to="/" />}
      <div className="mx-auto loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-48 w-48" />
    </div>
  );
};
