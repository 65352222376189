import React from 'react';
import classNames from 'classnames';

/**
 * Component for Select/Dropdown Menu UI.
 *
 * @component
 */
export const Select = ({
  data,
  placeholder,
  disablePlaceholder = true,
  fullWidth = false,
  customClass,
  disabled = false,
  ...rest
}) => (
  <select
    className={classNames(
      {
        'w-full': fullWidth,
      },
      `px-2 py-1 border rounded-md border-gray-300 focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 ${customClass}`
    )}
    disabled={disabled}
    {...rest}
  >
    {placeholder && (
      <option value="" disabled={disablePlaceholder} selected>
        {placeholder}
      </option>
    )}
    {data.map((item, index) => (
      <option key={index} value={item.value}>
        {item.label}
      </option>
    ))}
  </select>
);
