import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Radar } from 'react-chartjs-2';
import {
  Button,
  Card,
  PageTitle,
  Select,
  Stepper,
  Table,
  TableFileProfile,
} from '../../components';
import { APIService, SessionUtils } from '../../utils';
import {
  groundwaterContaminants,
  soilContaminants,
} from '../Infocare/constants';
import {
  groundwaterType,
  landUsesOption,
  vapourSampleDepth,
  vapourSampleType,
  vapourSoilTexture,
} from './constants';
import { useParams } from 'react-router';

import { connect } from 'react-redux';
import { RESOURCE_NAMES } from '../../store/reducers/resources';
import {
  getDataById as _getDataById,
  addData as _addData,
  updateData as _updateData,
  deleteData as _deleteData,
} from '../../store/actions/resources';
import { getResource } from '../../store/selectors/resources';
import _ from 'lodash';

const radarOptions = {
  scale: {
    ticks: { beginAtZero: true },
  },
};

const FileForm = ({ getDataById, addData, updateData, deleteData, files }) => {
  const { dataId } = useParams();
  const [allSoil, setAllSoil] = useState([]);
  const [allGroundwater, setAllGroundwater] = useState([]);
  const [allVapourSoil, setAllVapourSoil] = useState([]);
  const [allVapourGroundwater, setAllVapourGroundwater] = useState([]);
  const [allVapourSoilVapour, setAllVapourSoilVapour] = useState([]);

  /** State for storing substance dropdown list */
  const [soilSubList, setSoilSubList] = useState([]);
  const [groundwaterSubList, setGroundwaterSubList] = useState([]);
  const [vapourSubList, setVapourSubList] = useState([]);

  /** State for storing stepper completed list */
  const [completedList, setCompletedList] = useState([]);

  /** State for the form input value */
  const [siteName, setSiteName] = useState('');
  const [siteDesc, setSiteDesc] = useState('');
  const [landUse, setLandUse] = useState('');

  const [soilGroupOpt, setSoilGroupOpt] = useState('');
  const [soilSubOpt, setSoilSubOpt] = useState('');
  const [soilCon, setSoilCon] = useState('');

  const [groundwaterTypeOpt, setGroundwaterTypeOpt] = useState('');
  const [groundwaterGroupOpt, setGroundwaterGroupOpt] = useState('');
  const [groundwaterSubOpt, setGroundwaterSubOpt] = useState('');
  const [groundwaterCon, setGroundwaterCon] = useState('');

  const [vapourTextureOpt, setVapourTextureOpt] = useState('');
  const [vapourDepthOpt, setVapourDepthOpt] = useState('');
  const [vapourTypeOpt, setVapourTypeOpt] = useState('');
  const [vapourSubOpt, setVapourSubOpt] = useState('');
  const [vapourCon, setVapourCon] = useState('');
  const [vapourConUnit, setVapourConUnit] = useState('');

  /** State for the submisssion substance data array */
  const [soilData, setSoilData] = useState([]);
  const [groundwaterData, setGroundwaterData] = useState([]);
  const [vapourData, setVapourData] = useState([]);

  const [reviewed, setReviewed] = useState(false);
  const [tempReview, setTempReview] = useState({});
  const [resultData, setResultData] = useState([]);
  const [soilProfileData, setSoilProfileData] = useState([]);
  const [groundwaterProfileData, setGroundwaterProfileData] = useState([]);
  const [vapourProfileData, setVapourProfileData] = useState([]);

  const [soilTotalHQ, setSoilTotalHQ] = useState('');
  const [groundwaterTotalHQ, setGroundwaterTotalHQ] = useState('');
  const [vapourTotalHQ, setVapourTotalHQ] = useState('');

  const [resGroundwaterType, setResGroundwaterType] = useState('');
  const [resSoilTexture, setResSoilTexture] = useState('');

  const [radarData, setRadarData] = useState({
    labels: ['Soil HQ', 'Groundwater HQ', 'Vapour Intrusion HQ', 'Total HQ'],
  });

  const [error, setError] = useState('');

  const [isEdit, setIsEdit] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [loading, setLoading] = useState(true);

  const [isReplace, setIsReplace] = useState(false);
  const [replaceId, setReplaceId] = useState();

  useEffect(() => {
    if (dataId) {
      setIsEdit(true);
      setReviewed(true);
      setIsReplace(false);
      getDataById(RESOURCE_NAMES['FILES'], dataId).then(() => {
        setLoading(false);
      });
    } else {
      setIsEdit(false);
      setReviewed(false);
      setIsReplace(false);
      setLoading(false);
      handleReset();
    }
  }, [dataId]);

  // Assign file content value to the input form for the edit page
  useEffect(() => {
    if (
      dataId &&
      !loading &&
      files[dataId] &&
      files[dataId].soil &&
      files[dataId].groundWater &&
      files[dataId].vapour &&
      files[dataId].total
    ) {
      let arr = files[dataId];

      setSiteName(arr.name);
      setSiteDesc(arr.description ? arr.description : '');
      setLandUse(arr.landUse);

      setGroundwaterTypeOpt(arr.groundWater.type);
      setVapourTextureOpt(arr.vapour.soilTexture);

      assignData(arr);
    }
  }, [dataId, files, loading]);

  // get data for the form dropdown
  useEffect(() => {
    APIService.get.soil.all().then((reply) => {
      setAllSoil(reply.data);
    });
    APIService.get.groundwater.all().then((reply) => {
      setAllGroundwater(reply.data);
    });
    APIService.get.vapourSoil.all().then((reply) => {
      setAllVapourSoil(reply.data);
    });
    APIService.get.vapourGroundWater.all().then((reply) => {
      setAllVapourGroundwater(reply.data);
    });
    APIService.get.vapourSoilVapour.all().then((reply) => {
      setAllVapourSoilVapour(reply.data);
    });
  }, []);

  // update completed stepper
  useEffect(() => {
    if (!reviewed) {
      let c = [];
      if (siteName.trim() !== '' && siteDesc.trim() !== '' && landUse !== '') {
        c.push('siteProfile');
      }
      if (soilData.length > 0) {
        c.push('soilContaminant');
      }
      if (groundwaterData.length > 0) {
        c.push('groundwaterContaminant');
      }
      if (vapourData.length > 0) {
        c.push('vapourIntrusion');
      }
      setCompletedList(c);
    } else {
      setCompletedList([
        'siteProfile',
        'soilContaminant',
        'groundwaterContaminant',
        'vapourIntrusion',
        'result',
      ]);
    }
  }, [
    siteName,
    siteDesc,
    landUse,
    soilData,
    groundwaterData,
    vapourData,
    reviewed,
  ]);

  const assignData = (arr) => {
    setTempReview(arr);
    setSoilData(arr.soil.data);
    setGroundwaterData(arr.groundWater.data);
    setVapourData(arr.vapour.data);

    // set the review and result table data
    const a = [
      {
        '': arr.name ? arr.name : siteName.trim(),
        'Soil HQ': _.round(arr.soil.totalHQ, 2),
        'Groundwater HQ': _.round(arr.groundWater.totalHQ, 2),
        'Vapour Intrusion': _.round(arr.vapour.totalHQ, 2),
        'Total HQ': _.round(arr.total.totalHQ, 2),
      },
      {
        '': 'AVERAGE',
        'Soil HQ': _.round(arr.soil.avgHQ, 2),
        'Groundwater HQ': _.round(arr.groundWater.avgHQ, 2),
        'Vapour Intrusion': _.round(arr.vapour.avgHQ, 2),
        'Total HQ': _.round(arr.total.avgHQ, 2),
      },
      {
        '': 'MAX',
        'Soil HQ': _.round(arr.soil.maxHQ, 2),
        'Groundwater HQ': _.round(arr.groundWater.maxHQ, 2),
        'Vapour Intrusion': _.round(arr.vapour.maxHQ, 2),
        'Total HQ': _.round(arr.total.maxHQ, 2),
      },
    ];
    setResultData(a);

    // set the radar data
    const rd = [
      {
        label: arr.name ? arr.name : siteName.trim(),
        data: [
          _.round(arr.soil.totalHQ, 2),
          _.round(arr.groundWater.totalHQ, 2),
          _.round(arr.vapour.totalHQ, 2),
          _.round(arr.total.totalHQ, 2),
        ],
        backgroundColor: 'rgba(229, 228, 229, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: 'Max',
        data: [
          _.round(arr.soil.maxHQ, 2),
          _.round(arr.groundWater.maxHQ, 2),
          _.round(arr.vapour.maxHQ, 2),
          _.round(arr.total.maxHQ, 2),
        ],
        backgroundColor: 'rgba(229, 228, 229, 0.2)',
        borderColor: 'rgba(37, 99, 235, 1)',
        borderWidth: 1,
      },
      {
        label: 'Average',
        data: [
          _.round(arr.soil.avgHQ, 2),
          _.round(arr.groundWater.avgHQ, 2),
          _.round(arr.vapour.avgHQ, 2),
          _.round(arr.total.avgHQ, 2),
        ],
        backgroundColor: 'rgba(229, 228, 229, 0.2)',
        borderColor: 'rgba(110, 231, 183, 1)',
        borderWidth: 1,
      },
    ];

    setRadarData((oldArr) => {
      return {
        ...oldArr,
        datasets: rd,
      };
    });

    // set the soil profile data
    const s = arr.soil.data.map((item, index) => {
      return {
        No: index + 1,
        Contaminant: item.contaminants,
        'Concentrations (mg/Kg)': Number(item.concentrations),
        'Hazard Quotient': _.round(item['HQ'], 2),
      };
    });
    setSoilProfileData(s);
    setSoilTotalHQ(_.round(arr.soil.totalHQ, 2));

    // set the groundwater profile data
    const g = arr.groundWater.data.map((item, index) => {
      return {
        No: index + 1,
        Contaminant: item.contaminants,
        'Concentrations (mg/L)': Number(item.concentrations),
        'Hazard Quotient': _.round(item['HQ'], 2),
      };
    });
    setGroundwaterProfileData(g);
    setGroundwaterTotalHQ(_.round(arr.groundWater.totalHQ, 2));
    setResGroundwaterType(arr.groundWater.type);

    // set the vapour profile data
    const v = arr.vapour.data.map((item, index) => {
      return {
        No: index + 1,
        Contaminant: item.contaminants,
        Concentrations: Number(item.concentrations),
        Unit: vapourSampleType.find((dt) => dt.id == item.sampleType).unit,
        'Sample Type': vapourSampleType.find((dt) => dt.id == item.sampleType)
          .label,
        'Sample Depth': vapourSampleDepth.find(
          (dt) => dt.id == item.sampleDepth
        ).label,
        'Hazard Quotient': _.round(item['HQ'], 2),
      };
    });
    setVapourProfileData(v);
    setVapourTotalHQ(_.round(arr.vapour.totalHQ, 2));
    setResSoilTexture(arr.vapour.soilTexture);
  };

  /** Function for deleting data from table */
  const handleDelete = async (index, dataArr, setDataArr) => {
    const result = await Swal.fire({
      title: 'Delete this data?',
      text: 'All of this related data will be deleted permanently',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    });
    if (result.value) {
      try {
        // assigning the list to temp variable
        const temp = [...dataArr];
        // removing the element using splice
        temp.splice(index, 1);
        // updating the list
        setDataArr(temp);
      } catch (err) {
        await Swal.fire({
          title: 'An Error occured!',
          icon: 'error',
        });
      }
    }
  };

  const handleNextStep = () => {
    const dt = {
      landUse: landUse,
      soil: {
        data: soilData,
      },
      groundWater: {
        data: groundwaterData,
        type: groundwaterTypeOpt,
      },
      vapour: {
        data: vapourData,
        soilTexture: vapourTextureOpt,
      },
    };
    APIService.get.files.all().then((reply) => {
      if (
        (!isEdit &&
          !reply.data.some(
            (item) =>
              item.name.trim().toLowerCase() == siteName.trim().toLowerCase() &&
              item.status == 'active'
          )) ||
        (isEdit &&
          !reply.data.some(
            (item) =>
              item.name.trim().toLowerCase() !==
                files[dataId].name.toLowerCase() &&
              item.name.trim().toLowerCase() == siteName.trim().toLowerCase() &&
              item.status == 'active'
          ))
      ) {
        setError('');
        APIService.post.files.review(dt).then((reply) => {
          setReviewed(true);
          assignData(reply.data);
          setShowSubmit(true);
          setIsReplace(false);
        });
      } else {
        // if there's already file with the same name
        let repId;
        if (!isEdit) {
          repId = reply.data.find(
            (item) =>
              item.name.trim().toLowerCase() == siteName.trim().toLowerCase() &&
              item.status == 'active'
          ).id;
        }
        if (isEdit) {
          repId = reply.data.find(
            (item) =>
              item.name.trim().toLowerCase() !==
                files[dataId].name.toLowerCase() &&
              item.name.trim().toLowerCase() == siteName.trim().toLowerCase() &&
              item.status == 'active'
          ).id;
        }
        Swal.fire({
          title: `File with the name ${siteName} already exists!`,
          text: 'You have to replace the existing file first OR rename the current file.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#309e1d',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Rename this file',
          confirmButtonText: 'Replace the existing file',
          allowOutsideClick: false,
        }).then((result) => {
          if (result.value) {
            APIService.post.files.review(dt).then((reply) => {
              setReviewed(true);
              assignData(reply.data);
              setShowSubmit(true);
              setIsReplace(true);
              setReplaceId(repId);
            });
          } else {
            // if user choose to rename
            setIsReplace(false);
          }
        });
      }
    });
  };

  const handleAdd = () => {
    let tempType = '';
    let tempTexture = '';
    if (tempReview.groundWater.type) {
      tempType = tempReview.groundWater.type;
    }
    if (tempReview.vapour.soilTexture) {
      tempTexture = tempReview.vapour.soilTexture;
    }

    const dtFormat = {
      name: siteName.trim(),
      description: siteDesc,
      landUse: landUse,
      soil: {
        data: tempReview.soil.data,
        totalHQ: tempReview.soil.totalHQ,
      },
      groundWater: {
        data: tempReview.groundWater.data,
        type: tempType,
        totalHQ: tempReview.groundWater.totalHQ,
      },
      vapour: {
        data: tempReview.vapour.data,
        soilTexture: tempTexture,
        totalHQ: tempReview.vapour.totalHQ,
      },
    };
    Swal.fire({
      title: 'Check your data',
      text: 'Review your data before submission.',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        if (!isReplace) {
          return addData(dtFormat)
            .then((res) => {
              return { isConfirmed: true };
            })
            .catch((error) => {
              Swal.fire({
                title: 'An Error occured!',
                icon: 'error',
                confirmButtonColor: '#28a745',
              });
            });
        } else {
          return updateData(replaceId, dtFormat)
            .then((res) => {
              return { isConfirmed: true };
            })
            .catch((error) => {
              Swal.fire({
                title: 'An Error occured!',
                icon: 'error',
                confirmButtonColor: '#28a745',
              });
            });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
      backdrop: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await Swal.fire({
          icon: 'success',
          title: !isReplace
            ? 'File data successfully created!'
            : 'File data successfully updated!',
          confirmButtonColor: '#28a745',
        });
        window.location.href = `${process.env.MIX_APP_URL}/result`;
      }
    });
  };

  const handleReset = () => {
    setSiteName('');
    setLandUse('');
    setSiteDesc('');
    setSoilGroupOpt('');
    setSoilSubOpt('');
    setSoilCon('');
    setGroundwaterTypeOpt('');
    setGroundwaterGroupOpt('');
    setGroundwaterSubOpt('');
    setVapourTextureOpt('');
    setVapourTypeOpt('');
    setVapourDepthOpt('');
    setVapourSubOpt('');
    setVapourCon('');
    setSoilData([]);
    setGroundwaterData([]);
    setVapourData([]);
  };

  /** Function for deleting data */
  const handleDeleteData = async () => {
    const result = await Swal.fire({
      title: 'Delete this data?',
      text: 'All of this related data will be deleted permanently',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
    });
    if (result.value) {
      try {
        await deleteData(RESOURCE_NAMES['FILES'], dataId);
        window.location.href = `${process.env.MIX_APP_URL}/result`;
      } catch (err) {
        await Swal.fire({
          title: 'An Error occured!',
          icon: 'error',
          confirmButtonColor: '#28a745',
        });
      }
    }
  };

  const handleUpdate = () => {
    let tempType = '';
    let tempTexture = '';
    if (tempReview.groundWater.type) {
      tempType = tempReview.groundWater.type;
    }
    if (tempReview.vapour.soilTexture) {
      tempTexture = tempReview.vapour.soilTexture;
    }

    const dtFormat = {
      name: siteName.trim(),
      description: siteDesc,
      landUse: landUse,
      soil: {
        data: tempReview.soil.data,
        totalHQ: tempReview.soil.totalHQ,
      },
      groundWater: {
        data: tempReview.groundWater.data,
        type: tempType,
        totalHQ: tempReview.groundWater.totalHQ,
      },
      vapour: {
        data: tempReview.vapour.data,
        soilTexture: tempTexture,
        totalHQ: tempReview.vapour.totalHQ,
      },
    };
    Swal.fire({
      title: 'Check your data',
      text: 'Review your data before submission.',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        if (!isReplace) {
          return updateData(dataId, dtFormat)
            .then((res) => {
              return { isConfirmed: true };
            })
            .catch((error) => {
              Swal.fire({
                title: 'An Error occured!',
                icon: 'error',
                confirmButtonColor: '#28a745',
              });
            });
        } else {
          return updateData(replaceId, dtFormat)
            .then((res) => {
              return { isConfirmed: true };
            })
            .catch((error) => {
              Swal.fire({
                title: 'An Error occured!',
                icon: 'error',
                confirmButtonColor: '#28a745',
              });
            });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
      backdrop: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await Swal.fire({
          icon: 'success',
          title: 'File data successfully updated!',
          confirmButtonColor: '#28a745',
        });
        window.location.href = `${process.env.MIX_APP_URL}/result`;
      }
    });
  };

  return (
    <div>
      {!dataId ? (
        <PageTitle
          title="Create New File"
          description="Create your new chemical data."
          className="pe-7s-note"
        />
      ) : (
        <PageTitle
          title="Previous Results"
          description="Preview results of previously input data."
          className="pe-7s-folder"
        />
      )}

      <div className="flex flex-col px-6 pt-2 pb-6 space-y-8">
        <Stepper
          steps={[
            'Site Profile',
            'Soil Contamination',
            'Groundwater Contamination',
            'Vapour Intrusion',
            'Review and Results',
          ]}
          ids={[
            'siteProfile',
            'soilContaminant',
            'groundwaterContaminant',
            'vapourIntrusion',
            'result',
          ]}
          completedIds={completedList}
          disabled={reviewed}
        />

        <Card title="Site Profile" id="siteProfile">
          <div className="flex flex-col space-y-2">
            {error !== '' && <p className="text-red-500">* {error}</p>}
            <div className="flex flex-col space-y-4 space-x-0 lg:space-y-0 lg:space-x-2 lg:flex-row">
              <div className="lg:w-1/2 space-y-2">
                <span className="ml-1">Site Name</span>
                <input
                  className="appearance-none border border-gray-300 rounded-md w-full px-3 py-2 text-sm focus:border focus:ring-2 focus:ring-blue-200 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200"
                  name="fileName"
                  type="text"
                  placeholder="Site Name"
                  onChange={(e) => setSiteName(e.target.value)}
                  value={siteName}
                  disabled={reviewed}
                />
              </div>
              <div className="lg:w-1/2 space-y-2">
                <span className="ml-1">
                  Site Description (Max 255 characters)
                </span>
                <textarea
                  className="appearance-none border border-gray-300 rounded-md w-full h-56 px-3 py-2 text-sm focus:border focus:ring-2 focus:ring-blue-200 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200"
                  name="fileDescription"
                  placeholder="Site Description"
                  maxLength="255"
                  onChange={(e) => setSiteDesc(e.target.value)}
                  value={siteDesc}
                  disabled={reviewed}
                />
              </div>
            </div>
            <div>
              <p className="mb-1">Land Uses</p>
              <Select
                fullWidth
                customClass={'text-sm px-3 py-2'}
                placeholder="Choose Land Uses"
                data={landUsesOption.map((item) => {
                  return {
                    value: item.id,
                    label: item.label,
                  };
                })}
                onChange={(e) => setLandUse(e.target.value)}
                value={landUse}
                disabled={reviewed}
              />
            </div>
          </div>
        </Card>

        <Card title="Soil Contaminants" id="soilContaminant">
          <div className="flex flex-col space-y-4">
            <div className="space-y-2">
              <span className="">Soil Contaminant Group</span>
              <Select
                fullWidth
                customClass={'text-sm px-3 py-2'}
                placeholder="Choose Soil Contaminant Group"
                data={soilContaminants.map((item) => {
                  return {
                    value: item,
                    label: item,
                  };
                })}
                onChange={(e) => {
                  // set the substance list dropdown
                  setSoilSubList(
                    allSoil.filter(
                      (item) =>
                        item.group == e.target.value && item.status == 'active'
                    )
                  );
                  // reset the substance value
                  setSoilSubOpt('');
                  // set the soil group value
                  setSoilGroupOpt(e.target.value);
                }}
                value={soilGroupOpt}
                disabled={reviewed}
              />
            </div>
            <div className="flex flex-col space-y-4 space-x-0 lg:space-y-0 lg:space-x-2 lg:flex-row">
              <div className="lg:w-1/2 space-y-2">
                <span className="ml-1">Soil Substance</span>
                <Select
                  fullWidth
                  customClass={'text-sm px-3 py-2'}
                  placeholder="Choose Soil Substance"
                  data={soilSubList.map((item) => {
                    return {
                      value: item.id,
                      label: item.name,
                    };
                  })}
                  onChange={(e) => setSoilSubOpt(e.target.value)}
                  value={soilSubOpt}
                  disabled={reviewed}
                />
              </div>
              <div className="lg:w-1/2 space-y-2">
                <span className="ml-1">Concentration in soil</span>
                <div className="flex flex-row justify-center items-center space-x-2 w-full">
                  <input
                    className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                    name="contaminant"
                    type="number"
                    placeholder={0}
                    onChange={(e) => setSoilCon(e.target.value)}
                    value={soilCon}
                    disabled={reviewed}
                  />
                  <span>mg/Kg</span>
                </div>
              </div>
            </div>
            <div>
              <Button
                lowercase
                theme="success"
                text="Add"
                onClick={() => {
                  // add the data to the soil data
                  setSoilData((item) => [
                    ...item,
                    {
                      id: soilSubOpt,
                      concentrations: soilCon,
                    },
                  ]);
                  // reset the form value
                  setSoilSubOpt('');
                  setSoilCon('');
                }}
                disabled={soilSubOpt == '' || soilCon == '' || reviewed == true}
              />
            </div>
          </div>
        </Card>

        {/* SOIL TABLE */}
        {soilData.length > 0 && (
          <div className="flex flex-col justify-center w-full bg-white border border-gray-300 shadow-lg rounded-lg pt-8 pb-8 px-8 z-0">
            <Table
              data={soilData.map((item, index) => {
                return !reviewed
                  ? {
                      No: index + 1,
                      Contaminant: !item.contaminants
                        ? allSoil.find((dt) => dt.id == item.id) &&
                          allSoil.find((dt) => dt.id == item.id).name
                        : item.contaminants,
                      'Concentration (mg/Kg)': Number(item.concentrations),
                      Action: (
                        <button
                          onClick={() =>
                            handleDelete(index, soilData, setSoilData)
                          }
                          className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-red-400 text-red-400 hover:bg-red-600 hover:text-white focus:outline-none"
                        >
                          <div className="self-center align-middle font-normal text-lg pe-7s-trash transition duration-200" />
                        </button>
                      ),
                    }
                  : {
                      No: index + 1,
                      Contaminant: item.contaminants,
                      'Concentration (mg/Kg)': Number(item.concentrations),
                    };
              })}
              show={10}
              headerBgColor="bg-xgreen"
              headerTextColor="text-white"
              headerText="capitalize-first"
            />
          </div>
        )}

        <Card title="Groundwater Contaminants" id="groundwaterContaminant">
          <div className="flex flex-col space-y-4">
            <div className="space-y-2">
              <span className="">Water Type</span>
              <Select
                fullWidth
                customClass={'text-sm px-3 py-2'}
                placeholder="Choose Water Type"
                data={groundwaterType.map((item) => {
                  return {
                    value: item.id,
                    label: item.label,
                  };
                })}
                onChange={(e) => setGroundwaterTypeOpt(e.target.value)}
                value={groundwaterTypeOpt}
                disabled={reviewed}
              />
            </div>
            <div className="space-y-2">
              <span className="">Groundwater Contaminant Group</span>
              <Select
                fullWidth
                customClass={'text-sm px-3 py-2'}
                placeholder="Choose Groundwater Contaminant Group"
                data={groundwaterContaminants.map((item) => {
                  return {
                    value: item,
                    label: item,
                  };
                })}
                onChange={(e) => {
                  // set the substance list dropdown
                  setGroundwaterSubList(
                    allGroundwater.filter(
                      (item) =>
                        item.group == e.target.value && item.status == 'active'
                    )
                  );
                  // reset the substance value
                  setGroundwaterSubOpt('');
                  // set the groundwater group value
                  setGroundwaterGroupOpt(e.target.value);
                }}
                value={groundwaterGroupOpt}
                disabled={reviewed}
              />
            </div>
            <div className="flex flex-col space-y-4 space-x-0 lg:space-y-0 lg:space-x-2 lg:flex-row">
              <div className="lg:w-1/2 space-y-2">
                <span className="ml-1">Groundwater Substance</span>
                <Select
                  fullWidth
                  customClass={'text-sm px-3 py-2'}
                  placeholder="Choose Groundwater Substance"
                  data={groundwaterSubList.map((item) => {
                    return {
                      value: item.id,
                      label: item.name,
                    };
                  })}
                  onChange={(e) => setGroundwaterSubOpt(e.target.value)}
                  value={groundwaterSubOpt}
                  disabled={reviewed}
                />
              </div>
              <div className="lg:w-1/2 space-y-2">
                <span className="ml-1">Concentration in Groundwater</span>
                <div className="flex flex-row justify-center items-center space-x-2 w-full">
                  <input
                    className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                    name="contaminant"
                    type="number"
                    placeholder={0}
                    onChange={(e) => setGroundwaterCon(e.target.value)}
                    value={groundwaterCon}
                    disabled={reviewed}
                  />
                  <span>mg/L</span>
                </div>
              </div>
            </div>
            <div>
              <Button
                lowercase
                theme="success"
                text="Add"
                onClick={() => {
                  // add the data to the groundwater data
                  setGroundwaterData((item) => [
                    ...item,
                    {
                      id: groundwaterSubOpt,
                      concentrations: groundwaterCon,
                    },
                  ]);
                  // reset the form value
                  setGroundwaterSubOpt('');
                  setGroundwaterCon('');
                }}
                disabled={
                  groundwaterSubOpt == '' ||
                  groundwaterCon == '' ||
                  groundwaterTypeOpt == '' ||
                  reviewed == true
                }
              />
            </div>
          </div>
        </Card>

        {/* GROUNDWATER TABLE */}
        {groundwaterData.length > 0 && (
          <div className="flex flex-col justify-center w-full bg-white border border-gray-300 shadow-lg rounded-lg pt-8 pb-8 px-8 z-0">
            <Table
              data={groundwaterData.map((item, index) => {
                return !reviewed
                  ? {
                      No: index + 1,
                      Contaminant: !item.contaminants
                        ? allGroundwater.find((dt) => dt.id == item.id) &&
                          allGroundwater.find((dt) => dt.id == item.id).name
                        : item.contaminants,
                      'Concentration (mg/L)': Number(item.concentrations),
                      Action: (
                        <button
                          onClick={() =>
                            handleDelete(
                              index,
                              groundwaterData,
                              setGroundwaterData
                            )
                          }
                          className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-red-400 text-red-400 hover:bg-red-600 hover:text-white focus:outline-none"
                        >
                          <div className="self-center align-middle font-normal text-lg pe-7s-trash transition duration-200" />
                        </button>
                      ),
                    }
                  : {
                      No: index + 1,
                      Contaminant: item.contaminants,
                      'Concentration (mg/L)': Number(item.concentrations),
                    };
              })}
              show={10}
              headerBgColor="bg-xgreen"
              headerTextColor="text-white"
              headerText="capitalize-first"
            />
          </div>
        )}

        <Card title="Vapour Intrusion" id="vapourIntrusion">
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col space-y-4 space-x-0 lg:space-y-0 lg:space-x-6 lg:flex-row">
              <div className="lg:w-1/3 space-y-2">
                <span className="ml-1">Soil Texture</span>
                <Select
                  fullWidth
                  customClass={'text-sm px-3 py-2'}
                  placeholder="Choose Soil Texture"
                  data={vapourSoilTexture.map((item) => {
                    return {
                      value: item.id,
                      label: item.label,
                    };
                  })}
                  onChange={(e) => setVapourTextureOpt(e.target.value)}
                  value={vapourTextureOpt}
                  disabled={reviewed}
                />
              </div>
              <div className="lg:w-1/3 space-y-2">
                <span className="ml-1">Sample Type</span>
                <Select
                  fullWidth
                  customClass={'text-sm px-3 py-2'}
                  placeholder="Choose Sample Type"
                  data={vapourSampleType.map((item) => {
                    return {
                      value: item.id,
                      label: item.label,
                    };
                  })}
                  onChange={(e) => {
                    // set the substance list dropdown
                    if (e.target.value == 1) {
                      setVapourSubList(
                        allVapourSoil.filter((item) => item.status == 'active')
                      );
                      setVapourConUnit(['mg/Kg']);
                    } else if (e.target.value == 2) {
                      setVapourSubList(
                        allVapourGroundwater.filter(
                          (item) => item.status == 'active'
                        )
                      );
                      setVapourConUnit(['mg/L']);
                    } else if (e.target.value == 3) {
                      setVapourSubList(
                        allVapourSoilVapour.filter(
                          (item) => item.status == 'active'
                        )
                      );
                      setVapourConUnit(['mg/m', <sup>3</sup>]);
                    }
                    // reset the substance value
                    setVapourSubOpt('');
                    // set the soil group value
                    setVapourTypeOpt(e.target.value);
                  }}
                  value={vapourTypeOpt}
                  disabled={reviewed}
                />
              </div>
              <div className="lg:w-1/3 space-y-2">
                <span className="ml-1">Sampling Depth</span>
                <Select
                  fullWidth
                  customClass={'text-sm px-3 py-2'}
                  placeholder="Choose Sampling Depth"
                  data={vapourSampleDepth.map((item) => {
                    return {
                      value: item.id,
                      label: item.label,
                    };
                  })}
                  onChange={(e) => setVapourDepthOpt(e.target.value)}
                  value={vapourDepthOpt}
                  disabled={reviewed}
                />
              </div>
            </div>
            <div className="flex flex-col space-y-4 space-x-0 lg:space-y-0 lg:space-x-2 lg:flex-row">
              <div className="lg:w-1/2 space-y-2">
                <span className="ml-1">Volatile Organic Compound (VOC)</span>
                <Select
                  fullWidth
                  customClass={'text-sm px-3 py-2'}
                  placeholder="Choose VOC"
                  data={vapourSubList.map((item) => {
                    return {
                      value: item.id,
                      label: item.name,
                    };
                  })}
                  onChange={(e) => setVapourSubOpt(e.target.value)}
                  value={vapourSubOpt}
                  disabled={reviewed}
                />
              </div>
              <div className="lg:w-1/2 space-y-2">
                <span className="ml-1">VOC Concentration</span>
                <div className="flex flex-row justify-center items-center space-x-2 w-full">
                  <input
                    className="w-full px-3 py-2 border rounded-md border-gray-300 text-sm focus:ring-2 focus:ring-blue-300 focus:outline-none focus:shadow-lg disabled:text-gray-600 disabled:bg-gray-200 "
                    name="contaminant"
                    type="number"
                    placeholder={0}
                    onChange={(e) => setVapourCon(e.target.value)}
                    value={vapourCon}
                    disabled={reviewed}
                  />
                  <span>{vapourConUnit}</span>
                </div>
              </div>
            </div>
            <div>
              <Button
                lowercase
                theme="success"
                text="Add"
                onClick={() => {
                  // add the data to the vapour data
                  setVapourData((item) => [
                    ...item,
                    {
                      id: vapourSubOpt,
                      concentrations: vapourCon,
                      sampleType: vapourTypeOpt,
                      sampleDepth: vapourDepthOpt,
                    },
                  ]);
                  // reset the form value
                  setVapourSubOpt('');
                  setVapourCon('');
                }}
                disabled={
                  vapourSubOpt == '' ||
                  vapourCon == '' ||
                  vapourTextureOpt == '' ||
                  vapourDepthOpt == '' ||
                  reviewed == true
                }
              />
            </div>
          </div>
        </Card>

        {/* VAPOUR TABLE */}
        {vapourData.length > 0 && (
          <div className="flex flex-col justify-center w-full bg-white border border-gray-300 shadow-lg rounded-lg pt-8 pb-8 px-8 z-0">
            <Table
              data={vapourData.map((item, index) => {
                let c = '';
                if (item.sampleType == 1) {
                  c =
                    allVapourSoil.find((dt) => dt.id == item.id) &&
                    allVapourSoil.find((dt) => dt.id == item.id).name;
                } else if (item.sampleType == 2) {
                  c =
                    allVapourGroundwater.find((dt) => dt.id == item.id) &&
                    allVapourGroundwater.find((dt) => dt.id == item.id).name;
                } else if (item.sampleType == 3) {
                  c =
                    allVapourSoilVapour.find((dt) => dt.id == item.id) &&
                    allVapourSoilVapour.find((dt) => dt.id == item.id).name;
                }

                return !reviewed
                  ? {
                      No: index + 1,
                      Contaminant: !item.contaminants ? c : item.contaminants,
                      Concentration: Number(item.concentrations),
                      Unit: vapourSampleType.find(
                        (dt) => dt.id == item.sampleType
                      ).unit,
                      'Sample Type': vapourSampleType.find(
                        (dt) => dt.id == item.sampleType
                      ).label,
                      'Sample Depth': vapourSampleDepth.find(
                        (dt) => dt.id == item.sampleDepth
                      ).label,
                      Action: (
                        <button
                          onClick={() =>
                            handleDelete(index, vapourData, setVapourData)
                          }
                          className="text-center flex w-min px-3 py-1 space-x-2 text-gray-200 border rounded-md transition border-red-400 text-red-400 hover:bg-red-600 hover:text-white focus:outline-none"
                        >
                          <div className="self-center align-middle font-normal text-lg pe-7s-trash transition duration-200" />
                        </button>
                      ),
                    }
                  : {
                      No: index + 1,
                      Contaminant: item.contaminants,
                      Concentration: Number(item.concentrations),
                      Unit: vapourSampleType.find(
                        (dt) => dt.id == item.sampleType
                      ).unit,
                      'Sample Type': vapourSampleType.find(
                        (dt) => dt.id == item.sampleType
                      ).label,
                      'Sample Depth': vapourSampleDepth.find(
                        (dt) => dt.id == item.sampleDepth
                      ).label,
                    };
              })}
              show={10}
              headerBgColor="bg-xgreen"
              headerTextColor="text-white"
              headerText="capitalize-first"
            />
          </div>
        )}

        {/* REVIEW AND RESULT */}
        {reviewed && (
          <div className="flex flex-col space-y-8">
            <Card title="Review and Result" id="result">
              <TableFileProfile
                data={resultData}
                show={10}
                headerBgColor="bg-xgreen"
                headerTextColor="text-white"
                headerText="capitalize-first"
              />
            </Card>

            {/* RADAR CHART */}
            <Card
              title={`Land Uses : ${
                landUsesOption.find((item) => item.id == landUse)
                  ? landUsesOption.find((item) => item.id == landUse).label
                  : ''
              }
                `}
            >
              <div className="flex justify-center">
                <div className="w-full lg:w-2/4">
                  <Radar data={radarData} options={radarOptions} />
                </div>
              </div>
            </Card>

            {/* SOIL PROFILE */}
            {soilProfileData.length > 0 && (
              <Card title="Soil Profile" id="result">
                <TableFileProfile
                  data={soilProfileData}
                  type="soil"
                  totalHQ={soilTotalHQ}
                  show={10}
                  headerBgColor="bg-xgreen"
                  headerTextColor="text-white"
                  headerText="capitalize-first"
                />
              </Card>
            )}

            {/* GROUNDWATER PROFILE */}
            {groundwaterProfileData.length > 0 && (
              <Card
                title={`Groundwater Profile (${
                  resGroundwaterType != '' &&
                  groundwaterType.find(
                    (item) => item.id == resGroundwaterType
                  ) &&
                  groundwaterType.find((item) => item.id == resGroundwaterType)
                    .label
                })`}
                id="result"
              >
                <TableFileProfile
                  data={groundwaterProfileData}
                  type="groundwater"
                  totalHQ={groundwaterTotalHQ}
                  show={10}
                  headerBgColor="bg-xgreen"
                  headerTextColor="text-white"
                  headerText="capitalize-first"
                />
              </Card>
            )}

            {/* VAPOUR PROFILE */}
            {vapourProfileData.length > 0 && (
              <Card
                title={`Vapour Profile (SOIL TEXTURE : ${
                  resSoilTexture != '' &&
                  vapourSoilTexture.find((item) => item.id == resSoilTexture) &&
                  vapourSoilTexture.find((item) => item.id == resSoilTexture)
                    .label
                })`}
                id="result"
              >
                <TableFileProfile
                  data={vapourProfileData}
                  type="vapour"
                  totalHQ={vapourTotalHQ}
                  show={10}
                  headerBgColor="bg-xgreen"
                  headerTextColor="text-white"
                  headerText="capitalize-first"
                />
              </Card>
            )}
          </div>
        )}

        {/* Button */}
        <div className="flex flex-row justify-between px-4">
          {!reviewed ? (
            !isEdit ? (
              <>
                <span
                  className="cursor-pointer text-green-500"
                  onClick={handleReset}
                >
                  Reset
                </span>
                <Button
                  lowercase
                  theme="success"
                  text="Next Step"
                  onClick={handleNextStep}
                  disabled={siteName.trim() == '' || landUse == ''}
                />
              </>
            ) : (
              <>
                <span
                  className="cursor-pointer text-red-500"
                  onClick={handleDeleteData}
                >
                  Delete
                </span>
                <Button
                  lowercase
                  theme="success"
                  text="Next Step"
                  onClick={handleNextStep}
                  disabled={siteName.trim() == '' || landUse == ''}
                />
              </>
            )
          ) : (
            <>
              {isEdit ? (
                dataId &&
                files[dataId] &&
                (SessionUtils.getUserData().role == 'admin' ||
                  SessionUtils.getUserData().id == files[dataId].userId) && (
                  <span
                    className="cursor-pointer text-green-500"
                    onClick={() => {
                      setReviewed(false);
                    }}
                  >
                    Edit
                  </span>
                )
              ) : (
                <span
                  className="cursor-pointer text-green-500"
                  onClick={() => {
                    setReviewed(false);
                  }}
                >
                  Edit
                </span>
              )}

              {showSubmit && (
                <Button
                  lowercase
                  theme="success"
                  text={!isEdit ? 'Submit' : 'Update Data'}
                  onClick={!isEdit ? handleAdd : handleUpdate}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  files: getResource(RESOURCE_NAMES['FILES'])(state),
});

export default connect(mapStateToProps, {
  getDataById: _getDataById,
  addData: _addData(RESOURCE_NAMES['FILES']),
  updateData: _updateData(RESOURCE_NAMES['FILES']),
  deleteData: _deleteData,
})(FileForm);
